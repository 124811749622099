import React from 'react';
import { BookOpen, ChevronRight } from 'lucide-react';
import { DailyHighlight as DailyHighlightType } from '../types';

interface DailyHighlightProps {
  highlight: DailyHighlightType;
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
  onNext: () => void;
}

const DailyHighlight: React.FC<DailyHighlightProps> = ({
  highlight,
  theme,
  t,
  onNext
}) => {
  return (
    <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-yellow-50'} rounded-xl p-4 shadow-sm relative overflow-hidden`}>
      <div className="absolute -right-4 -top-4 w-20 h-20 bg-yellow-900 rounded-full opacity-10"></div>
      <div className="absolute -left-8 -bottom-2 w-24 h-24 bg-yellow-900 rounded-full opacity-5"></div>
      
      <div className="flex justify-between items-center mb-3">
        <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-yellow-300' : 'text-yellow-700'} flex items-center`}>
          <BookOpen size={18} className="mr-2" />
          {t('todaysHighlight')}
        </h2>
        
        <button 
          onClick={onNext}
          className={`px-3 py-1 rounded-lg text-xs ${
            theme === 'dark' ? 'bg-yellow-800 text-yellow-200' : 'bg-yellow-200 text-yellow-800'
          } flex items-center`}
        >
          {t('nextHighlight')} <ChevronRight size={14} className="ml-1" />
        </button>
      </div>
      
      <div className={`p-4 rounded-lg ${
        highlight.type === 'quran'
          ? (theme === 'dark' ? 'bg-indigo-900/50' : 'bg-indigo-50')
          : (theme === 'dark' ? 'bg-green-900/50' : 'bg-green-50')
      }`}>
        <div className="flex items-center mb-2">
          <span className={`px-2 py-1 rounded text-xs mr-2 ${
            highlight.type === 'quran'
              ? (theme === 'dark' ? 'bg-indigo-800 text-indigo-200' : 'bg-indigo-200 text-indigo-800')
              : (theme === 'dark' ? 'bg-green-800 text-green-200' : 'bg-green-200 text-green-800')
          }`}>
            {t(highlight.type)}
          </span>
          <h3 className={`font-semibold ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>
            {t(highlight.title)}
          </h3>
        </div>
        
        {highlight.source && (
          <p className={`text-xs mb-2 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
            {t('source')}: {t(highlight.source)}
          </p>
        )}
        
        {/* Arabic text - always shown in original */}
        <div className={`p-3 rounded-lg mb-3 text-right ${
          theme === 'dark' ? 'bg-gray-800/50' : 'bg-white/70'
        }`}>
          <p className={`text-sm leading-relaxed ${theme === 'dark' ? 'text-gray-300' : 'text-gray-800'}`}>
            {highlight.arabic}
          </p>
        </div>
        
        {/* Translation in selected language */}
        <p className={`text-sm mb-3 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
          {t(`${highlight.title}_translation`)}
        </p>
        
        {/* Reflection in selected language */}
        <div className={`mt-2 p-3 rounded-lg ${
          theme === 'dark' ? 'bg-gray-700' : 'bg-white/70'
        }`}>
          <h4 className={`text-xs font-medium mb-1 ${
            theme === 'dark' ? 'text-gray-300' : 'text-gray-700'
          }`}>{t('reflection')}</h4>
          <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
            {t(`${highlight.title}_reflection`)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DailyHighlight;