import React, { useState, useEffect } from 'react';
import { Target, Bell, Calendar, Plus } from 'lucide-react';
import { Theme, CustomGoal, ReminderSettings, DailyProgress } from '../../types';
import CustomGoalsList from '../CustomGoalsList';
import RemindersList from '../RemindersList';
import { getCustomGoals, getReminders, updateGoalProgress, toggleReminder, initializeLocalStorage, saveCustomGoals, saveReminders } from '../../lib/localStorage';
import toast from 'react-hot-toast';

interface PersonalizationTabProps {
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
  progressHistory: DailyProgress[];
  onViewProgressHistory: () => void;
}

const PersonalizationTab: React.FC<PersonalizationTabProps> = ({
  theme,
  t,
  progressHistory,
  onViewProgressHistory
}) => {
  const [customGoals, setCustomGoals] = useState<CustomGoal[]>([]);
  const [reminders, setReminders] = useState<ReminderSettings[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initializeLocalStorage();
    loadData();
  }, []);

  const loadData = () => {
    try {
      setLoading(true);
      const goals = getCustomGoals();
      const reminders = getReminders();
      setCustomGoals(goals);
      setReminders(reminders);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Failed to load your personalized data');
    } finally {
      setLoading(false);
    }
  };

  const handleAddGoal = () => {
    const newGoal: CustomGoal = {
      id: customGoals.length + 1,
      name: '',
      description: '',
      target: 1,
      progress: 0,
      completed: false,
      category: 'other',
      icon: '🎯',
      createdAt: new Date(),
    };
    setCustomGoals([...customGoals, newGoal]);
    saveCustomGoals([...customGoals, newGoal]);
    toast.success('New goal added');
  };

  const handleEditGoal = (goal: CustomGoal) => {
    const updatedGoals = customGoals.map(g => g.id === goal.id ? goal : g);
    setCustomGoals(updatedGoals);
    saveCustomGoals(updatedGoals);
    toast.success('Goal updated');
  };

  const handleDeleteGoal = (goalId: number) => {
    const updatedGoals = customGoals.filter(g => g.id !== goalId);
    setCustomGoals(updatedGoals);
    saveCustomGoals(updatedGoals);
    toast.success('Goal deleted');
  };

  const handleUpdateGoalProgress = (goalId: number, increment: boolean) => {
    try {
      const updatedGoals = updateGoalProgress(goalId, increment);
      setCustomGoals(updatedGoals);
      toast.success(increment ? 'Progress updated!' : 'Progress adjusted');
    } catch (error) {
      console.error('Error updating goal progress:', error);
      toast.error('Failed to update progress');
    }
  };

  const handleAddReminder = () => {
    const newReminder: ReminderSettings = {
      id: reminders.length + 1,
      type: 'custom',
      name: '',
      enabled: true,
      days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
      sound: 'default',
      vibration: true
    };
    setReminders([...reminders, newReminder]);
    saveReminders([...reminders, newReminder]);
    toast.success('New reminder added');
  };

  const handleEditReminder = (reminder: ReminderSettings) => {
    const updatedReminders = reminders.map(r => r.id === reminder.id ? reminder : r);
    setReminders(updatedReminders);
    saveReminders(updatedReminders);
    toast.success('Reminder updated');
  };

  const handleDeleteReminder = (reminderId: number) => {
    const updatedReminders = reminders.filter(r => r.id !== reminderId);
    setReminders(updatedReminders);
    saveReminders(updatedReminders);
    toast.success('Reminder deleted');
  };

  const handleToggleReminder = (reminderId: number) => {
    try {
      const updatedReminders = toggleReminder(reminderId);
      setReminders(updatedReminders);
      const reminder = updatedReminders.find(r => r.id === reminderId);
      toast.success(reminder?.enabled ? 'Reminder enabled' : 'Reminder disabled');
    } catch (error) {
      console.error('Error toggling reminder:', error);
      toast.error('Failed to update reminder');
    }
  };

  // Get current month stats
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  
  const currentMonthProgress = progressHistory.filter(p => {
    const date = new Date(p.date);
    return date.getMonth() === currentMonth && date.getFullYear() === currentYear;
  });
  
  const daysTracked = currentMonthProgress.length;
  
  const averageCompletion = daysTracked > 0
    ? Math.round(currentMonthProgress.reduce((acc, curr) => acc + curr.completionPercentage, 0) / daysTracked)
    : 0;

  if (loading) {
    return (
      <div className={`flex items-center justify-center h-64 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
        Loading your personalized data...
      </div>
    );
  }

  return (
    <div className={`space-y-6 ${theme === 'dark' ? 'text-gray-200' : ''}`}>
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''} flex items-center`}>
            <Calendar size={20} className={`mr-2 ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'}`} />
            {t('progressHistory')}
          </h2>
          <button
            onClick={onViewProgressHistory}
            className={`px-3 py-1 rounded-lg text-xs ${
              theme === 'dark' ? 'bg-indigo-700 text-white' : 'bg-indigo-100 text-indigo-800'
            } flex items-center`}
          >
            {t('viewAll')}
          </button>
        </div>
        
        <div className="grid grid-cols-2 gap-4">
          <div className={`p-3 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50'} text-center`}>
            <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} mb-1`}>{t('daysTracked')}</p>
            <p className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{daysTracked}</p>
          </div>
          
          <div className={`p-3 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50'} text-center`}>
            <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} mb-1`}>{t('averageCompletion')}</p>
            <p className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{averageCompletion}%</p>
          </div>
        </div>
      </div>
      
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <CustomGoalsList
          theme={theme}
          t={t}
          customGoals={customGoals}
          onAddGoal={handleAddGoal}
          onEditGoal={handleEditGoal}
          onDeleteGoal={handleDeleteGoal}
          onUpdateProgress={handleUpdateGoalProgress}
        />
      </div>
      
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <RemindersList
          theme={theme}
          t={t}
          reminders={reminders}
          onAddReminder={handleAddReminder}
          onEditReminder={handleEditReminder}
          onDeleteReminder={handleDeleteReminder}
          onToggleReminder={handleToggleReminder}
        />
      </div>
    </div>
  );
};

export default PersonalizationTab;