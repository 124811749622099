import React, { useState, useEffect } from 'react';
import { Compass } from 'lucide-react';
import { Location, Theme } from '../types';

interface QiblaFinderProps {
  qiblaDirection: number | null;
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
  onCalculate: () => void;
}

const QiblaFinder: React.FC<QiblaFinderProps> = ({
  qiblaDirection,
  theme,
  t,
  onCalculate
}) => {
  const [hasCompass, setHasCompass] = useState(false);
  const [compassHeading, setCompassHeading] = useState(0);
  const [isCalibrating, setIsCalibrating] = useState(false);
  const [permissionState, setPermissionState] = useState<PermissionState>('prompt');

  useEffect(() => {
    // Check if device has compass
    if (window.DeviceOrientationEvent) {
      setHasCompass(true);
      
      // Check if we need to request permission (iOS 13+)
      if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
        (DeviceOrientationEvent as any).requestPermission()
          .then((state: PermissionState) => {
            setPermissionState(state);
            if (state === 'granted') {
              startCompassTracking();
            }
          })
          .catch(console.error);
      } else {
        // No permission needed (Android, older iOS)
        startCompassTracking();
      }
    }

    return () => {
      window.removeEventListener('deviceorientationabsolute', handleOrientation);
      window.removeEventListener('deviceorientation', handleOrientation);
    };
  }, []);

  const startCompassTracking = () => {
    // Try absolute orientation first
    if ('ondeviceorientationabsolute' in window) {
      window.addEventListener('deviceorientationabsolute', handleOrientation);
    } else {
      window.addEventListener('deviceorientation', handleOrientation);
    }
  };

  const handleOrientation = (event: DeviceOrientationEvent) => {
    let heading: number;

    if (event.webkitCompassHeading) {
      // iOS devices
      heading = event.webkitCompassHeading;
    } else if (event.absolute && event.alpha) {
      // Android devices
      heading = 360 - event.alpha;
    } else {
      // Fallback if no compass
      heading = 0;
    }

    setCompassHeading(heading);
  };

  const handleClick = async () => {
    if (!hasCompass) {
      alert(t('compassNotSupported'));
      return;
    }

    if (permissionState === 'prompt') {
      try {
        if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
          const permission = await (DeviceOrientationEvent as any).requestPermission();
          setPermissionState(permission);
          if (permission === 'granted') {
            startCompassTracking();
          } else {
            alert(t('compassPermissionDenied'));
            return;
          }
        }
      } catch (error) {
        console.error('Error requesting compass permission:', error);
        alert(t('compassPermissionError'));
        return;
      }
    }

    setIsCalibrating(true);
    onCalculate();
    
    // Reset calibrating state after 3 seconds
    setTimeout(() => {
      setIsCalibrating(false);
    }, 3000);
  };

  // Calculate the rotation angle for the compass
  const compassRotation = qiblaDirection ? qiblaDirection - compassHeading : 0;

  return (
    <div 
      className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-blue-50'} rounded-xl p-4 shadow-sm cursor-pointer relative overflow-hidden group transition-all duration-300 hover:shadow-md`}
      onClick={handleClick}
    >
      <div className="absolute -right-4 -bottom-4 w-16 h-16 bg-blue-900 rounded-full opacity-10"></div>
      <div className="flex flex-col items-center justify-center h-32">
        <div className="relative">
          <Compass 
            size={48} 
            className={`${theme === 'dark' ? 'text-blue-300' : 'text-blue-600'} mb-2 transition-transform duration-300 ${isCalibrating ? 'animate-spin' : ''}`}
            style={{ transform: `rotate(${compassRotation}deg)` }}
          />
          {qiblaDirection && (
            <div className={`absolute -top-1 -right-1 w-3 h-3 rounded-full ${theme === 'dark' ? 'bg-green-400' : 'bg-green-500'} animate-pulse`}></div>
          )}
        </div>
        
        <p className={`text-center ${theme === 'dark' ? 'text-blue-300' : 'text-blue-600'} font-medium`}>
          {t('qiblaFinder')}
        </p>
        
        {qiblaDirection && (
          <p className={`text-xs mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
            {qiblaDirection}° {t('fromNorth')}
          </p>
        )}

        {isCalibrating && (
          <p className={`text-xs mt-2 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} text-center`}>
            {t('compassCalibration')}
          </p>
        )}

        {permissionState === 'denied' && (
          <p className={`text-xs mt-2 ${theme === 'dark' ? 'text-red-400' : 'text-red-500'} text-center`}>
            {t('compassPermissionDenied')}
          </p>
        )}
      </div>
    </div>
  );
};

export default QiblaFinder;