import React from 'react';
import { X } from 'lucide-react';
import { SpecialDay, Theme } from '../../types';

interface RamadanCalendarModalProps {
  showModal: boolean;
  specialDays: SpecialDay[];
  ramadanDay: number | null;
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
  onClose: () => void;
}

const RamadanCalendarModal: React.FC<RamadanCalendarModalProps> = ({
  showModal,
  ramadanDay,
  theme,
  t,
  onClose
}) => {
  if (!showModal) return null;

  // Define important Ramadan milestones
  const specialDays: SpecialDay[] = [
    {
      day: 1,
      name: "First Day of Ramadan",
      description: "The blessed month begins. Start of fasting.",
      type: "milestone"
    },
    {
      day: 10,
      name: "First Third of Ramadan",
      description: "The first ten days of mercy (Rahmah).",
      type: "milestone"
    },
    {
      day: 15,
      name: "Middle of Ramadan",
      description: "Halfway point of the blessed month.",
      type: "milestone"
    },
    {
      day: 20,
      name: "Last Third Begins",
      description: "The final ten days begin. Increase in worship.",
      type: "milestone"
    },
    {
      day: 21,
      name: "Possible Laylatul Qadr",
      description: "One of the possible nights of power.",
      type: "laylatul-qadr"
    },
    {
      day: 23,
      name: "Possible Laylatul Qadr",
      description: "One of the possible nights of power.",
      type: "laylatul-qadr"
    },
    {
      day: 25,
      name: "Possible Laylatul Qadr",
      description: "One of the possible nights of power.",
      type: "laylatul-qadr"
    },
    {
      day: 27,
      name: "Most Likely Laylatul Qadr",
      description: "The night most commonly associated with Laylatul Qadr.",
      type: "laylatul-qadr-main"
    },
    {
      day: 29,
      name: "Possible Last Day",
      description: "Possible last day of Ramadan depending on moon sighting.",
      type: "milestone"
    },
    {
      day: 30,
      name: "Possible Last Day",
      description: "Possible last day of Ramadan depending on moon sighting.",
      type: "milestone"
    }
  ];
  
  // Generate calendar days
  const calendarDays = Array.from({ length: 30 }, (_, i) => {
    const day = i + 1;
    const special = specialDays.find(s => s.day === day);
    
    return {
      day,
      isSpecial: !!special,
      special
    };
  });
  
  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${theme === 'dark' ? 'bg-black/70' : 'bg-gray-500/70'}`}>
      <div className={`w-full max-w-md ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg overflow-hidden`}>
        <div className={`p-4 border-b ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-between items-center">
            <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('ramadanCalendar')}</h2>
            <button 
              onClick={onClose}
              className={`p-1 rounded-full ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
            >
              <X size={20} />
            </button>
          </div>
        </div>
        
        <div className="max-h-[70vh] overflow-y-auto p-4">
          <div className="grid grid-cols-7 gap-1 mb-4">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className={`text-center text-xs p-1 font-medium ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {day}
              </div>
            ))}
            
            {/* Placeholder for days before Ramadan starts (using 4 as example) */}
            {Array.from({ length: 4 }).map((_, i) => (
              <div key={`empty-${i}`} className="p-2"></div>
            ))}
            
            {/* Ramadan days */}
            {calendarDays.map(({ day, isSpecial, special }) => (
              <div 
                key={`day-${day}`} 
                className={`p-1 rounded-lg text-center ${
                  day === ramadanDay 
                    ? (theme === 'dark' ? 'bg-indigo-700 text-white' : 'bg-indigo-100 text-indigo-800')
                    : isSpecial 
                      ? (special?.type === 'laylatul-qadr-main'
                        ? (theme === 'dark' ? 'bg-purple-900 text-purple-100' : 'bg-purple-100 text-purple-800')
                        : special?.type === 'laylatul-qadr'
                          ? (theme === 'dark' ? 'bg-purple-800/40 text-purple-100' : 'bg-purple-50 text-purple-800')
                          : (theme === 'dark' ? 'bg-gray-700 text-gray-100' : 'bg-gray-100 text-gray-800'))
                      : ''
                }`}
              >
                <span className="text-sm">{day}</span>
                {isSpecial && (
                  <div className="w-1.5 h-1.5 rounded-full mx-auto mt-1" 
                    style={{ 
                      backgroundColor: special?.type === 'laylatul-qadr-main' 
                        ? '#8B5CF6' 
                        : special?.type === 'laylatul-qadr' 
                          ? '#A78BFA' 
                          : '#9CA3AF'
                    }}
                  ></div>
                )}
              </div>
            ))}
          </div>
          
          <h3 className={`font-medium mb-2 ${theme === 'dark' ? 'text-white' : ''}`}>{t('specialDays')}</h3>
          <div className="space-y-2">
            {specialDays.map(day => (
              <div 
                key={`detail-${day.day}`} 
                className={`p-3 rounded-lg ${
                  day.type === 'laylatul-qadr-main' 
                    ? (theme === 'dark' ? 'bg-purple-900' : 'bg-purple-50')
                    : day.type === 'laylatul-qadr'
                      ? (theme === 'dark' ? 'bg-purple-800/40' : 'bg-purple-50/70')
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50')
                }`}
              >
                <div className="flex justify-between items-center">
                  <span 
                    className={`px-2 py-0.5 rounded text-xs ${
                      day.type.includes('laylatul-qadr')
                        ? (theme === 'dark' ? 'bg-purple-700 text-purple-100' : 'bg-purple-200 text-purple-800')
                        : (theme === 'dark' ? 'bg-gray-600 text-gray-200' : 'bg-gray-200 text-gray-700')
                    }`}
                  >
                    {t('day')} {day.day}
                  </span>
                  
                  {day.type === 'laylatul-qadr-main' && (
                    <span className={`px-2 py-0.5 rounded text-xs ${
                      theme === 'dark' ? 'bg-yellow-700 text-yellow-100' : 'bg-yellow-200 text-yellow-800'
                    }`}>
                      ★ {t('mostLikely')}
                    </span>
                  )}
                </div>
                
                <h4 className={`font-medium mt-2 ${
                  day.type.includes('laylatul-qadr')
                    ? (theme === 'dark' ? 'text-purple-200' : 'text-purple-800')
                    : (theme === 'dark' ? 'text-white' : 'text-gray-800')
                }`}>{day.name}</h4>
                
                <p className={`text-xs mt-1 ${
                  day.type.includes('laylatul-qadr')
                    ? (theme === 'dark' ? 'text-purple-300' : 'text-purple-700')
                    : (theme === 'dark' ? 'text-gray-300' : 'text-gray-600')
                }`}>{day.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RamadanCalendarModal;