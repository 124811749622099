import React from 'react';
import { Award, Share2, Heart, Sparkles } from 'lucide-react';
import { Achievement } from '../types';

interface RecentAchievementProps {
  achievement: Achievement;
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
}

const RecentAchievement: React.FC<RecentAchievementProps> = ({
  achievement,
  theme,
  t
}) => {
  // Get current Ramadan day
  const ramadanStartDate = new Date('2025-03-01');
  const today = new Date();
  const daysDifference = Math.floor((today - ramadanStartDate) / (1000 * 60 * 60 * 24));
  const currentDay = daysDifference >= 0 ? daysDifference + 1 : null;
  
  // The completed day is always one less than the current day
  const completedDay = currentDay ? currentDay - 1 : null;

  // Generate achievement based on completed day
  const dayAchievement = completedDay && completedDay > 0 ? {
    id: completedDay,
    name: `Day ${completedDay} Fast Complete`,
    icon: '🌙',
    description: `Completed your ${completedDay}${getDayOrdinal(completedDay)} day of fasting`,
    progress: 1,
    target: 1,
    unlocked: true
  } : achievement;

  // Helper function to get ordinal suffix
  function getDayOrdinal(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  return (
    <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-yellow-50'} rounded-xl p-4 shadow-sm relative overflow-hidden`}>
      <div className="absolute -right-4 -top-4 w-20 h-20 bg-yellow-900 rounded-full opacity-10"></div>
      <div className="absolute -left-8 -bottom-2 w-24 h-24 bg-yellow-900 rounded-full opacity-5"></div>
      
      <div className="flex justify-between items-center mb-2">
        <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-yellow-300' : 'text-yellow-700'} flex items-center`}>
          <Award size={18} className="mr-2" />
          {t('recentAchievement')}
        </h2>
        
        <button 
          className={`p-2 rounded-full ${
            theme === 'dark' ? 'bg-gray-700 text-yellow-300' : 'bg-yellow-100 text-yellow-700'
          }`}
        >
          <Share2 size={16} />
        </button>
      </div>
      
      <div className="flex items-center">
        <div className={`w-14 h-14 ${theme === 'dark' ? 'bg-yellow-900' : 'bg-yellow-100'} rounded-full flex items-center justify-center text-2xl relative`}>
          {dayAchievement.icon}
          <Sparkles size={12} className={`absolute -top-1 -right-1 ${theme === 'dark' ? 'text-yellow-300' : 'text-yellow-500'}`} />
        </div>
        <div className="ml-4">
          <h3 className={`font-bold ${theme === 'dark' ? 'text-yellow-300' : 'text-yellow-800'}`}>
            {dayAchievement.name}
          </h3>
          <p className={`text-xs ${theme === 'dark' ? 'text-yellow-400' : 'text-yellow-600'} flex items-center`}>
            <Heart size={12} className="mr-1" /> {t('keepUpGoodWork')}
          </p>
          {dayAchievement.description && (
            <p className={`text-xs mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
              {dayAchievement.description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentAchievement;