import React from 'react';
import { Activity, Book, Star, Gift } from 'lucide-react';

interface ProgressSummaryProps {
  completionPercentage: number;
  completedTasks: number;
  totalTasks: number;
  completedSunnahTasks: number;
  totalSunnahTasks: number;
  unlockedAchievements: number;
  totalAchievements: number;
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
}

const ProgressSummary: React.FC<ProgressSummaryProps> = ({
  completionPercentage,
  completedTasks,
  totalTasks,
  completedSunnahTasks,
  totalSunnahTasks,
  unlockedAchievements,
  totalAchievements,
  theme,
  t
}) => {
  return (
    <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-indigo-50'} rounded-xl p-4`}>
      <div className="flex justify-between items-center mb-2">
        <h3 className={`font-semibold ${theme === 'dark' ? 'text-white' : ''} flex items-center`}>
          <Activity size={16} className={`mr-2 ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'}`} />
          {t('todaysProgress')}
        </h3>
        <span className={`font-bold ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-700'}`}>
          {completionPercentage}%
        </span>
      </div>
      <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-2.5`}>
        <div 
          className="bg-indigo-600 h-2.5 rounded-full" 
          style={{ width: `${completionPercentage}%` }}
        ></div>
      </div>
      
      <div className="mt-3 flex justify-evenly">
        <div className="text-center">
          <div className={`w-8 h-8 rounded-full ${theme === 'dark' ? 'bg-indigo-900' : 'bg-indigo-100'} mx-auto flex items-center justify-center`}>
            <Book size={14} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} />
          </div>
          <p className="text-xs mt-1">{completedTasks}/{totalTasks}</p>
        </div>
        <div className="text-center">
          <div className={`w-8 h-8 rounded-full ${theme === 'dark' ? 'bg-purple-900' : 'bg-purple-100'} mx-auto flex items-center justify-center`}>
            <Star size={14} className={theme === 'dark' ? 'text-purple-300' : 'text-purple-600'} />
          </div>
          <p className="text-xs mt-1">{completedSunnahTasks}/{totalSunnahTasks}</p>
        </div>
        <div className="text-center">
          <div className={`w-8 h-8 rounded-full ${theme === 'dark' ? 'bg-green-900' : 'bg-green-100'} mx-auto flex items-center justify-center`}>
            <Gift size={14} className={theme === 'dark' ? 'text-green-300' : 'text-green-600'} />
          </div>
          <p className="text-xs mt-1">{unlockedAchievements}/{totalAchievements}</p>
        </div>
      </div>
    </div>
  );
};

export default ProgressSummary;