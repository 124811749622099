import React, { useState, useEffect } from 'react';
import { Coffee, Utensils, Clock } from 'lucide-react';

interface MealTimesProps {
  nextMeal: {
    type: string;
    hours: number;
    minutes: number;
  };
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
}

const MealTimes: React.FC<MealTimesProps> = ({
  nextMeal,
  theme,
  t
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      const totalMinutesUntilNext = nextMeal.hours * 60 + nextMeal.minutes;
      const maxMinutes = 12 * 60; // Maximum 12 hours between meals
      
      // Invert the percentage since we want to show progress towards the next meal
      const remainingPercentage = (totalMinutesUntilNext / maxMinutes) * 100;
      const progressPercentage = Math.max(0, Math.min(100, 100 - remainingPercentage));
      
      setProgress(progressPercentage);
    };

    // Initial update
    updateProgress();

    // Update every minute
    const interval = setInterval(updateProgress, 60000);

    return () => clearInterval(interval);
  }, [nextMeal.hours, nextMeal.minutes]);

  // Get meal-specific styles
  const getMealStyles = (type: string) => {
    if (type === 'Sahoor') {
      return {
        icon: <Coffee size={24} className="transition-transform duration-500 group-hover:rotate-12" />,
        gradient: theme === 'dark'
          ? 'from-purple-900/50 via-indigo-900/30 to-gray-900'
          : 'from-purple-100 via-indigo-50 to-gray-50',
        iconBg: theme === 'dark' ? 'bg-purple-900' : 'bg-purple-100',
        iconColor: theme === 'dark' ? 'text-purple-300' : 'text-purple-600',
        timeColor: theme === 'dark' ? 'text-purple-200' : 'text-purple-700',
        progressBg: theme === 'dark' ? 'bg-purple-800/50' : 'bg-purple-100/50',
        progressFill: theme === 'dark' ? 'bg-purple-400' : 'bg-purple-500',
        progressGlow: theme === 'dark' ? 'bg-purple-400' : 'bg-purple-400'
      };
    } else {
      return {
        icon: <Utensils size={24} className="transition-transform duration-500 group-hover:scale-110" />,
        gradient: theme === 'dark'
          ? 'from-orange-900/50 via-amber-900/30 to-gray-900'
          : 'from-orange-100 via-amber-50 to-gray-50',
        iconBg: theme === 'dark' ? 'bg-orange-900' : 'bg-orange-100',
        iconColor: theme === 'dark' ? 'text-orange-300' : 'text-orange-600',
        timeColor: theme === 'dark' ? 'text-orange-200' : 'text-orange-700',
        progressBg: theme === 'dark' ? 'bg-orange-800/50' : 'bg-orange-100/50',
        progressFill: theme === 'dark' ? 'bg-orange-400' : 'bg-orange-500',
        progressGlow: theme === 'dark' ? 'bg-orange-400' : 'bg-orange-400'
      };
    }
  };

  const styles = getMealStyles(nextMeal.type);

  return (
    <div className={`group bg-gradient-to-r ${styles.gradient} rounded-xl p-6 shadow-sm relative overflow-hidden transition-all duration-300 hover:shadow-lg`}>
      {/* Decorative background elements */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 right-0 w-40 h-40 bg-current rounded-full filter blur-3xl animate-pulse"></div>
        <div className="absolute -bottom-8 -left-8 w-40 h-40 bg-current rounded-full filter blur-3xl animate-pulse delay-150"></div>
      </div>

      <div className="relative">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <div>
            <h3 className={`text-lg font-bold ${styles.timeColor} flex items-center`}>
              {nextMeal.type === 'Sahoor' ? t('sahoorTime') : t('breakFastTime')}
            </h3>
            <p className={`flex items-center text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
              <Clock size={14} className="mr-1" />
              {t('inTime')} {nextMeal.hours}{t('hours')} {nextMeal.minutes}{t('minutes')}
            </p>
          </div>
          <div className={`${styles.iconBg} p-3 rounded-full ${styles.iconColor} transform transition-transform duration-500 group-hover:scale-110`}>
            {styles.icon}
          </div>
        </div>

        {/* Enhanced Progress bar */}
        <div className="relative mt-4">
          {/* Background track */}
          <div className={`h-3 rounded-full ${styles.progressBg} overflow-hidden`}>
            {/* Progress fill */}
            <div 
              className={`h-full rounded-full transition-all duration-1000 relative ${styles.progressFill}`}
              style={{ width: `${progress}%` }}
            >
              {/* Animated gradient overlay */}
              <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-[shimmer_2s_infinite]"></div>
              
              {/* Glow effect */}
              <div 
                className={`absolute inset-0 ${styles.progressGlow} filter blur-sm opacity-50`}
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>

          {/* Progress markers */}
          <div className="flex justify-between mt-1 px-1">
            <span className={`text-xs ${theme === 'dark' ? 'text-gray-500' : 'text-gray-400'}`}>
              {Math.round(progress)}%
            </span>
            <span className={`text-xs ${theme === 'dark' ? 'text-gray-500' : 'text-gray-400'}`}>
              {nextMeal.hours}h {nextMeal.minutes}m
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealTimes;