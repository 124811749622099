import React, { useState } from 'react';
import { Clock, Book, ChevronLeft, ChevronRight, Sun, Moon, Sunrise, Sunset } from 'lucide-react';
import { Theme } from '../../types';
import { dailyHighlights } from '../../data/dailyHighlights';

interface InfoTabProps {
  theme: Theme;
  prayerTimes: {
    fajr: string;
    sunrise: string;
    dhuhr: string;
    asr: string;
    maghrib: string;
    isha: string;
    sahoor: string;
    iftar: string;
  };
  t: (key: string, ...args: any[]) => string;
}

const InfoTab: React.FC<InfoTabProps> = ({
  theme,
  prayerTimes,
  t
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? dailyHighlights.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === dailyHighlights.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Get current time to determine active prayer
  const now = new Date();
  const currentTime = now.getHours() * 60 + now.getMinutes();

  const getPrayerTime = (timeStr: string) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const getPrayerIcon = (prayerName: string, isActive: boolean) => {
    const baseClasses = `w-8 h-8 rounded-full flex items-center justify-center ${
      isActive 
        ? 'animate-pulse-glow'
        : ''
    }`;

    const iconClasses = isActive
      ? theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'
      : theme === 'dark' ? 'text-gray-400' : 'text-gray-500';

    switch (prayerName) {
      case 'fajr':
        return (
          <div className={`${baseClasses} ${theme === 'dark' ? 'bg-indigo-900/50' : 'bg-indigo-50'}`}>
            <Sunrise size={20} className={iconClasses} />
          </div>
        );
      case 'sunrise':
        return (
          <div className={`${baseClasses} ${theme === 'dark' ? 'bg-orange-900/50' : 'bg-orange-50'}`}>
            <Sun size={20} className={iconClasses} />
          </div>
        );
      case 'dhuhr':
        return (
          <div className={`${baseClasses} ${theme === 'dark' ? 'bg-yellow-900/50' : 'bg-yellow-50'}`}>
            <Sun size={20} className={iconClasses} />
          </div>
        );
      case 'asr':
        return (
          <div className={`${baseClasses} ${theme === 'dark' ? 'bg-amber-900/50' : 'bg-amber-50'}`}>
            <Sun size={20} className={iconClasses} />
          </div>
        );
      case 'maghrib':
        return (
          <div className={`${baseClasses} ${theme === 'dark' ? 'bg-purple-900/50' : 'bg-purple-50'}`}>
            <Sunset size={20} className={iconClasses} />
          </div>
        );
      case 'isha':
        return (
          <div className={`${baseClasses} ${theme === 'dark' ? 'bg-blue-900/50' : 'bg-blue-50'}`}>
            <Moon size={20} className={iconClasses} />
          </div>
        );
      default:
        return (
          <div className={`${baseClasses} ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100'}`}>
            <Clock size={20} className={iconClasses} />
          </div>
        );
    }
  };

  const getPrayerProgress = (currentTime: number, prayerTime: number, nextPrayerTime: number) => {
    if (currentTime < prayerTime || currentTime > nextPrayerTime) return 0;
    return ((currentTime - prayerTime) / (nextPrayerTime - prayerTime)) * 100;
  };

  const prayerTimesList = [
    { name: 'fajr', time: prayerTimes.fajr },
    { name: 'sunrise', time: prayerTimes.sunrise },
    { name: 'dhuhr', time: prayerTimes.dhuhr },
    { name: 'asr', time: prayerTimes.asr },
    { name: 'maghrib', time: prayerTimes.maghrib },
    { name: 'isha', time: prayerTimes.isha }
  ];

  return (
    <div className={`space-y-6 ${theme === 'dark' ? 'text-gray-200' : ''}`}>
      {/* Prayer Times Section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm overflow-hidden relative`}>
        {/* Decorative background pattern */}
        <div className="absolute inset-0 opacity-5">
          <div className="absolute top-0 right-0 w-40 h-40 bg-indigo-500 rounded-full filter blur-3xl animate-pulse-glow"></div>
          <div className="absolute bottom-0 left-0 w-40 h-40 bg-purple-500 rounded-full filter blur-3xl animate-pulse-glow"></div>
        </div>

        <div className="relative">
          <div className="flex items-center mb-4">
            <Clock size={20} className={`${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} mr-2`} />
            <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('dailyPrayers')}</h2>
          </div>
          
          <div className="space-y-4">
            {prayerTimesList.map((prayer, index) => {
              const currentPrayerTime = getPrayerTime(prayer.time);
              const nextPrayerTime = getPrayerTime(prayerTimesList[(index + 1) % prayerTimesList.length].time);
              const isActive = currentTime >= currentPrayerTime && currentTime < nextPrayerTime;
              const progress = getPrayerProgress(currentTime, currentPrayerTime, nextPrayerTime);

              return (
                <div 
                  key={prayer.name}
                  className={`relative ${
                    isActive 
                      ? (theme === 'dark' ? 'bg-indigo-900/20' : 'bg-indigo-50/80') 
                      : (theme === 'dark' ? 'bg-gray-700/50' : 'bg-gray-50/80')
                  } p-4 rounded-lg transition-all duration-300 hover:transform hover:scale-[1.02]`}
                >
                  {/* Progress bar */}
                  {isActive && (
                    <div 
                      className="absolute bottom-0 left-0 h-1 bg-indigo-500 transition-all duration-1000 rounded-full"
                      style={{ width: `${progress}%` }}
                    ></div>
                  )}

                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      {getPrayerIcon(prayer.name, isActive)}
                      <div>
                        <h3 className={`font-medium ${
                          isActive 
                            ? (theme === 'dark' ? 'text-indigo-300' : 'text-indigo-700')
                            : ''
                        }`}>
                          {t(prayer.name)}
                        </h3>
                        <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                          {prayer.time}
                        </p>
                      </div>
                    </div>

                    {isActive && (
                      <div className={`px-2 py-1 rounded-full text-xs ${
                        theme === 'dark' ? 'bg-indigo-900 text-indigo-200' : 'bg-indigo-100 text-indigo-800'
                      }`}>
                        {t('current')}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Fasting Times Section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <div className="flex items-center mb-4">
          <Clock size={20} className={`${theme === 'dark' ? 'text-orange-300' : 'text-orange-600'} mr-2`} />
          <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('fasting')}</h2>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-orange-50'} p-3 rounded-lg`}>
            <div className="flex justify-between items-center">
              <h3 className={`text-sm font-semibold ${theme === 'dark' ? 'text-orange-300' : 'text-orange-700'}`}>{t('sahoor')}</h3>
              <p className={`font-bold ${theme === 'dark' ? 'text-white' : 'text-orange-800'}`}>{prayerTimes.sahoor}</p>
            </div>
          </div>
          <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-orange-50'} p-3 rounded-lg`}>
            <div className="flex justify-between items-center">
              <h3 className={`text-sm font-semibold ${theme === 'dark' ? 'text-orange-300' : 'text-orange-700'}`}>{t('iftar')}</h3>
              <p className={`font-bold ${theme === 'dark' ? 'text-white' : 'text-orange-800'}`}>{prayerTimes.iftar}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Ramadan Knowledge Section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <Book size={20} className={`${theme === 'dark' ? 'text-green-300' : 'text-green-600'} mr-2`} />
            <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('ramadanKnowledge')}</h2>
          </div>
          <div className="flex space-x-2">
            <button
              onClick={goToPrevious}
              className={`p-1 rounded-full ${
                theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-100 hover:bg-gray-200'
              } transition-colors`}
            >
              <ChevronLeft size={20} />
            </button>
            <button
              onClick={goToNext}
              className={`p-1 rounded-full ${
                theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-100 hover:bg-gray-200'
              } transition-colors`}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>

        <div className="relative overflow-hidden">
          <div className={`p-4 rounded-lg ${
            dailyHighlights[currentIndex].type === 'quran'
              ? (theme === 'dark' ? 'bg-indigo-900/50' : 'bg-indigo-50')
              : (theme === 'dark' ? 'bg-green-900/50' : 'bg-green-50')
          }`}>
            <div className="flex items-center mb-2">
              <span className={`px-2 py-1 rounded text-xs mr-2 ${
                dailyHighlights[currentIndex].type === 'quran'
                  ? (theme === 'dark' ? 'bg-indigo-800 text-indigo-200' : 'bg-indigo-200 text-indigo-800')
                  : (theme === 'dark' ? 'bg-green-800 text-green-200' : 'bg-green-200 text-green-800')
              }`}>
                {t(dailyHighlights[currentIndex].type)}
              </span>
              <h3 className={`font-semibold ${theme === 'dark' ? 'text-white' : 'text-gray-800'}`}>
                {t(dailyHighlights[currentIndex].title)}
              </h3>
            </div>

            {dailyHighlights[currentIndex].source && (
              <p className={`text-xs mb-2 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('source')}: {t(dailyHighlights[currentIndex].source)}
              </p>
            )}

            {/* Arabic text */}
            <div className={`p-3 rounded-lg mb-3 text-right ${
              theme === 'dark' ? 'bg-gray-800/50' : 'bg-white/70'
            }`}>
              <p className={`text-lg leading-relaxed ${theme === 'dark' ? 'text-gray-300' : 'text-gray-800'}`}>
                {dailyHighlights[currentIndex].arabic}
              </p>
            </div>

            {/* Translation */}
            <div className={`mb-3 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
              <h4 className="font-medium text-sm mb-1">{t('translation')}</h4>
              <p className="text-sm">{t(`${dailyHighlights[currentIndex].title}_translation`)}</p>
            </div>

            {/* Reflection */}
            <div className={`p-3 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-white/70'}`}>
              <h4 className={`text-xs font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('reflection')}
              </h4>
              <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                {t(`${dailyHighlights[currentIndex].title}_reflection`)}
              </p>
            </div>
          </div>

          {/* Navigation dots */}
          <div className="flex justify-center mt-4 space-x-2">
            {dailyHighlights.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index)}
                className={`w-2 h-2 rounded-full transition-colors ${
                  index === currentIndex
                    ? theme === 'dark'
                      ? 'bg-indigo-500'
                      : 'bg-indigo-600'
                    : theme === 'dark'
                    ? 'bg-gray-600'
                    : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTab;