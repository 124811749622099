import React from 'react';
import { Home, Calendar, Book, Award, Settings } from 'lucide-react';

interface BottomNavigationProps {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({
  activeTab,
  setActiveTab,
  theme,
  t
}) => {
  const navItems = [
    { id: 'home', icon: Home, label: t('home') },
    { id: 'track', icon: Calendar, label: t('track') },
    { id: 'info', icon: Book, label: t('info') },
    { id: 'achievements', icon: Award, label: t('achievements') },
    { id: 'personalize', icon: Settings, label: t('personalize') }
  ];

  return (
    <nav className={`fixed bottom-0 left-0 right-0 ${theme === 'dark' ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} max-w-md mx-auto border-t`}>
      <div className="grid grid-cols-5 gap-1 px-2 py-2">
        {navItems.map(({ id, icon: Icon, label }) => (
          <button
            key={id}
            onClick={() => setActiveTab(id)}
            className={`flex flex-col items-center justify-center py-2 px-1 rounded-lg transition-colors ${
              activeTab === id
                ? theme === 'dark'
                  ? 'text-indigo-400 bg-gray-700/50'
                  : 'text-indigo-600 bg-indigo-50'
                : theme === 'dark'
                ? 'text-gray-400 hover:bg-gray-700/30'
                : 'text-gray-500 hover:bg-gray-50'
            }`}
          >
            <Icon size={20} className="mb-1" />
            <span className="text-xs font-medium truncate w-full text-center">{label}</span>
          </button>
        ))}
      </div>
    </nav>
  );
};

export default BottomNavigation;