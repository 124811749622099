import React from 'react';
import { Plus, Target, Check, Calendar, Edit, Trash2 } from 'lucide-react';
import { CustomGoal, Theme } from '../types';
import { calculateRamadanDay } from '../utils/helpers';

interface CustomGoalsListProps {
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
  customGoals: CustomGoal[];
  onAddGoal: () => void;
  onEditGoal: (goal: CustomGoal) => void;
  onDeleteGoal: (goalId: number) => void;
  onUpdateProgress: (goalId: number, increment: boolean) => void;
}

const CustomGoalsList: React.FC<CustomGoalsListProps> = ({
  theme,
  t,
  customGoals,
  onAddGoal,
  onEditGoal,
  onDeleteGoal,
  onUpdateProgress
}) => {
  // Get Ramadan remaining days
  const { remainingDays } = calculateRamadanDay();
  
  // Format date to display
  const formatDate = (date: Date | string | undefined) => {
    if (!date) return '';
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return dateObj.toLocaleDateString(t('locale'), { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };
  
  // Calculate days remaining
  const getDaysRemaining = (endDate: Date | string | undefined) => {
    if (!endDate) return remainingDays || 0;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const end = typeof endDate === 'string' ? new Date(endDate) : endDate;
    end.setHours(0, 0, 0, 0);
    const diffTime = end.getTime() - today.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };
  
  // Group goals by category
  const groupedGoals = customGoals.reduce((acc, goal) => {
    if (!acc[goal.category]) {
      acc[goal.category] = [];
    }
    acc[goal.category].push(goal);
    return acc;
  }, {} as Record<string, CustomGoal[]>);
  
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('customGoals')}</h2>
        <button
          onClick={onAddGoal}
          className={`p-2 rounded-full ${theme === 'dark' ? 'bg-indigo-700 text-white' : 'bg-indigo-100 text-indigo-800'}`}
        >
          <Plus size={18} />
        </button>
      </div>
      
      {customGoals.length === 0 ? (
        <div 
          className={`p-4 rounded-lg border-2 border-dashed text-center cursor-pointer ${
            theme === 'dark' ? 'border-gray-700 hover:border-gray-600' : 'border-gray-200 hover:border-gray-300'
          }`}
          onClick={onAddGoal}
        >
          <Target size={24} className={`mx-auto mb-2 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`} />
          <p className={`${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('noCustomGoals')}</p>
          <p className={`text-sm ${theme === 'dark' ? 'text-gray-500' : 'text-gray-400'}`}>{t('clickToAddGoal')}</p>
        </div>
      ) : (
        <div className="space-y-4">
          {Object.entries(groupedGoals).map(([category, goals]) => (
            goals.length > 0 && (
              <div key={`category-${category}`}>
                <h3 className={`text-sm font-medium mb-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t(category)}
                </h3>
                <div className="space-y-2">
                  {goals.map(goal => (
                    <div 
                      key={`goal-${goal.id}`}
                      className={`p-3 rounded-lg ${
                        goal.completed 
                          ? (theme === 'dark' ? 'bg-green-900/50' : 'bg-green-50') 
                          : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50')
                      }`}
                    >
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center">
                          <span className="text-xl mr-2">{goal.icon}</span>
                          <div>
                            <h4 className={`font-medium ${theme === 'dark' ? 'text-white' : ''}`}>{goal.name}</h4>
                            {goal.description && (
                              <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                                {goal.description}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="flex space-x-1">
                          <button
                            onClick={() => onEditGoal(goal)}
                            className={`p-1 rounded ${theme === 'dark' ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}`}
                          >
                            <Edit size={14} className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} />
                          </button>
                          <button
                            onClick={() => onDeleteGoal(goal.id)}
                            className={`p-1 rounded ${theme === 'dark' ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}`}
                          >
                            <Trash2 size={14} className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} />
                          </button>
                        </div>
                      </div>
                      
                      {goal.endDate && (
                        <div className={`flex items-center text-xs mb-2 ${
                          getDaysRemaining(goal.endDate) <= 3 
                            ? (theme === 'dark' ? 'text-red-300' : 'text-red-600')
                            : (theme === 'dark' ? 'text-gray-400' : 'text-gray-500')
                        }`}>
                          <Calendar size={12} className="mr-1" />
                          {getDaysRemaining(goal.endDate) > 0 
                            ? `${getDaysRemaining(goal.endDate)} ${t('daysRemaining')}` 
                            : t('dueToday')}
                          <span className="mx-1">•</span>
                          {formatDate(goal.endDate)}
                        </div>
                      )}
                      
                      <div className="flex items-center justify-between">
                        <div className="flex-1 mr-3">
                          <div className="flex justify-between items-center text-xs mb-1">
                            <span className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}>
                              {t('progress')}
                            </span>
                            <span className={`font-medium ${
                              goal.completed 
                                ? (theme === 'dark' ? 'text-green-300' : 'text-green-600')
                                : (theme === 'dark' ? 'text-white' : 'text-gray-800')
                            }`}>
                              {goal.progress}/{goal.target}
                            </span>
                          </div>
                          <div className={`w-full ${theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200'} rounded-full h-1.5`}>
                            <div 
                              className={`h-1.5 rounded-full ${
                                goal.completed 
                                  ? 'bg-green-500' 
                                  : 'bg-indigo-600'
                              }`}
                              style={{ width: `${Math.min(100, (goal.progress / goal.target) * 100)}%` }}
                            ></div>
                          </div>
                        </div>
                        
                        <div className="flex space-x-1">
                          <button
                            onClick={() => onUpdateProgress(goal.id, false)}
                            disabled={goal.progress <= 0}
                            className={`w-8 h-8 rounded-full flex items-center justify-center ${
                              goal.progress <= 0
                                ? (theme === 'dark' ? 'bg-gray-600 text-gray-400' : 'bg-gray-200 text-gray-400')
                                : (theme === 'dark' ? 'bg-gray-600 text-white hover:bg-gray-500' : 'bg-gray-200 text-gray-700 hover:bg-gray-300')
                            }`}
                          >
                            -
                          </button>
                          <button
                            onClick={() => onUpdateProgress(goal.id, true)}
                            disabled={goal.progress >= goal.target}
                            className={`w-8 h-8 rounded-full flex items-center justify-center ${
                              goal.progress >= goal.target
                                ? (theme === 'dark' ? 'bg-gray-600 text-gray-400' : 'bg-gray-200 text-gray-400')
                                : (theme === 'dark' ? 'bg-indigo-600 text-white hover:bg-indigo-500' : 'bg-indigo-100 text-indigo-800 hover:bg-indigo-200')
                            }`}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomGoalsList;