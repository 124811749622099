import { CustomGoal, ReminderSettings, DailyProgress, Task, SunnahTask } from '../types';
import { calculateRamadanDay } from '../utils/helpers';

// Keys for localStorage
const KEYS = {
  CUSTOM_GOALS: 'ramadan_custom_goals',
  REMINDERS: 'ramadan_reminders',
  USER_STATS: 'ramadan_user_stats',
  SIGN_UP_DATE: 'ramadan_sign_up_date',
  DAILY_TRACKING: 'ramadan_daily_tracking',
  PROGRESS_HISTORY: 'ramadan_progress_history'
};

// Get remaining days for Ramadan goals
const { remainingDays } = calculateRamadanDay();

// Default goals and reminders
const DEFAULT_GOALS: CustomGoal[] = [
  {
    id: 1,
    name: 'Complete Quran Reading',
    description: 'Read the entire Quran during Ramadan',
    target: remainingDays || 30,
    progress: 0,
    completed: false,
    category: 'quran',
    icon: '📖',
    createdAt: new Date(),
    endDate: new Date('2025-03-30') // Ramadan end date
  },
  {
    id: 2,
    name: 'Pray Taraweeh Daily',
    description: 'Perform Taraweeh prayers every night in Ramadan',
    target: remainingDays || 30,
    progress: 0,
    completed: false,
    category: 'prayer',
    icon: '🕌',
    createdAt: new Date(),
    endDate: new Date('2025-03-30') // Ramadan end date
  }
];

const DEFAULT_REMINDERS: ReminderSettings[] = [
  {
    id: 1,
    type: 'prayer',
    name: 'Prayer Reminder',
    enabled: true,
    minutesBefore: 15,
    days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
    sound: 'adhan',
    vibration: true
  },
  {
    id: 2,
    type: 'meal',
    name: 'Iftar Reminder',
    enabled: true,
    minutesBefore: 30,
    days: ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'],
    sound: 'bell',
    vibration: true
  }
];

// Initialize data if not exists
export const initializeLocalStorage = () => {
  if (!localStorage.getItem(KEYS.SIGN_UP_DATE)) {
    localStorage.setItem(KEYS.SIGN_UP_DATE, new Date().toISOString());
  }

  if (!localStorage.getItem(KEYS.CUSTOM_GOALS)) {
    localStorage.setItem(KEYS.CUSTOM_GOALS, JSON.stringify(DEFAULT_GOALS));
  }

  if (!localStorage.getItem(KEYS.REMINDERS)) {
    localStorage.setItem(KEYS.REMINDERS, JSON.stringify(DEFAULT_REMINDERS));
  }

  if (!localStorage.getItem(KEYS.PROGRESS_HISTORY)) {
    localStorage.setItem(KEYS.PROGRESS_HISTORY, JSON.stringify([]));
  }

  // Initialize or reset daily tracking if it's a new day
  checkAndResetDailyTracking();
};

// Serialize task data for storage
const serializeTask = (task: Task) => ({
  id: task.id,
  name: task.name,
  completed: task.completed,
  inMosque: task.inMosque
});

const serializeSunnahTask = (task: SunnahTask) => ({
  id: task.id,
  name: task.name,
  completed: task.completed,
  readingType: task.readingType,
  readingAmount: task.readingAmount,
  amount: task.amount,
  currency: task.currency
});

// Daily Tracking Functions
export const checkAndResetDailyTracking = () => {
  const tracking = getDailyTracking();
  const today = new Date().toISOString().split('T')[0];

  if (tracking.date !== today) {
    // Save previous day's progress to history before resetting
    if (tracking.date) {
      saveDailyProgressToHistory(tracking);
    }

    // Reset tracking for new day
    const newTracking = {
      date: today,
      tasks: getDefaultTasks(),
      sunnahTasks: getDefaultSunnahTasks(),
      quranReadingAmount: 0,
      quranReadingType: 'verse',
      charityAmount: 0,
      charityCurrency: 'USD',
      periodDay: false
    };

    localStorage.setItem(KEYS.DAILY_TRACKING, JSON.stringify(newTracking));
    return newTracking;
  }

  return tracking;
};

export const getDailyTracking = () => {
  const tracking = localStorage.getItem(KEYS.DAILY_TRACKING);
  if (!tracking) {
    return {
      date: new Date().toISOString().split('T')[0],
      tasks: getDefaultTasks(),
      sunnahTasks: getDefaultSunnahTasks(),
      quranReadingAmount: 0,
      quranReadingType: 'verse',
      charityAmount: 0,
      charityCurrency: 'USD',
      periodDay: false
    };
  }
  return JSON.parse(tracking);
};

export const updateDailyTracking = (data: any) => {
  const currentTracking = getDailyTracking();
  const serializedData = {
    ...data,
    tasks: data.tasks?.map(serializeTask) || currentTracking.tasks,
    sunnahTasks: data.sunnahTasks?.map(serializeSunnahTask) || currentTracking.sunnahTasks
  };
  
  const updatedTracking = { ...currentTracking, ...serializedData };
  localStorage.setItem(KEYS.DAILY_TRACKING, JSON.stringify(updatedTracking));
  return updatedTracking;
};

// Progress History Functions
export const getProgressHistory = (): DailyProgress[] => {
  const history = localStorage.getItem(KEYS.PROGRESS_HISTORY);
  return history ? JSON.parse(history) : [];
};

export const saveDailyProgressToHistory = (tracking: any) => {
  const history = getProgressHistory();
  const completedTasks = tracking.tasks.filter((t: Task) => t.completed).length;
  const completedSunnahTasks = tracking.sunnahTasks.filter((t: SunnahTask) => t.completed).length;
  const totalTasks = tracking.tasks.length;
  const totalSunnahTasks = tracking.sunnahTasks.length;

  const dailyProgress: DailyProgress = {
    date: tracking.date,
    prayersCompleted: completedTasks,
    totalPrayers: totalTasks,
    sunnahCompleted: completedSunnahTasks,
    totalSunnah: totalSunnahTasks,
    quranAmount: tracking.quranReadingAmount,
    quranUnit: tracking.quranReadingType,
    charityAmount: tracking.charityAmount,
    charityCurrency: tracking.charityCurrency,
    customGoalsCompleted: 0,
    totalCustomGoals: 0,
    completionPercentage: Math.round(((completedTasks + completedSunnahTasks) / (totalTasks + totalSunnahTasks)) * 100)
  };

  // Add to history if not already exists for this date
  if (!history.find(p => p.date === tracking.date)) {
    history.push(dailyProgress);
    localStorage.setItem(KEYS.PROGRESS_HISTORY, JSON.stringify(history));
  }

  return dailyProgress;
};

// Helper functions
const getDefaultTasks = (): Task[] => [
  { id: 1, name: 'Fajr Prayer', completed: false, inMosque: false, icon: '🌅' },
  { id: 2, name: 'Dhuhr Prayer', completed: false, inMosque: false, icon: '☀️' },
  { id: 3, name: 'Asr Prayer', completed: false, inMosque: false, icon: '🌤️' },
  { id: 4, name: 'Maghrib Prayer', completed: false, inMosque: false, icon: '🌅' },
  { id: 5, name: 'Isha Prayer', completed: false, inMosque: false, icon: '🌙' }
];

const getDefaultSunnahTasks = (): SunnahTask[] => [
  { id: 1, name: 'Taraweeh', completed: false, icon: '🕌' },
  { id: 2, name: 'Quran Reading', completed: false, readingType: 'page', readingAmount: 0, icon: '📖' },
  { id: 3, name: 'Dhuha Prayer', completed: false, icon: '🌞' },
  { id: 4, name: 'Tahajjud', completed: false, icon: '✨' },
  { id: 5, name: 'Charity', completed: false, amount: 0, currency: 'USD', icon: '🤲' }
];

// Custom Goals
export const getCustomGoals = (): CustomGoal[] => {
  const goals = localStorage.getItem(KEYS.CUSTOM_GOALS);
  return goals ? JSON.parse(goals) : [];
};

export const saveCustomGoals = (goals: CustomGoal[]) => {
  localStorage.setItem(KEYS.CUSTOM_GOALS, JSON.stringify(goals));
};

export const updateGoalProgress = (goalId: number, increment: boolean): CustomGoal[] => {
  const goals = getCustomGoals();
  const updatedGoals = goals.map(goal => {
    if (goal.id === goalId) {
      const newProgress = increment 
        ? Math.min(goal.target, goal.progress + 1)
        : Math.max(0, goal.progress - 1);
      return {
        ...goal,
        progress: newProgress,
        completed: newProgress >= goal.target
      };
    }
    return goal;
  });
  saveCustomGoals(updatedGoals);
  return updatedGoals;
};

// Reminders
export const getReminders = (): ReminderSettings[] => {
  const reminders = localStorage.getItem(KEYS.REMINDERS);
  return reminders ? JSON.parse(reminders) : [];
};

export const saveReminders = (reminders: ReminderSettings[]) => {
  localStorage.setItem(KEYS.REMINDERS, JSON.stringify(reminders));
};

export const toggleReminder = (reminderId: number): ReminderSettings[] => {
  const reminders = getReminders();
  const updatedReminders = reminders.map(reminder => {
    if (reminder.id === reminderId) {
      return { ...reminder, enabled: !reminder.enabled };
    }
    return reminder;
  });
  saveReminders(updatedReminders);
  return updatedReminders;
};

// User Stats
export const getSignUpDate = (): Date => {
  const date = localStorage.getItem(KEYS.SIGN_UP_DATE);
  return date ? new Date(date) : new Date();
};

export const getDaysTracked = (): number => {
  const signUpDate = getSignUpDate();
  const today = new Date();
  const diffTime = Math.abs(today.getTime() - signUpDate.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Stats calculation
export const calculateUserStats = () => {
  const history = getProgressHistory();
  return {
    daysTracked: history.length,
    prayersCompleted: history.reduce((sum, day) => sum + day.prayersCompleted, 0),
    quranSessions: history.reduce((sum, day) => sum + (day.quranAmount > 0 ? 1 : 0), 0),
    achievements: history.reduce((sum, day) => sum + (day.completionPercentage === 100 ? 1 : 0), 0)
  };
};