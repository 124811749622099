import React, { useState, useEffect } from 'react';
import { User, Bell, Sliders, Settings, Save, AlertTriangle, LogOut, Moon, Sun, Globe, ChevronRight, Check, Volume2, Clock, Utensils, Heart, Wrench } from 'lucide-react';
import { Theme, Language, Location } from '../../types';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabase';
import toast from 'react-hot-toast';

interface ProfileTabProps {
  theme: Theme;
  language: Language;
  userName: string;
  gender: string;
  setGender: (gender: string) => void;
  notificationsEnabled: boolean;
  setNotificationsEnabled: (enabled: boolean) => void;
  dataSource: string;
  setDataSource: (source: string) => void;
  location: Location;
  toggleTheme: () => void;
  toggleLanguage: () => void;
  saveToLocalStorage: () => void;
  savedSettings: boolean;
  t: (key: string, ...args: any[]) => string;
  getDataSourceForLocation: (location: Location, currentDataSource: string) => string;
  onLogout: () => void;
}

const ProfileTab: React.FC<ProfileTabProps> = ({
  theme,
  language,
  userName,
  gender,
  setGender,
  notificationsEnabled,
  setNotificationsEnabled,
  dataSource,
  setDataSource,
  location,
  toggleTheme,
  toggleLanguage,
  saveToLocalStorage,
  savedSettings,
  t,
  getDataSourceForLocation,
  onLogout
}) => {
  const { user, updateProfile } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    firstName: userName,
    lastName: '',
    phone: ''
  });
  const [stats, setStats] = useState({
    daysTracked: 0,
    prayersCompleted: 0,
    quranSessions: 0,
    achievements: 0
  });

  useEffect(() => {
    if (user) {
      fetchUserStats();
    }
  }, [user]);

  const fetchUserStats = async () => {
    try {
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('created_at')
        .eq('id', user?.id)
        .single();

      if (profileError) throw profileError;

      const signUpDate = new Date(profile.created_at);
      const today = new Date();
      const diffTime = Math.abs(today.getTime() - signUpDate.getTime());
      const daysTracked = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const { data: tasks, error: tasksError } = await supabase
        .from('custom_goals')
        .select('progress, target, category')
        .eq('user_id', user?.id)
        .eq('completed', true);

      if (tasksError) throw tasksError;

      const stats = {
        daysTracked,
        prayersCompleted: 0,
        quranSessions: 0,
        achievements: 0
      };

      if (tasks) {
        const prayerTasks = tasks.filter(t => t.category === 'prayer');
        stats.prayersCompleted = prayerTasks.reduce((sum, task) => sum + task.progress, 0);

        const quranTasks = tasks.filter(t => t.category === 'quran');
        stats.quranSessions = quranTasks.reduce((sum, task) => sum + task.progress, 0);

        stats.achievements = tasks.length;
      }

      setStats(stats);
    } catch (error) {
      console.error('Error fetching user stats:', error);
      toast.error('Failed to load statistics');
    }
  };

  const handleEditProfile = async () => {
    if (isEditing) {
      try {
        await updateProfile({
          firstName: editForm.firstName,
          lastName: editForm.lastName,
          phone: editForm.phone
        });
        toast.success('Profile updated successfully!');
        setIsEditing(false);
      } catch (error) {
        toast.error('Failed to update profile');
      }
    } else {
      setIsEditing(true);
    }
  };

  return (
    <div className={`space-y-6 pb-20 ${theme === 'dark' ? 'text-gray-200' : ''}`}>
      {/* Profile Section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-indigo-50'} rounded-xl p-6 text-center relative`}>
        <div className={`w-20 h-20 ${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} rounded-full mx-auto mb-4 flex items-center justify-center shadow-md`}>
          <User size={36} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} />
        </div>
        
        {isEditing ? (
          <div className="space-y-4">
            <input
              type="text"
              value={editForm.firstName}
              onChange={(e) => setEditForm({ ...editForm, firstName: e.target.value })}
              className={`w-full p-2 rounded-lg ${
                theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-white'
              } text-center`}
              placeholder="First Name"
            />
            <input
              type="text"
              value={editForm.lastName}
              onChange={(e) => setEditForm({ ...editForm, lastName: e.target.value })}
              className={`w-full p-2 rounded-lg ${
                theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-white'
              } text-center`}
              placeholder="Last Name"
            />
            <input
              type="tel"
              value={editForm.phone}
              onChange={(e) => setEditForm({ ...editForm, phone: e.target.value })}
              className={`w-full p-2 rounded-lg ${
                theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-white'
              } text-center`}
              placeholder="Phone Number"
            />
          </div>
        ) : (
          <>
            <h2 className={`text-xl font-bold ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-800'}`}>{userName || 'Guest'}</h2>
            <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>@{userName ? userName.toLowerCase() : 'guest'}</p>
            <p className={`text-sm mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>Joined 2025</p>
          </>
        )}
        
        <button 
          onClick={handleEditProfile}
          className={`mt-3 ${theme === 'dark' ? 'bg-indigo-700' : 'bg-indigo-600'} text-white rounded-lg px-4 py-2 text-sm font-medium`}
        >
          {isEditing ? 'Save Profile' : t('editProfile')}
        </button>
      </div>

      {/* Prayer Times Data Source */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <h3 className={`font-medium ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} mb-4 flex items-center`}>
          <Sliders size={18} className={`mr-2 ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'}`} />
          {t('dataSource')}
        </h3>
        
        <div className="space-y-3">
          {/* Auto (Based on Location) */}
          <div 
            className={`p-3 rounded-lg flex items-center ${
              dataSource === 'auto' 
                ? (theme === 'dark' ? 'bg-indigo-900 border-indigo-700' : 'bg-indigo-50 border-indigo-200') 
                : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50')
            } border cursor-pointer`}
            onClick={() => setDataSource('auto')}
          >
            <div className={`mr-3 w-4 h-4 rounded-full ${
              dataSource === 'auto' 
                ? 'bg-indigo-500' 
                : (theme === 'dark' ? 'bg-gray-600' : 'bg-gray-300')
            } flex items-center justify-center`}>
              {dataSource === 'auto' && <Check size={10} className="text-white" />}
            </div>
            <div>
              <h4 className={`text-sm font-medium ${
                dataSource === 'auto' 
                  ? (theme === 'dark' ? 'text-indigo-300' : 'text-indigo-700') 
                  : (theme === 'dark' ? 'text-white' : 'text-gray-800')
              }`}>{t('basedOnLocation')}</h4>
              <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('basedOnLocationDesc')}
              </p>
            </div>
          </div>
          
          {/* Universal Islamic */}
          <div 
            className={`p-3 rounded-lg flex items-center ${
              dataSource === 'muslim_world_league' 
                ? (theme === 'dark' ? 'bg-indigo-900 border-indigo-700' : 'bg-indigo-50 border-indigo-200') 
                : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50')
            } border cursor-pointer`}
            onClick={() => setDataSource('muslim_world_league')}
          >
            <div className={`mr-3 w-4 h-4 rounded-full ${
              dataSource === 'muslim_world_league' 
                ? 'bg-indigo-500' 
                : (theme === 'dark' ? 'bg-gray-600' : 'bg-gray-300')
            } flex items-center justify-center`}>
              {dataSource === 'muslim_world_league' && <Check size={10} className="text-white" />}
            </div>
            <div>
              <h4 className={`text-sm font-medium ${
                dataSource === 'muslim_world_league' 
                  ? (theme === 'dark' ? 'text-indigo-300' : 'text-indigo-700') 
                  : (theme === 'dark' ? 'text-white' : 'text-gray-800')
              }`}>{t('universalIslamic')}</h4>
              <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('universalIslamicDesc')}
              </p>
            </div>
          </div>
          
          {/* Current Data Source Display */}
          <div className={`mt-2 p-3 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'}`}>
            <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
              {dataSource === 'auto' 
                ? `Current: ${getDataSourceForLocation(location, 'auto')} (auto-detected)` 
                : `Current: ${dataSource}`}
            </p>
          </div>
        </div>
      </div>

      {/* Regular Settings Section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <h3 className={`font-medium ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} mb-4 flex items-center`}>
          <Settings size={18} className={`mr-2 ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'}`} />
          {t('settings')}
        </h3>
        
        <div className="space-y-4">
          <div className={`flex justify-between items-center p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg`}>
            <div className="flex items-center">
              <User size={18} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'} />
              <span className="ml-3">{t('personalInfo')}</span>
            </div>
            <ChevronRight size={18} className={theme === 'dark' ? 'text-gray-500' : 'text-gray-400'} />
          </div>
          
          <div className={`p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg`}>
            <div className="flex items-center mb-2">
              <User size={18} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'} />
              <span className="ml-3">{t('gender')}</span>
            </div>
            <div className="flex ml-8 mt-1">
              <label className="flex items-center mr-4">
                <input 
                  type="radio" 
                  name="gender" 
                  checked={gender === 'male'} 
                  onChange={() => setGender('male')} 
                  className="mr-1"
                />
                <span className="text-sm">{t('male')}</span>
              </label>
              <label className="flex items-center">
                <input 
                  type="radio" 
                  name="gender" 
                  checked={gender === 'female'} 
                  onChange={() => setGender('female')} 
                  className="mr-1"
                />
                <span className="text-sm">{t('female')}</span>
              </label>
            </div>
          </div>
          
          {/* Theme toggle */}
          <div className={`p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg`}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                {theme === 'dark' ? 
                  <Moon size={18} className="text-indigo-300" /> : 
                  <Sun size={18} className="text-indigo-500" />
                }
                <span className="ml-3">{t('darkMode')}</span>
              </div>
              <div 
                className={`w-12 h-6 rounded-full flex items-center p-1 cursor-pointer ${
                  theme === 'dark' ? 'bg-indigo-600 justify-end' : 'bg-gray-300 justify-start'
                }`}
                onClick={toggleTheme}
              >
                <div className="w-4 h-4 bg-white rounded-full"></div>
              </div>
            </div>
          </div>
          
          <div className={`p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg`}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Globe size={18} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'} />
                <span className="ml-3">{t('language')}</span>
              </div>
              <div 
                className={`px-3 py-1 rounded ${
                  theme === 'dark' ? 'bg-indigo-900 text-white' : 'bg-indigo-100 text-indigo-800'
                } cursor-pointer flex items-center`}
                onClick={toggleLanguage}
              >
                {language === 'en' ? 'English' : 'Bahasa Indonesia'}
                <ChevronRight size={16} className="ml-1" />
              </div>
            </div>
          </div>
          
          {/* Data & Privacy Section */}
          <div className={`p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg`}>
            <div className="flex items-center mb-2">
              <Save size={18} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'} />
              <span className="ml-3">{t('dataAndPrivacy')}</span>
            </div>
            <div className="ml-8 mt-2 space-y-3">
              <div className={`p-3 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'} text-sm`}>
                <div className="flex items-center">
                  <AlertTriangle size={14} className={`mr-2 ${theme === 'dark' ? 'text-yellow-300' : 'text-yellow-600'}`} />
                  <span>{t('dataSaved')}</span>
                </div>
              </div>
              
              <button 
                className={`px-3 py-2 rounded-lg text-sm ${
                  theme === 'dark' ? 'bg-red-900 text-red-300' : 'bg-red-50 text-red-600'
                } w-full text-left flex items-center`}
              >
                <AlertTriangle size={14} className="mr-2" />
                {t('deleteAllData')}
              </button>
              
              <button 
                className={`px-3 py-2 rounded-lg text-sm ${
                  theme === 'dark' ? 'bg-indigo-900 text-indigo-300' : 'bg-indigo-50 text-indigo-600'
                } w-full text-left flex items-center`}
                onClick={saveToLocalStorage}
              >
                <Save size={14} className="mr-2" />
                {t('saveAllSettings')}
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <h3 className={`font-medium ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'} mb-4`}>{t('yourStats')}</h3>
        
        <div className="grid grid-cols-2 gap-4">
          <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-indigo-50'} p-3 rounded-lg text-center`}>
            <p className={`text-xs ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} mb-1`}>{t('daysTracked')}</p>
            <p className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-indigo-800'}`}>{stats.daysTracked}</p>
          </div>
          <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-green-50'} p-3 rounded-lg text-center`}>
            <p className={`text-xs ${theme === 'dark' ? 'text-green-300' : 'text-green-600'} mb-1`}>{t('prayersCompleted')}</p>
            <p className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-green-800'}`}>{stats.prayersCompleted}</p>
          </div>
          <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-yellow-50'} p-3 rounded-lg text-center`}>
            <p className={`text-xs ${theme === 'dark' ? 'text-yellow-300' : 'text-yellow-600'} mb-1`}>{t('quranSessions')}</p>
            <p className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-yellow-800'}`}>{stats.quranSessions}</p>
          </div>
          <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-purple-50'} p-3 rounded-lg text-center`}>
            <p className={`text-xs ${theme === 'dark' ? 'text-purple-300' : 'text-purple-600'} mb-1`}>{t('achievements')}</p>
            <p className={`text-2xl font-bold ${theme === 'dark' ? 'text-white' : 'text-purple-800'}`}>{stats.achievements}</p>
          </div>
        </div>
      </div>
      
      <button 
        onClick={onLogout}
        className={`w-full mt-4 p-3 flex items-center justify-center border ${
          theme === 'dark' ? 'border-red-700 text-red-400' : 'border-red-300 text-red-500'
        } rounded-lg`}
      >
        <LogOut size={18} className="mr-2" />
        <span>{t('logOut')}</span>
      </button>

      {/* About Section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-50'} rounded-lg p-4 text-center space-y-2`}>
        <div className="flex items-center justify-center space-x-1">
          <span>Made with</span>
          <Heart size={16} className="text-red-500 fill-current" />
        </div>
        <div className="text-sm text-gray-500">
          Version 1.0.0 (Initial Release)
          <br />
          Version 1.1.0 (Added Local Storage Support)
          <br />
          Version 1.2.0 (Added Offline Mode)
          <br />
          Version 1.3.0 (Added Multi-language Support)
          <br />
          Version 1.4.0 (Current - Enhanced UI/UX)
        </div>
        <div className="flex items-center justify-center space-x-2 text-sm">
          <Wrench size={14} className={theme === 'dark' ? 'text-gray-400' : 'text-gray-600'} />
          <a 
            href="https://www.nihbuatjajan.com/bmgvt7hflduz" 
            target="_blank" 
            rel="noopener noreferrer" 
            className={`${theme === 'dark' ? 'text-indigo-400 hover:text-indigo-300' : 'text-indigo-600 hover:text-indigo-500'}`}
          >
            Support future development
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProfileTab;