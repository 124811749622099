import { Translations } from '../types';

export const translations: Translations = {
  en: {
    // General app UI
    appName: "Ramadan App",
    greeting: "Hi",
    day: "Day",
    daysUntil: "days until Ramadan",
    locale: "en-US",
    
    // Prayer & Fasting
    fasting: "Fasting Times",
    nextPrayer: "Next Prayer",
    inTime: "in",
    hours: "h",
    minutes: "m",
    fajr: "Fajr",
    sunrise: "Sunrise",
    dhuhr: "Dhuhr",
    asr: "Asr",
    maghrib: "Maghrib",
    isha: "Isha",
    sahoorTime: "Sahoor Time",
    breakFastTime: "Iftar Time",
    sahoor: "Sahoor",
    iftar: "Iftar",
    
    // Tracking
    dailyPrayers: "Daily Prayers",
    sunnahActivities: "Sunnah Activities",
    prayedInMosque: "Prayed in congregation/mosque",
    periodDay: "Period Day",
    prayerTrackingDisabled: "Prayer tracking disabled during period days",
    markCompleted: "Mark as completed",
    quranReadingToday: "Quran reading today",
    amountGiven: "Amount given",
    verse: "Verse",
    page: "Page",
    juz: "Juz",
    
    // Achievements
    recentAchievement: "Recent Achievement",
    keepUpGoodWork: "Keep up the good work!",
    todaysHighlight: "Today's Highlight",
    todaysProgress: "Today's Progress",
    ramadanJourney: "Your Ramadan Journey",
    overallProgress: "Overall Progress",
    unlockedAchievements: "Unlocked Achievements",
    achievementsInProgress: "Achievements in Progress",
    progress: "Progress",
    days: "days",
    
    // Quran & Knowledge
    youveRead: "You've read {0} verses, {1} pages, and {2} juz in total",
    youveGiven: "You've given {0}{1} to charity in total",
    reflection: "Reflection",
    nextHighlight: "Next Highlight",
    source: "Source",
    
    // Notifications
    notifications: "Notifications",
    notificationsSettings: "Notification Settings",
    enablePrayerAlerts: "Enable Prayer Alerts",
    enableMealAlerts: "Enable Meal Alerts",
    
    // Qibla finder
    qiblaFinder: "Qibla Finder",
    findQibla: "Find Qibla Direction",
    qiblaDirectionIs: "Qibla direction is",
    degrees: "degrees",
    fromNorth: "from North",
    
    // Dua collection
    duaCollection: "Dua Collection",
    commonDuas: "Common Duas",
    viewDuas: "View Duas",
    backToAllDuas: "Back to all duas",
    transliteration: "Transliteration:",
    translation: "Translation:",
    whenToRecite: "When to recite:",
    
    // Calendar
    calendar: "Calendar",
    ramadanCalendar: "Ramadan Calendar",
    specialDays: "Special Days",
    laylatul: "Laylatul Qadr",
    mostLikely: "Most Likely",
    viewSpecialDays: "View special nights and important dates",
    
    // Notes
    dailyNote: "Daily Note",
    saveNote: "Save Note",
    addNote: "Add a note for today...",
    
    // Settings & Profile
    dataAndPrivacy: "Data & Privacy",
    dataSaved: "Data saved locally",
    deleteAllData: "Delete All Data",
    shareAchievement: "Share Achievement",
    settingsSaved: "Settings saved successfully!",
    compassCalibration: "Please calibrate your compass by moving your device in a figure-8 pattern",
    compassNotSupported: "Compass sensor not available on your device",
    compassPermissionDenied: "Permission to access compass was denied",
    compassPermissionError: "Error requesting compass permission",
    settings: "Settings",
    personalInfo: "Personal Information",
    appSettings: "App Settings",
    gender: "Gender",
    male: "Male",
    female: "Female",
    yourStats: "Your Stats",
    daysTracked: "Days Tracked",
    prayersCompleted: "Prayers Completed",
    quranSessions: "Quran Sessions",
    achievements: "Achievements",
    logOut: "Log Out",
    darkMode: "Dark Mode",
    language: "Language",
    english: "English",
    bahasa: "Bahasa Indonesia",
    location: "Location",
    autoDetect: "Auto Detect",
    saveAllSettings: "Save All Settings",
    editProfile: "Edit Profile",
    
    // Tabs
    home: "Home",
    track: "Track",
    info: "Info",
    profile: "Profile",
    personalize: "Personalize",
    
    // Data sources
    calculationMethod: "Calculation Method",
    dataSource: "Prayer Times Data Source",
    dataSourceDescription: "Choose how prayer times are calculated",
    basedOnLocation: "Based on Location",
    basedOnLocationDesc: "Automatically selects the best data source for your region",
    officialIndonesian: "Kemenag Indonesia",
    officialIndonesianDesc: "Official method by Indonesian Ministry of Religious Affairs",
    universalIslamic: "Universal Islamic",
    universalIslamicDesc: "Standard calculation used globally by many Muslims",
    
    // Personalization features
    customGoals: "Custom Goals",
    createCustomGoal: "Create Custom Goal",
    goalName: "Goal Name",
    goalDescription: "Goal Description",
    goalCategory: "Category",
    goalIcon: "Icon",
    goalTarget: "Target",
    endDate: "End Date",
    enterGoalName: "Enter goal name",
    enterGoalDescription: "Enter goal description (optional)",
    times: "times",
    goalTargetDescription: "How many times do you want to complete this goal?",
    endDateDescription: "When do you want to complete this goal by? (optional)",
    saveGoal: "Save Goal",
    cancel: "Cancel",
    prayer: "Prayer",
    quranReading: "Quran Reading",
    charity: "Charity",
    other: "Other",
    noCustomGoals: "No custom goals yet",
    clickToAddGoal: "Click to add a new goal",
    daysRemaining: "days remaining",
    dueToday: "Due today",

    // Daily Highlight Translations
    "Quran 2:183-184_translation": "O you who have believed, decreed upon you is fasting as it was decreed upon those before you that you may become righteous.",
    "Quran 2:183-184_reflection": "Fasting is prescribed to help us develop God-consciousness (taqwa) and self-restraint.",
    
    "Virtue of Ramadan_translation": "Whoever fasts during Ramadan out of sincere faith and hoping to attain Allah's rewards, then all his past sins will be forgiven.",
    "Virtue of Ramadan_reflection": "Ramadan is a time for spiritual renewal and seeking forgiveness.",
    
    "Quran 2:185_translation": "The month of Ramadan is that in which was revealed the Quran, a guidance for the people and clear proofs of guidance and criterion.",
    "Quran 2:185_reflection": "Ramadan marks the revelation of the Quran, making it a special time to connect with Allah's words.",
    
    "Gates of Paradise_translation": "When Ramadan begins, the gates of Paradise are opened, the gates of Hell are closed, and the devils are chained.",
    "Gates of Paradise_reflection": "Ramadan provides a unique spiritual environment for worship and good deeds.",
    
    "Quran 97:1-5_translation": "Indeed, We sent the Quran down during the Night of Decree. And what can make you know what is the Night of Decree? The Night of Decree is better than a thousand months.",
    "Quran 97:1-5_reflection": "Laylatul Qadr (Night of Decree) is a blessed night in Ramadan worth more than a thousand months of worship.",
    
    "Charity in Ramadan_translation": "The Prophet (ﷺ) was the most generous of people, and he was most generous during Ramadan.",
    "Charity in Ramadan_reflection": "Ramadan is a time to increase in generosity and charity, following the Prophet's example.",
    
    "Quran 2:186_translation": "And when My servants ask you concerning Me - indeed I am near. I respond to the invocation of the supplicant when he calls upon Me.",
    "Quran 2:186_reflection": "Allah is especially close to us during Ramadan, ready to answer our prayers and supplications.",

    // New translations
    share: "Share Progress",
    shared: "Progress shared successfully!",
    copiedToClipboard: "Progress copied to clipboard!",
    shareError: "Failed to share progress",
    shareProgress: "Share your progress",
    shareTitle: "My Ramadan Journey",
    shareDescription: "Track your Ramadan journey with me!"
  },
  id: {
    // General app UI
    appName: "Aplikasi Ramadan",
    greeting: "Halo",
    day: "Hari",
    daysUntil: "hari menuju Ramadan",
    locale: "id-ID",
    
    // Prayer & Fasting
    fasting: "Waktu Puasa",
    nextPrayer: "Shalat Berikutnya",
    inTime: "dalam",
    hours: "j",
    minutes: "m",
    fajr: "Subuh",
    sunrise: "Terbit",
    dhuhr: "Dzuhur",
    asr: "Ashar",
    maghrib: "Maghrib",
    isha: "Isya",
    sahoorTime: "Waktu Sahur",
    breakFastTime: "Waktu Berbuka",
    sahoor: "Sahur",
    iftar: "Berbuka",
    
    // Tracking
    dailyPrayers: "Shalat Harian",
    sunnahActivities: "Aktivitas Sunnah",
    prayedInMosque: "Shalat berjamaah/di masjid",
    periodDay: "Hari Menstruasi",
    prayerTrackingDisabled: "Pelacakan shalat dinonaktifkan selama hari menstruasi",
    markCompleted: "Tandai selesai",
    quranReadingToday: "Bacaan Quran hari ini",
    amountGiven: "Jumlah yang diberikan",
    verse: "Ayat",
    page: "Halaman",
    juz: "Juz",
    
    // Achievements
    recentAchievement: "Pencapaian Terbaru",
    keepUpGoodWork: "Pertahankan prestasi baik!",
    todaysHighlight: "Sorotan Hari Ini",
    todaysProgress: "Kemajuan Hari Ini",
    ramadanJourney: "Perjalanan Ramadan Anda",
    overallProgress: "Kemajuan Keseluruhan",
    unlockedAchievements: "Pencapaian Terbuka",
    achievementsInProgress: "Pencapaian dalam Proses",
    progress: "Kemajuan",
    days: "hari",
    
    // Quran & Knowledge
    youveRead: "Anda telah membaca {0} ayat, {1} halaman, dan {2} juz secara total",
    youveGiven: "Anda telah memberikan {0}{1} untuk sedekah secara total",
    reflection: "Renungan",
    nextHighlight: "Sorotan Berikutnya",
    source: "Sumber",
    
    // Notifications
    notifications: "Notifikasi",
    notificationsSettings: "Pengaturan Notifikasi",
    enablePrayerAlerts: "Aktifkan Peringatan Shalat",
    enableMealAlerts: "Aktifkan Peringatan Waktu Makan",
    
    // Qibla finder
    qiblaFinder: "Pencari Kiblat",
    findQibla: "Temukan Arah Kiblat",
    qiblaDirectionIs: "Arah kiblat adalah",
    degrees: "derajat",
    fromNorth: "dari Utara",
    
    // Dua collection
    duaCollection: "Koleksi Doa",
    commonDuas: "Doa-doa Umum",
    viewDuas: "Lihat Doa-doa",
    backToAllDuas: "Kembali ke semua doa",
    transliteration: "Transliterasi:",
    translation: "Terjemahan:",
    whenToRecite: "Kapan dibaca:",
    
    // Calendar
    calendar: "Kalender",
    ramadanCalendar: "Kalender Ramadan",
    specialDays: "Hari-hari Istimewa",
    laylatul: "Lailatul Qadr",
    mostLikely: "Kemungkinan Besar",
    viewSpecialDays: "Lihat malam-malam istimewa dan tanggal penting",
    
    // Notes
    dailyNote: "Catatan Harian",
    saveNote: "Simpan Catatan",
    addNote: "Tambahkan catatan untuk hari ini...",
    
    // Settings & Profile
    dataAndPrivacy: "Data & Privasi",
    dataSaved: "Data disimpan secara lokal",
    deleteAllData: "Hapus Semua Data",
    shareAchievement: "Bagikan Pencapaian",
    settingsSaved: "Pengaturan berhasil disimpan!",
    compassCalibration: "Harap kalibrasi kompas Anda dengan menggerakkan perangkat dalam pola angka 8",
    compassNotSupported: "Sensor kompas tidak tersedia di perangkat Anda",
    compassPermissionDenied: "Izin untuk mengakses kompas ditolak",
    compassPermissionError: "Error saat meminta izin kompas",
    settings: "Pengaturan",
    personalInfo: "Informasi Pribadi",
    appSettings: "Pengaturan Aplikasi",
    gender: "Jenis Kelamin",
    male: "Laki-laki",
    female: "Perempuan",
    yourStats: "Statistik Anda",
    daysTracked: "Hari Dipantau",
    prayersCompleted: "Shalat Selesai",
    quranSessions: "Sesi Quran",
    achievements: "Pencapaian",
    logOut: "Keluar",
    darkMode: "Mode Gelap",
    language: "Bahasa",
    english: "English",
    bahasa: "Bahasa Indonesia",
    location: "Lokasi",
    autoDetect: "Deteksi Otomatis",
    saveAllSettings: "Simpan Semua Pengaturan",
    editProfile: "Edit Profil",
    
    // Tabs
    home: "Beranda",
    track: "Pantau",
    info: "Info",
    profile: "Profil",
    personalize: "Personalisasi",
    
    // Data sources
    calculationMethod: "Metode Perhitungan",
    dataSource: "Sumber Data Waktu Shalat",
    dataSourceDescription: "Pilih cara perhitungan waktu shalat",
    basedOnLocation: "Berdasarkan Lokasi",
    basedOnLocationDesc: "Secara otomatis memilih sumber data terbaik untuk wilayah Anda",
    officialIndonesian: "Kemenag Indonesia",
    officialIndonesianDesc: "Metode resmi oleh Kementerian Agama Indonesia",
    universalIslamic: "Islam Universal",
    universalIslamicDesc: "Perhitungan standar yang digunakan secara global oleh banyak Muslim",
    
    // Personalization features
    customGoals: "Target Kustom",
    createCustomGoal: "Buat Target Kustom",
    goalName: "Nama Target",
    goalDescription: "Deskripsi Target",
    goalCategory: "Kategori",
    goalIcon: "Ikon",
    goalTarget: "Target",
    endDate: "Tanggal Selesai",
    enterGoalName: "Masukkan nama target",
    enterGoalDescription: "Masukkan deskripsi target (opsional)",
    times: "kali",
    goalTargetDescription: "Berapa kali Anda ingin menyelesaikan target ini?",
    endDateDescription: "Kapan Anda ingin menyelesaikan target ini? (opsional)",
    saveGoal: "Simpan Target",
    cancel: "Batal",
    prayer: "Shalat",
    quranReading: "Quran",
    charity: "Sedekah",
    other: "Lainnya",
    noCustomGoals: "Belum ada target kustom",
    clickToAddGoal: "Klik untuk menambahkan target baru",
    daysRemaining: "hari tersisa",
    dueToday: "Jatuh tempo hari ini",

    // Daily Highlight Translations
    "Quran 2:183-184_translation": "Wahai orang-orang yang beriman! Diwajibkan atas kamu berpuasa sebagaimana diwajibkan atas orang-orang sebelum kamu agar kamu bertakwa.",
    "Quran 2:183-184_reflection": "Puasa diwajibkan untuk membantu kita mengembangkan kesadaran akan Allah (takwa) dan pengendalian diri.",
    
    "Virtue of Ramadan_translation": "Barangsiapa yang berpuasa di bulan Ramadhan karena iman dan mengharap pahala dari Allah, maka diampuni dosa-dosanya yang telah lalu.",
    "Virtue of Ramadan_reflection": "Ramadan adalah waktu untuk pembaruan spiritual dan mencari pengampunan.",
    
    "Quran 2:185_translation": "Bulan Ramadhan adalah (bulan) yang di dalamnya diturunkan Al-Qur'an, sebagai petunjuk bagi manusia dan penjelasan-penjelasan mengenai petunjuk itu dan pembeda (antara yang benar dan yang batil).",
    "Quran 2:185_reflection": "Ramadan menandai turunnya Al-Quran, menjadikannya waktu khusus untuk terhubung dengan firman Allah.",
    
    "Gates of Paradise_translation": "Ketika Ramadhan tiba, pintu-pintu surga dibuka, pintu-pintu neraka ditutup, dan setan-setan dibelenggu.",
    "Gates of Paradise_reflection": "Ramadan menyediakan lingkungan spiritual yang unik untuk ibadah dan amal baik.",
    
    "Quran 97:1-5_translation": "Sesungguhnya Kami telah menurunkannya (Al-Qur'an) pada malam qadar. Dan tahukah kamu apakah malam qadar itu? Malam qadar itu lebih baik dari seribu bulan.",
    "Quran 97:1-5_reflection": "Lailatul Qadr (Malam Kemuliaan) adalah malam yang diberkahi di bulan Ramadan yang nilainya lebih dari seribu bulan ibadah.",
    
    "Charity in Ramadan_translation": "Nabi ﷺ adalah orang yang paling dermawan, dan beliau paling dermawan di bulan Ramadhan.",
    "Charity in Ramadan_reflection": "Ramadan adalah waktu untuk meningkatkan kedermawanan dan sedekah, mengikuti teladan Nabi.",
    
    "Quran 2:186_translation": "Dan apabila hamba-hamba-Ku bertanya kepadamu (Muhammad) tentang Aku, maka sesungguhnya Aku dekat. Aku kabulkan permohonan orang yang berdoa apabila dia berdoa kepada-Ku.",
    "Quran 2:186_reflection": "Allah sangat dekat dengan kita selama Ramadan, siap menjawab doa dan permohonan kita.",

    // New translations
    share: "Bagikan Progress",
    shared: "Progress berhasil dibagikan!",
    copiedToClipboard: "Progress disalin ke clipboard!",
    shareError: "Gagal membagikan progress",
    shareProgress: "Bagikan progress Anda",
    shareTitle: "Perjalanan Ramadan Saya",
    shareDescription: "Ikuti perjalanan Ramadan saya!"
  }
};

export const getTranslation = (language: string, key: string, ...args: any[]) => {
  const text = translations[language]?.[key] || key;
  if (args.length) {
    return text.replace(/{(\d+)}/g, (match, number) => {
      return typeof args[number] !== 'undefined' ? args[number] : match;
    });
  }
  return text;
};