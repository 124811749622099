import React from 'react';
import { User, Bell, ChevronRight, Settings, LogOut, Moon, Sun, Globe } from 'lucide-react';

interface HeaderProps {
  userName: string;
  theme: 'light' | 'dark';
  language: string;
  showProfileMenu: boolean;
  setShowProfileMenu: (show: boolean) => void;
  setActiveTab: (tab: string) => void;
  onLogout: () => void;
  onLanguageChange: () => void;
  t: (key: string, ...args: any[]) => string;
}

const Header: React.FC<HeaderProps> = ({
  userName,
  theme,
  language,
  showProfileMenu,
  setShowProfileMenu,
  setActiveTab,
  onLogout,
  onLanguageChange,
  t
}) => {
  return (
    <div className="flex justify-between items-center mb-4 relative">
      <div className="flex items-center">
        <Moon size={20} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} />
        <h1 className={`text-xl font-bold ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-800'} ml-2`}>
          {t('greeting')}, {userName || t('guest')}
        </h1>
      </div>
      
      <div className="flex items-center space-x-2">
        {/* Language Toggle with Flag */}
        <button
          onClick={onLanguageChange}
          className={`px-3 py-2 ${theme === 'dark' ? 'bg-gray-800 text-indigo-300 hover:bg-gray-700' : 'bg-indigo-50 text-indigo-600 hover:bg-indigo-100'} rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2`}
          title={language === 'en' ? 'Switch to Bahasa Indonesia' : 'Switch to English'}
        >
          <span className="text-lg leading-none">
            {language === 'en' ? '🇺🇸' : '🇮🇩'}
          </span>
          <Globe size={16} />
        </button>
        
        {/* Profile Button */}
        <button 
          className={`p-2 ${theme === 'dark' ? 'bg-gray-800 hover:bg-gray-700' : 'bg-indigo-50 hover:bg-indigo-100'} rounded-full cursor-pointer transition-colors duration-200`}
          onClick={() => setShowProfileMenu(!showProfileMenu)}
        >
          <User size={20} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} />
        </button>
      </div>
      
      {showProfileMenu && (
        <div className={`absolute top-12 right-0 w-48 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl shadow-lg p-3 z-10`}>
          <div className="mb-2 pb-2 border-b border-gray-700">
            <div className="flex items-center">
              <div className={`w-8 h-8 ${theme === 'dark' ? 'bg-gray-700' : 'bg-indigo-100'} rounded-full flex items-center justify-center`}>
                <User size={16} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} />
              </div>
              <div className="ml-2">
                <p className={`text-sm font-medium ${theme === 'dark' ? 'text-white' : ''}`}>{userName || t('guest')}</p>
                <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                  @{userName ? userName.toLowerCase() : 'guest'}
                </p>
              </div>
            </div>
          </div>
          
          <div onClick={() => {
            setActiveTab('profile');
            setShowProfileMenu(false);
          }} className={`flex items-center p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg cursor-pointer`}>
            <User size={16} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'} />
            <span className={`text-sm ml-2 ${theme === 'dark' ? 'text-gray-200' : ''}`}>{t('profile')}</span>
          </div>
          
          <div onClick={() => {
            setActiveTab('personalize');
            setShowProfileMenu(false);
          }} className={`flex items-center p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg cursor-pointer`}>
            <Settings size={16} className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-500'} />
            <span className={`text-sm ml-2 ${theme === 'dark' ? 'text-gray-200' : ''}`}>{t('settings')}</span>
          </div>
          
          <div 
            onClick={() => {
              onLogout();
              setShowProfileMenu(false);
            }} 
            className={`flex items-center p-2 ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-50'} rounded-lg cursor-pointer ${
              theme === 'dark' ? 'text-red-400' : 'text-red-500'
            } mt-2 border-t pt-2 ${theme === 'dark' ? 'border-gray-700' : ''}`}
          >
            <LogOut size={16} className="mr-2" />
            <span className="text-sm">{t('logOut')}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;