import React, { useEffect } from 'react';
import { Calendar, Moon, User, Users, Book, Award } from 'lucide-react';
import { Theme, Task, SunnahTask } from '../../types';
import { getDailyTracking, updateDailyTracking, checkAndResetDailyTracking } from '../../lib/localStorage';
import toast from 'react-hot-toast';

interface TrackTabProps {
  theme: Theme;
  gender: string;
  periodDay: boolean;
  setPeriodDay: (value: boolean) => void;
  userTasks: Task[];
  userSunnahTasks: SunnahTask[];
  toggleTask: (id: number, field?: string) => void;
  toggleSunnahTask: (id: number) => void;
  quranReadingAmount: number;
  quranReadingType: string;
  updateQuranReading: (amount: string, type: string) => void;
  totalQuranRead: { verse: number; page: number; juz: number };
  charityAmount: number;
  charityCurrency: string;
  updateCharityAmount: (amount: string) => void;
  setCharityCurrency: (currency: string) => void;
  totalCharityGiven: number;
  completionPercentage: number;
  t: (key: string, ...args: any[]) => string;
}

const TrackTab: React.FC<TrackTabProps> = ({
  theme,
  gender,
  periodDay,
  setPeriodDay,
  userTasks,
  userSunnahTasks,
  toggleTask,
  toggleSunnahTask,
  quranReadingAmount,
  quranReadingType,
  updateQuranReading,
  totalQuranRead,
  charityAmount,
  charityCurrency,
  updateCharityAmount,
  setCharityCurrency,
  totalCharityGiven,
  completionPercentage,
  t
}) => {
  // Load saved tracking data on component mount and check for new day
  useEffect(() => {
    try {
      const tracking = checkAndResetDailyTracking();
      
      // Update period day state
      setPeriodDay(tracking.periodDay || false);

      // Update tasks state
      if (tracking.tasks) {
        tracking.tasks.forEach((savedTask: Task) => {
          const existingTask = userTasks.find(t => t.id === savedTask.id);
          if (existingTask) {
            if (existingTask.completed !== savedTask.completed) {
              toggleTask(savedTask.id);
            }
            if (existingTask.inMosque !== savedTask.inMosque) {
              toggleTask(savedTask.id, 'inMosque');
            }
          }
        });
      }

      // Update sunnah tasks state
      if (tracking.sunnahTasks) {
        tracking.sunnahTasks.forEach((savedTask: SunnahTask) => {
          const existingTask = userSunnahTasks.find(t => t.id === savedTask.id);
          if (existingTask && existingTask.completed !== savedTask.completed) {
            toggleSunnahTask(savedTask.id);
          }
        });
      }

      // Update Quran reading state if different
      if (tracking.quranReadingAmount !== undefined && 
          tracking.quranReadingAmount !== quranReadingAmount) {
        updateQuranReading(
          tracking.quranReadingAmount.toString(),
          tracking.quranReadingType || 'verse'
        );
      }

      // Update charity state if different
      if (tracking.charityAmount !== undefined && 
          tracking.charityAmount !== charityAmount) {
        updateCharityAmount(tracking.charityAmount.toString());
        if (tracking.charityCurrency) {
          setCharityCurrency(tracking.charityCurrency);
        }
      }
    } catch (error) {
      console.error('Error loading tracking data:', error);
      toast.error(t('failedToLoadTracking'));
    }
  }, []);

  // Save tracking data whenever it changes
  useEffect(() => {
    try {
      const trackingData = {
        tasks: userTasks.map(task => ({
          id: task.id,
          name: task.name,
          completed: task.completed,
          inMosque: task.inMosque
        })),
        sunnahTasks: userSunnahTasks.map(task => ({
          id: task.id,
          name: task.name,
          completed: task.completed,
          readingType: task.readingType,
          readingAmount: task.readingAmount,
          amount: task.amount,
          currency: task.currency
        })),
        quranReadingAmount,
        quranReadingType,
        charityAmount,
        charityCurrency,
        periodDay
      };

      updateDailyTracking(trackingData);
    } catch (error) {
      console.error('Error saving tracking data:', error);
      toast.error(t('failedToSaveProgress'));
    }
  }, [
    userTasks,
    userSunnahTasks,
    quranReadingAmount,
    quranReadingType,
    charityAmount,
    charityCurrency,
    periodDay
  ]);

  return (
    <div className={`space-y-6 ${theme === 'dark' ? 'text-gray-200' : ''}`}>
      {/* Period tracker for female users */}
      {gender === 'female' && (
        <div className={`${theme === 'dark' ? 'bg-pink-900' : 'bg-pink-50'} rounded-xl p-4 shadow-sm`}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <User size={20} className={`${theme === 'dark' ? 'text-pink-300' : 'text-pink-500'} mr-2`} />
              <h3 className={`font-medium ${theme === 'dark' ? 'text-pink-300' : 'text-pink-700'}`}>{t('periodDay')}</h3>
            </div>
            <div 
              className={`w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors duration-300 ${
                periodDay ? (theme === 'dark' ? 'bg-pink-600' : 'bg-pink-500') : (theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200')
              }`}
              onClick={() => setPeriodDay(!periodDay)}
            >
              {periodDay && <span className="text-white">✓</span>}
            </div>
          </div>
          {periodDay && (
            <p className={`text-xs mt-2 ${theme === 'dark' ? 'text-pink-300' : 'text-pink-600'}`}>
              {t('prayerTrackingDisabled')}
            </p>
          )}
        </div>
      )}
      
      {/* Daily prayers section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <div className="flex items-center mb-4">
          <Calendar size={20} className={`${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'} mr-2`} />
          <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('dailyPrayers')}</h2>
        </div>
        <div className="space-y-3">
          {userTasks.map((task) => (
            <div 
              key={task.id} 
              className={`p-3 rounded-lg transition-colors duration-300 ${
                task.completed 
                  ? (theme === 'dark' ? 'bg-green-900' : 'bg-green-50') 
                  : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50')
              } ${(gender === 'female' && periodDay) ? 'opacity-50' : ''}`}
            >
              <div className="flex items-center justify-between mb-1">
                <div className="flex items-center">
                  {task.icon}
                  <span className={`ml-2 ${
                    task.completed 
                      ? (theme === 'dark' ? 'text-green-300 font-medium' : 'text-green-700 font-medium') 
                      : (theme === 'dark' ? 'text-gray-300' : 'text-gray-700')
                  }`}>
                    {task.name}
                  </span>
                </div>
                
                <div 
                  className={`w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors duration-300 ${
                    task.completed 
                      ? 'bg-green-500' 
                      : (theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200')
                  } ${(gender === 'female' && periodDay) ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                  onClick={() => {
                    if (!(gender === 'female' && periodDay)) {
                      toggleTask(task.id);
                    }
                  }}
                >
                  {task.completed && <span className="text-white">✓</span>}
                </div>
              </div>
              
              {/* Second check for prayers in mosque (only for males) */}
              {gender === 'male' && (
                <div className="flex items-center justify-between mt-2 ml-6 text-sm">
                  <span className={`flex items-center ${
                    task.inMosque 
                      ? (theme === 'dark' ? 'text-blue-300' : 'text-blue-600') 
                      : (theme === 'dark' ? 'text-gray-400' : 'text-gray-500')
                  }`}>
                    <Users size={14} className="mr-2" />
                    {t('prayedInMosque')}
                  </span>
                  <div 
                    className={`w-5 h-5 rounded-full flex items-center justify-center cursor-pointer transition-colors duration-300 ${
                      task.inMosque 
                        ? 'bg-blue-500' 
                        : (theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200')
                    }`}
                    onClick={() => toggleTask(task.id, 'inMosque')}
                  >
                    {task.inMosque && <span className="text-white text-xs">✓</span>}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      {/* Sunnah activities section */}
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <div className="flex items-center mb-4">
          <Moon size={20} className={`${theme === 'dark' ? 'text-purple-300' : 'text-purple-600'} mr-2`} />
          <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('sunnahActivities')}</h2>
        </div>
        <div className="space-y-3">
          {userSunnahTasks.map((task) => (
            <div 
              key={task.id} 
              className={`p-3 rounded-lg transition-colors duration-300 ${
                task.completed 
                  ? (theme === 'dark' ? 'bg-green-900' : 'bg-green-50') 
                  : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50')
              }`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {task.icon}
                  <span className={`ml-2 ${
                    task.completed 
                      ? (theme === 'dark' ? 'text-green-300 font-medium' : 'text-green-700 font-medium') 
                      : (theme === 'dark' ? 'text-gray-300' : 'text-gray-700')
                  }`}>
                    {task.name}
                  </span>
                </div>
                
                <div 
                  className={`w-6 h-6 rounded-full flex items-center justify-center cursor-pointer transition-colors duration-300 ${
                    task.completed 
                      ? 'bg-green-500' 
                      : (theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200')
                  }`}
                  onClick={() => toggleSunnahTask(task.id)}
                >
                  {task.completed && <span className="text-white">✓</span>}
                </div>
              </div>
              
              {/* Quran reading input */}
              {task.id === 2 && task.completed && (
                <div className="mt-3 ml-6">
                  <div className="flex items-center mb-2">
                    <label className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} block`}>
                      {t('quranReadingToday')}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <div className="flex-1 mr-2">
                      <input 
                        type="number" 
                        placeholder="Amount" 
                        className={`w-full p-2 rounded border ${
                          theme === 'dark' 
                            ? 'bg-gray-700 border-gray-600 text-white' 
                            : 'border-gray-300'
                        } text-sm`}
                        value={quranReadingAmount || ''}
                        onChange={(e) => updateQuranReading(e.target.value, quranReadingType)}
                      />
                    </div>
                    <select 
                      className={`p-2 rounded border ${
                        theme === 'dark' 
                          ? 'bg-gray-700 border-gray-600 text-white' 
                          : 'border-gray-300'
                      } text-sm`}
                      value={quranReadingType}
                      onChange={(e) => updateQuranReading(quranReadingAmount.toString(), e.target.value)}
                    >
                      <option value="verse">{t('verse')}</option>
                      <option value="page">{t('page')}</option>
                      <option value="juz">{t('juz')}</option>
                    </select>
                  </div>
                </div>
              )}
              
              {/* Charity amount input */}
              {task.id === 5 && task.completed && (
                <div className="mt-3 ml-6">
                  <div className="mb-2">
                    <label className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} block`}>
                      {t('amountGiven')}
                    </label>
                  </div>
                  
                  <div className="flex items-center">
                    <div className="flex-1 mr-2">
                      <input 
                        type="number" 
                        placeholder="Amount" 
                        className={`w-full p-2 rounded border ${
                          theme === 'dark' 
                            ? 'bg-gray-700 border-gray-600 text-white' 
                            : 'border-gray-300'
                        } text-sm`}
                        value={charityAmount || ''}
                        onChange={(e) => updateCharityAmount(e.target.value)}
                      />
                    </div>
                    <select 
                      className={`p-2 rounded border ${
                        theme === 'dark' 
                          ? 'bg-gray-700 border-gray-600 text-white' 
                          : 'border-gray-300'
                      } text-sm`}
                      value={charityCurrency}
                      onChange={(e) => setCharityCurrency(e.target.value)}
                    >
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="IDR">IDR</option>
                      <option value="MYR">MYR</option>
                      <option value="SGD">SGD</option>
                      <option value="SAR">SAR</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-indigo-50'} rounded-xl p-4`}>
        <div className="flex justify-between items-center mb-2">
          <h3 className={`font-semibold ${theme === 'dark' ? 'text-white' : ''}`}>{t('todaysProgress')}</h3>
          <span className={`font-bold ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-700'}`}>
            {completionPercentage}%
          </span>
        </div>
        <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-2.5`}>
          <div 
            className="bg-indigo-600 h-2.5 rounded-full" 
            style={{ width: `${completionPercentage}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default TrackTab;