import React from 'react';
import { X } from 'lucide-react';
import { Dua } from '../../types';

interface DuaModalProps {
  showModal: boolean;
  selectedDua: Dua | null;
  commonDuas: Dua[];
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
  onClose: () => void;
  onSelectDua: (dua: Dua | null) => void;
}

const DuaModal: React.FC<DuaModalProps> = ({
  showModal,
  selectedDua,
  commonDuas,
  theme,
  t,
  onClose,
  onSelectDua
}) => {
  if (!showModal) return null;
  
  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${theme === 'dark' ? 'bg-black/70' : 'bg-gray-500/70'}`}>
      <div className={`w-full max-w-md ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg overflow-hidden`}>
        <div className={`p-4 border-b ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-between items-center">
            <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('duaCollection')}</h2>
            <button 
              onClick={onClose}
              className={`p-1 rounded-full ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
            >
              <X size={20} />
            </button>
          </div>
        </div>
        
        <div className="h-96 overflow-y-auto p-4">
          {selectedDua ? (
            <div className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-white'} p-4 rounded-lg`}>
              <button 
                onClick={() => onSelectDua(null)}
                className={`mb-4 px-3 py-1 rounded-full text-xs ${theme === 'dark' ? 'bg-gray-600 text-gray-300' : 'bg-gray-200 text-gray-600'} flex items-center`}
              >
                ← {t('backToAllDuas')}
              </button>
              
              <h3 className={`text-lg font-bold mb-3 ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'}`}>
                {t(selectedDua.title)}
              </h3>
              
              <div className={`p-4 rounded-lg mb-4 text-right ${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-50'}`}>
                <p className={`text-xl leading-loose ${theme === 'dark' ? 'text-gray-200' : 'text-gray-800'}`}>
                  {selectedDua.arabic}
                </p>
              </div>
              
              <div className={`mb-3 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                <h4 className="font-medium text-sm mb-1">{t('transliteration')}</h4>
                <p className="text-sm italic">{selectedDua.transliteration}</p>
              </div>
              
              <div className={`mb-3 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                <h4 className="font-medium text-sm mb-1">{t('translation')}</h4>
                <p className="text-sm">{selectedDua.english}</p>
              </div>
              
              <div className={`text-xs rounded-lg p-3 ${theme === 'dark' ? 'bg-indigo-900 text-indigo-300' : 'bg-indigo-50 text-indigo-700'}`}>
                <span className="font-medium">{t('whenToRecite')} </span>
                {t(selectedDua.context)}
              </div>
            </div>
          ) : (
            commonDuas.map(dua => (
              <div 
                key={dua.id} 
                className={`p-4 rounded-lg mb-3 cursor-pointer ${
                  theme === 'dark' ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-50 hover:bg-gray-100'
                }`}
                onClick={() => onSelectDua(dua)}
              >
                <h3 className={`font-medium ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'}`}>{t(dua.title)}</h3>
                <p className={`text-sm mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} line-clamp-1`}>
                  {dua.english}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default DuaModal;