import React, { useState } from 'react';
import { X, Plus, Check, Calendar, Target, BookOpen, Heart, Award, Bookmark } from 'lucide-react';
import { CustomGoal, Theme } from '../../types';
import { calculateRamadanDay } from '../../utils/helpers';

interface CustomGoalModalProps {
  showModal: boolean;
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
  onClose: () => void;
  onSaveGoal: (goal: Omit<CustomGoal, 'id' | 'createdAt'>) => void;
  existingGoals: CustomGoal[];
}

const CustomGoalModal: React.FC<CustomGoalModalProps> = ({
  showModal,
  theme,
  t,
  onClose,
  onSaveGoal,
  existingGoals
}) => {
  const [goalName, setGoalName] = useState('');
  const [goalDescription, setGoalDescription] = useState('');
  const [goalTarget, setGoalTarget] = useState(1);
  const [goalCategory, setGoalCategory] = useState<'prayer' | 'quran' | 'charity' | 'other'>('prayer');
  const [goalIcon, setGoalIcon] = useState('🕌');
  const [endDate, setEndDate] = useState('2025-03-30'); // Set default end date to Ramadan end
  
  const { remainingDays } = calculateRamadanDay();
  
  const categoryIcons = {
    prayer: ['🕌', '🤲', '📿', '🧎', '⭐'],
    quran: ['📖', '📚', '📜', '🔖', '✨'],
    charity: ['💰', '🤝', '❤️', '🎁', '💝'],
    other: ['🌙', '🌟', '🔔', '📅', '🏆']
  };
  
  const handleSaveGoal = () => {
    if (!goalName.trim()) {
      return;
    }
    
    const newGoal = {
      name: goalName,
      description: goalDescription,
      target: goalTarget,
      progress: 0,
      completed: false,
      category: goalCategory,
      icon: goalIcon,
      endDate: endDate ? new Date(endDate) : undefined
    };
    
    onSaveGoal(newGoal);
    resetForm();
    onClose();
  };
  
  const resetForm = () => {
    setGoalName('');
    setGoalDescription('');
    setGoalTarget(1);
    setGoalCategory('prayer');
    setGoalIcon('🕌');
    setEndDate('2025-03-30');
  };
  
  if (!showModal) return null;
  
  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${theme === 'dark' ? 'bg-black/70' : 'bg-gray-500/70'}`}>
      <div className={`w-full max-w-md ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg overflow-hidden`}>
        <div className={`p-4 border-b ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-between items-center">
            <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('createCustomGoal')}</h2>
            <button 
              onClick={onClose}
              className={`p-1 rounded-full ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
            >
              <X size={20} />
            </button>
          </div>
        </div>
        
        <div className="p-4 max-h-[70vh] overflow-y-auto">
          <div className="space-y-4">
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('goalName')} *
              </label>
              <input
                type="text"
                value={goalName}
                onChange={(e) => setGoalName(e.target.value)}
                placeholder={t('enterGoalName')}
                className={`w-full p-2 rounded border ${
                  theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                }`}
                required
              />
            </div>
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('goalDescription')}
              </label>
              <textarea
                value={goalDescription}
                onChange={(e) => setGoalDescription(e.target.value)}
                placeholder={t('enterGoalDescription')}
                className={`w-full p-2 rounded border ${
                  theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                } min-h-[80px]`}
              />
            </div>
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('goalCategory')}
              </label>
              <div className="grid grid-cols-2 gap-2">
                <button
                  onClick={() => setGoalCategory('prayer')}
                  className={`p-2 rounded-lg flex items-center ${
                    goalCategory === 'prayer' 
                      ? (theme === 'dark' ? 'bg-indigo-900 text-indigo-200' : 'bg-indigo-100 text-indigo-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <BookOpen size={16} className="mr-2" />
                  {t('prayer')}
                </button>
                <button
                  onClick={() => setGoalCategory('quran')}
                  className={`p-2 rounded-lg flex items-center ${
                    goalCategory === 'quran' 
                      ? (theme === 'dark' ? 'bg-blue-900 text-blue-200' : 'bg-blue-100 text-blue-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <Bookmark size={16} className="mr-2" />
                  {t('quran')}
                </button>
                <button
                  onClick={() => setGoalCategory('charity')}
                  className={`p-2 rounded-lg flex items-center ${
                    goalCategory === 'charity' 
                      ? (theme === 'dark' ? 'bg-green-900 text-green-200' : 'bg-green-100 text-green-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <Heart size={16} className="mr-2" />
                  {t('charity')}
                </button>
                <button
                  onClick={() => setGoalCategory('other')}
                  className={`p-2 rounded-lg flex items-center ${
                    goalCategory === 'other' 
                      ? (theme === 'dark' ? 'bg-purple-900 text-purple-200' : 'bg-purple-100 text-purple-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <Award size={16} className="mr-2" />
                  {t('other')}
                </button>
              </div>
            </div>
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('goalIcon')}
              </label>
              <div className="grid grid-cols-5 gap-2">
                {categoryIcons[goalCategory].map((icon, index) => (
                  <button
                    key={index}
                    onClick={() => setGoalIcon(icon)}
                    className={`p-2 rounded-lg text-xl flex items-center justify-center ${
                      goalIcon === icon 
                        ? (theme === 'dark' ? 'bg-indigo-900' : 'bg-indigo-100') 
                        : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                    }`}
                  >
                    {icon}
                  </button>
                ))}
              </div>
            </div>
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('goalTarget')}
              </label>
              <div className="flex items-center">
                <input
                  type="number"
                  min="1"
                  value={goalTarget}
                  onChange={(e) => setGoalTarget(parseInt(e.target.value) || 1)}
                  className={`w-full p-2 rounded-l border ${
                    theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                  }`}
                />
                <div className={`p-2 rounded-r border-t border-r border-b ${
                  theme === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-300' : 'bg-gray-100 border-gray-300 text-gray-700'
                }`}>
                  {t('times')}
                </div>
              </div>
              <p className={`text-xs mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('goalTargetDescription')} ({remainingDays} {t('daysRemaining')})
              </p>
            </div>
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('endDate')}
              </label>
              <div className="flex items-center">
                <div className={`p-2 rounded-l border-t border-l border-b ${
                  theme === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-300' : 'bg-gray-100 border-gray-300 text-gray-700'
                }`}>
                  <Calendar size={16} />
                </div>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  max="2025-03-30"
                  className={`w-full p-2 rounded-r border ${
                    theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                  }`}
                />
              </div>
              <p className={`text-xs mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('endDateDescription')}
              </p>
            </div>
          </div>
        </div>
        
        <div className={`p-4 border-t ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-end space-x-2">
            <button
              onClick={onClose}
              className={`px-4 py-2 rounded ${
                theme === 'dark' ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700'
              }`}
            >
              {t('cancel')}
            </button>
            <button
              onClick={handleSaveGoal}
              disabled={!goalName.trim()}
              className={`px-4 py-2 rounded flex items-center ${
                !goalName.trim()
                  ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                  : (theme === 'dark' ? 'bg-indigo-600 text-white' : 'bg-indigo-600 text-white')
              }`}
            >
              <Check size={16} className="mr-2" />
              {t('saveGoal')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomGoalModal;