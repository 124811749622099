import React, { useState, useEffect } from 'react';
import { Moon, Star, Calendar, Sparkles, CheckCircle, Share2 } from 'lucide-react';
import { Theme, Language, PrayerTime, MealTime, DailyHighlight, Achievement } from '../../types';
import NextPrayer from '../NextPrayer';
import MealTimes from '../MealTimes';
import DailyHighlightComponent from '../DailyHighlight';
import QiblaFinder from '../QiblaFinder';
import DuaCollection from '../DuaCollection';
import RamadanCalendarButton from '../RamadanCalendarButton';
import RecentAchievement from '../RecentAchievement';
import ProgressSummary from '../ProgressSummary';
import { getDailyTracking } from '../../lib/localStorage';
import toast from 'react-hot-toast';

interface HomeTabProps {
  theme: Theme;
  language: Language;
  currentDate: string;
  ramadanDay: number | null;
  daysUntilRamadan: number;
  nextPrayer: PrayerTime;
  nextMeal: MealTime;
  currentHighlight: DailyHighlight;
  qiblaDirection: number | null;
  duasCount: number;
  recentAchievement: Achievement;
  completionPercentage: number;
  completedTasks: number;
  totalTasks: number;
  completedSunnahTasks: number;
  totalSunnahTasks: number;
  unlockedAchievements: number;
  totalAchievements: number;
  savedSettings: boolean;
  t: (key: string, ...args: any[]) => string;
  onNextHighlight: () => void;
  onCalculateQibla: () => void;
  onOpenDuaModal: () => void;
  onOpenCalendarModal: () => void;
}

const APP_URL = 'https://myramadan.app';

const HomeTab: React.FC<HomeTabProps> = ({
  theme,
  language,
  currentDate,
  ramadanDay,
  daysUntilRamadan,
  nextPrayer,
  nextMeal,
  currentHighlight,
  qiblaDirection,
  duasCount,
  recentAchievement,
  completionPercentage,
  completedTasks,
  totalTasks,
  completedSunnahTasks,
  totalSunnahTasks,
  unlockedAchievements,
  totalAchievements,
  savedSettings,
  t,
  onNextHighlight,
  onCalculateQibla,
  onOpenDuaModal,
  onOpenCalendarModal
}) => {
  const [isSharing, setIsSharing] = useState(false);
  const [showShareText, setShowShareText] = useState(false);
  const [dailyData, setDailyData] = useState(null);

  useEffect(() => {
    const tracking = getDailyTracking();
    setDailyData(tracking);
  }, []);

  const handleShare = async () => {
    setIsSharing(true);
    try {
      const shareText = `${t('shareTitle')}\n\n` +
        `${t('ramadanDay')} ${ramadanDay || daysUntilRamadan}\n` +
        `📿 ${t('prayers')}: ${completedTasks}/${totalTasks}\n` +
        `🌟 ${t('sunnah')}: ${completedSunnahTasks}/${totalSunnahTasks}\n` +
        `🏆 ${t('achievements')}: ${unlockedAchievements}/${totalAchievements}\n` +
        `✨ ${t('overallProgress')}: ${completionPercentage}%\n\n` +
        `${t('shareDescription')}\n${APP_URL}`;

      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText)}`;
      const whatsappWindow = window.open(whatsappUrl, '_blank');

      if (!whatsappWindow) {
        if (navigator.share && navigator.canShare) {
          await navigator.share({
            title: t('shareTitle'),
            text: shareText,
            url: APP_URL
          });
          toast.success(t('shared'), {
            icon: '🎉',
            duration: 3000
          });
        } else {
          await navigator.clipboard.writeText(shareText);
          toast.success(t('copiedToClipboard'), {
            icon: '📋',
            duration: 3000
          });
        }
      }
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error('Share error:', error);
        toast.error(t('shareError'), {
          icon: '❌',
          duration: 3000
        });
      }
    } finally {
      setIsSharing(false);
    }
  };

  return (
    <div className={`space-y-6 ${theme === 'dark' ? 'text-gray-200' : ''}`}>
      <div className={`${
        theme === 'dark' 
          ? 'bg-gradient-to-br from-green-900 via-emerald-900/50 to-teal-900/30' 
          : 'bg-gradient-to-br from-green-50 via-emerald-50/50 to-teal-50/30'
      } rounded-xl p-6 text-center relative overflow-hidden animate-gradient`}>
        {/* Decorative elements */}
        <div className="absolute inset-0 overflow-hidden">
          {/* Geometric patterns */}
          <div className="absolute top-0 left-0 w-24 h-24 rounded-br-full opacity-10" 
               style={{background: theme === 'dark' ? 'linear-gradient(135deg, #22c55e, #059669)' : 'linear-gradient(135deg, #4ade80, #22c55e)'}}></div>
          <div className="absolute bottom-0 right-0 w-24 h-24 rounded-tl-full opacity-10"
               style={{background: theme === 'dark' ? 'linear-gradient(135deg, #059669, #047857)' : 'linear-gradient(135deg, #22c55e, #059669)'}}></div>
          
          {/* Animated stars */}
          <Star size={12} className="absolute top-8 left-12 text-green-300 opacity-50 animate-twinkle" />
          <Star size={8} className="absolute top-12 right-16 text-green-300 opacity-50 animate-twinkle-delayed" />
          <Star size={10} className="absolute bottom-16 left-20 text-green-300 opacity-50 animate-twinkle" />
          <Star size={6} className="absolute bottom-12 right-24 text-green-300 opacity-50 animate-twinkle-delayed" />
        </div>

        <div className="relative">
          {/* Share button */}
          <button
            onClick={handleShare}
            disabled={isSharing}
            onMouseEnter={() => setShowShareText(true)}
            onMouseLeave={() => setShowShareText(false)}
            className={`absolute top-2 right-2 p-2 rounded-full transition-all transform hover:scale-105 flex items-center gap-1 group ${
              theme === 'dark'
                ? 'bg-gradient-to-r from-blue-600/90 to-indigo-500/90 hover:from-blue-500/90 hover:to-indigo-400/90'
                : 'bg-gradient-to-r from-blue-500/90 to-indigo-400/90 hover:from-blue-400/90 hover:to-indigo-300/90'
            } text-white shadow-lg ${isSharing ? 'opacity-75 cursor-wait' : ''}`}
            aria-label={t('shareProgress')}
          >
            <Share2 size={14} className={`${isSharing ? 'animate-pulse' : ''}`} />
            <span className={`text-xs font-medium overflow-hidden transition-all duration-200 ${
              showShareText ? 'max-w-[100px] ml-1 opacity-100' : 'max-w-0 opacity-0'
            }`}>
              {t('share')}
            </span>
          </button>

          {/* Moon icon with glow effect */}
          <div className="relative inline-block mb-4">
            <div className={`absolute inset-0 ${
              theme === 'dark' ? 'bg-green-500' : 'bg-green-300'
            } rounded-full blur-xl opacity-20 animate-pulse-glow`}></div>
            <Moon size={48} className={`relative ${
              theme === 'dark' ? 'text-green-300' : 'text-green-600'
            }`} />
            <Sparkles size={16} className={`absolute -top-1 -right-1 ${
              theme === 'dark' ? 'text-yellow-300' : 'text-yellow-400'
            } animate-twinkle`} />
          </div>

          {/* Title and date */}
          <h1 className={`text-2xl font-bold mb-2 ${
            theme === 'dark' ? 'text-white' : 'text-green-900'
          }`}>
            Ramadan Mubarak
          </h1>
          <p className={`${
            theme === 'dark' ? 'text-green-200' : 'text-green-700'
          } mb-4`}>
            {currentDate}
          </p>

          {/* Ramadan day counter */}
          {ramadanDay ? (
            <div className={`inline-flex items-center px-4 py-2 rounded-full ${
              theme === 'dark' 
                ? 'bg-green-900/50 text-green-200 border border-green-700' 
                : 'bg-green-100 text-green-800 border border-green-200'
            } shadow-lg backdrop-blur-sm`}>
              <Calendar size={16} className="mr-2" />
              <span className="font-semibold">{t('day')} {ramadanDay}</span>
            </div>
          ) : (
            <div className={`inline-flex items-center px-4 py-2 rounded-full ${
              theme === 'dark' 
                ? 'bg-green-900/50 text-green-200 border border-green-700' 
                : 'bg-green-100 text-green-800 border border-green-200'
            } shadow-lg backdrop-blur-sm`}>
              <Calendar size={16} className="mr-2" />
              <span className="font-semibold">{daysUntilRamadan} {t('daysUntil')}</span>
            </div>
          )}
        </div>
      </div>

      {/* Grid layout for main components */}
      <div className="grid grid-cols-2 gap-4">
        <QiblaFinder 
          qiblaDirection={qiblaDirection}
          theme={theme}
          t={t}
          onCalculate={onCalculateQibla}
        />
        
        <DuaCollection 
          duasCount={duasCount}
          theme={theme}
          t={t}
          onOpen={onOpenDuaModal}
        />
      </div>
      
      <NextPrayer 
        nextPrayer={nextPrayer}
        theme={theme}
        t={t}
      />
      
      <MealTimes 
        nextMeal={nextMeal}
        theme={theme}
        t={t}
      />
      
      <DailyHighlightComponent 
        highlight={currentHighlight}
        theme={theme}
        t={t}
        onNext={onNextHighlight}
      />
      
      <RamadanCalendarButton 
        theme={theme}
        t={t}
        onOpen={onOpenCalendarModal}
      />
      
      <RecentAchievement 
        achievement={recentAchievement}
        theme={theme}
        t={t}
      />
      
      <ProgressSummary 
        completionPercentage={completionPercentage}
        completedTasks={completedTasks}
        totalTasks={totalTasks}
        completedSunnahTasks={completedSunnahTasks}
        totalSunnahTasks={totalSunnahTasks}
        unlockedAchievements={unlockedAchievements}
        totalAchievements={totalAchievements}
        theme={theme}
        t={t}
      />

      {/* Toast notification for saved settings */}
      {savedSettings && (
        <div className={`fixed bottom-20 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded-lg ${
          theme === 'dark' ? 'bg-indigo-800 text-indigo-100' : 'bg-indigo-100 text-indigo-800'
        } flex items-center shadow-lg`}>
          <CheckCircle size={16} className="mr-2" />
          <span className="text-sm">{t('settingsSaved')}</span>
        </div>
      )}
    </div>
  );
};

export default HomeTab;