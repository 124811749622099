import React, { useEffect, useState } from 'react';
import { Achievement } from '../../types';
import { Theme } from '../../types';
import { Star, Moon, Book, Heart, Users, Gift, Clock, Calendar } from 'lucide-react';
import { calculateRamadanDay } from '../../utils/helpers';
import { getProgressHistory, getSignUpDate } from '../../lib/localStorage';
import toast from 'react-hot-toast';

interface AchievementsTabProps {
  theme: Theme;
  achievements: Achievement[];
  completionPercentage: number;
  t: (key: string, ...args: any[]) => string;
}

const AchievementsTab: React.FC<AchievementsTabProps> = ({
  theme,
  achievements,
  completionPercentage,
  t
}) => {
  const [ramadanProgress, setRamadanProgress] = useState(0);
  const [unlockedAchievements, setUnlockedAchievements] = useState<Achievement[]>([]);
  const [inProgressAchievements, setInProgressAchievements] = useState<Achievement[]>([]);

  useEffect(() => {
    calculateAchievements();
  }, [achievements]);

  const calculateAchievements = () => {
    try {
      // Get Ramadan progress
      const { ramadanDay, remainingDays } = calculateRamadanDay();
      const totalDays = 30; // Total days in Ramadan
      const currentDay = ramadanDay || 0;
      const progress = Math.round((currentDay / totalDays) * 100);
      setRamadanProgress(progress);

      // Get user's progress history
      const progressHistory = getProgressHistory();
      const signUpDate = getSignUpDate();

      // Calculate achievements based on history
      const updatedAchievements = achievements.map(achievement => {
        let progress = 0;
        let streak = 0;
        let unlocked = false;

        switch (achievement.name) {
          case 'Quran Enthusiast': {
            // Count consecutive days of Quran reading
            for (const day of progressHistory) {
              if (day.quranAmount > 0) {
                streak++;
                if (streak >= achievement.target) {
                  unlocked = true;
                  break;
                }
              } else {
                streak = 0;
              }
            }
            progress = streak;
            break;
          }

          case 'Prayer Warrior': {
            // Count consecutive days of completing all prayers
            for (const day of progressHistory) {
              if (day.prayersCompleted === day.totalPrayers) {
                streak++;
                if (streak >= achievement.target) {
                  unlocked = true;
                  break;
                }
              } else {
                streak = 0;
              }
            }
            progress = streak;
            break;
          }

          case 'Generous Soul': {
            // Count consecutive days of giving charity
            for (const day of progressHistory) {
              if (day.charityAmount > 0) {
                streak++;
                if (streak >= achievement.target) {
                  unlocked = true;
                  break;
                }
              } else {
                streak = 0;
              }
            }
            progress = streak;
            break;
          }

          case 'Dhuha Devotee': {
            // Count consecutive days of performing Dhuha
            for (const day of progressHistory) {
              if (day.sunnahCompleted > 0) { // Assuming Dhuha is tracked in sunnah tasks
                streak++;
                if (streak >= achievement.target) {
                  unlocked = true;
                  break;
                }
              } else {
                streak = 0;
              }
            }
            progress = streak;
            break;
          }

          case 'Tahajjud Devotee': {
            // Count consecutive nights of performing Tahajjud
            for (const day of progressHistory) {
              if (day.sunnahCompleted > 0) { // Assuming Tahajjud is tracked in sunnah tasks
                streak++;
                if (streak >= achievement.target) {
                  unlocked = true;
                  break;
                }
              } else {
                streak = 0;
              }
            }
            progress = streak;
            break;
          }

          default: {
            // Handle daily fast achievement
            if (achievement.name.startsWith('Day') && achievement.name.includes('Fast Complete')) {
              unlocked = true;
              progress = achievement.target;
            }
            break;
          }
        }

        return {
          ...achievement,
          progress,
          unlocked,
          streak
        };
      });

      // Separate unlocked and in-progress achievements
      setUnlockedAchievements(updatedAchievements.filter(a => a.unlocked));
      setInProgressAchievements(updatedAchievements.filter(a => !a.unlocked));

    } catch (error) {
      console.error('Error calculating achievements:', error);
      toast.error('Failed to load achievements');
    }
  };

  return (
    <div className={`space-y-6 ${theme === 'dark' ? 'text-gray-200' : ''}`}>
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-indigo-50'} rounded-xl p-4`}>
        <h2 className={`text-lg font-bold mb-2 ${theme === 'dark' ? 'text-white' : ''}`}>{t('ramadanJourney')}</h2>
        <div className="flex justify-between items-center">
          <span className={theme === 'dark' ? 'text-indigo-300' : 'text-indigo-700'}>{t('overallProgress')}</span>
          <span className={`font-bold ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-800'}`}>{ramadanProgress}%</span>
        </div>
        <div className={`w-full ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-2.5 mt-2`}>
          <div className="bg-indigo-600 h-2.5 rounded-full" style={{ width: `${ramadanProgress}%` }}></div>
        </div>
      </div>
      
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <h2 className={`text-lg font-bold mb-4 ${theme === 'dark' ? 'text-white' : ''}`}>{t('unlockedAchievements')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {unlockedAchievements.map((achievement) => (
            <div key={achievement.id} className={`${theme === 'dark' ? 'bg-green-900' : 'bg-green-50'} p-4 rounded-lg flex items-center`}>
              <div className={`w-12 h-12 ${theme === 'dark' ? 'bg-green-800' : 'bg-green-100'} rounded-full flex items-center justify-center text-2xl`}>
                {achievement.icon}
              </div>
              <div className="ml-3">
                <h3 className={`font-semibold ${theme === 'dark' ? 'text-green-300' : 'text-green-800'}`}>{achievement.name}</h3>
                <p className={`text-xs ${theme === 'dark' ? 'text-green-400' : 'text-green-600'}`}>{achievement.description}</p>
              </div>
            </div>
          ))}
          
          {unlockedAchievements.length === 0 && (
            <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
              {t('noAchievementsYet')}
            </p>
          )}
        </div>
      </div>
      
      <div className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-xl p-4 shadow-sm`}>
        <h2 className={`text-lg font-bold mb-4 ${theme === 'dark' ? 'text-white' : ''}`}>{t('achievementsInProgress')}</h2>
        <div className="space-y-3">
          {inProgressAchievements.map((achievement) => (
            <div key={achievement.id} className={`${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50'} p-4 rounded-lg`}>
              <div className="flex items-center mb-2">
                <div className={`w-12 h-12 ${theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200'} rounded-full flex items-center justify-center text-2xl`}>
                  {achievement.icon}
                </div>
                <div className="ml-3">
                  <h3 className={`font-semibold ${theme === 'dark' ? 'text-white' : ''}`}>{achievement.name}</h3>
                  <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
                    {achievement.description}
                  </p>
                </div>
              </div>
              <div className={`flex justify-between items-center text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'} mb-1`}>
                <span>{t('progress')}</span>
                <span>{achievement.progress}/{achievement.target}</span>
              </div>
              <div className={`w-full ${theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200'} rounded-full h-1.5`}>
                <div 
                  className="bg-indigo-600 h-1.5 rounded-full" 
                  style={{ width: `${(achievement.progress / achievement.target) * 100}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AchievementsTab;