import React, { useState, useEffect } from 'react';
import { Moon, Book, Award, Star, Calendar } from 'lucide-react';
import { useAuth } from './contexts/AuthContext';
import AuthForm from './components/auth/AuthForm';
import Header from './components/Header';
import BottomNavigation from './components/BottomNavigation';
import HomeTab from './components/tabs/HomeTab';
import TrackTab from './components/tabs/TrackTab';
import InfoTab from './components/tabs/InfoTab';
import AchievementsTab from './components/tabs/AchievementsTab';
import ProfileTab from './components/tabs/ProfileTab';
import PersonalizationTab from './components/tabs/PersonalizationTab';
import DuaModal from './components/modals/DuaModal';
import RamadanCalendarModal from './components/modals/RamadanCalendarModal';
import CustomGoalModal from './components/modals/CustomGoalModal';
import ReminderSettingsModal from './components/modals/ReminderSettingsModal';
import ProgressHistoryModal from './components/modals/ProgressHistoryModal';
import { calculateQiblaDirection, getDataSourceForLocation, formatCurrentDate, calculateRamadanDay, calculateCompletionPercentage, calculateNextPrayer, calculateNextMeal, getPrayerTimes } from './utils/helpers';
import { commonDuas } from './data/commonDuas';
import { dailyHighlights } from './data/dailyHighlights';
import { getTranslation } from './utils/translations';
import { Location, Theme, Language, CustomGoal, ReminderSettings, DailyProgress, Achievement, Task, SunnahTask, Dua } from './types';

const App = () => {
  const { user, loading, profile, signOut } = useAuth();

  // Core app state
  const [activeTab, setActiveTab] = useState<string>('home');
  const [theme, setTheme] = useState<Theme>('light');
  const [language, setLanguage] = useState<Language>('en');
  const [userName, setUserName] = useState<string>(profile?.first_name || '');
  const [gender, setGender] = useState<string>(profile?.gender || 'male');
  const [showProfileMenu, setShowProfileMenu] = useState<boolean>(false);
  const [savedSettings, setSavedSettings] = useState<boolean>(false);

  // Location and prayer times
  const [location, setLocation] = useState<Location>({ 
    lat: null, 
    lng: null, 
    name: "Jakarta", 
    timezone: "Asia/Jakarta" 
  });
  const [dataSource, setDataSource] = useState<string>('auto');
  const [prayerTimes, setPrayerTimes] = useState({
    fajr: '04:38',
    sunrise: '05:52',
    dhuhr: '12:05',
    asr: '15:20',
    maghrib: '18:15',
    isha: '19:30',
    sahoor: '04:20',
    iftar: '18:15'
  });

  // Modal states
  const [showDuaModal, setShowDuaModal] = useState<boolean>(false);
  const [showRamadanCalendar, setShowRamadanCalendar] = useState<boolean>(false);
  const [showCustomGoalModal, setShowCustomGoalModal] = useState<boolean>(false);
  const [showReminderModal, setShowReminderModal] = useState<boolean>(false);
  const [showProgressHistoryModal, setShowProgressHistoryModal] = useState<boolean>(false);

  // Dua and highlights
  const [selectedDua, setSelectedDua] = useState<Dua | null>(null);
  const [currentHighlightIndex, setCurrentHighlightIndex] = useState<number>(0);

  // Tasks and tracking
  const [periodDay, setPeriodDay] = useState<boolean>(false);
  const [userTasks, setUserTasks] = useState<Task[]>([
    { id: 1, name: 'Fajr Prayer', completed: false, inMosque: false, icon: <Moon size={16} className="text-orange-500" /> },
    { id: 2, name: 'Dhuhr Prayer', completed: false, inMosque: false, icon: <Moon size={16} className="text-yellow-500" /> },
    { id: 3, name: 'Asr Prayer', completed: false, inMosque: false, icon: <Moon size={16} className="text-amber-500" /> },
    { id: 4, name: 'Maghrib Prayer', completed: false, inMosque: false, icon: <Moon size={16} className="text-indigo-500" /> },
    { id: 5, name: 'Isha Prayer', completed: false, inMosque: false, icon: <Moon size={16} className="text-purple-500" /> }
  ]);

  const [userSunnahTasks, setUserSunnahTasks] = useState<SunnahTask[]>([
    { id: 1, name: 'Taraweeh', completed: false, icon: <Moon size={16} className="text-green-500" /> },
    { id: 2, name: 'Quran Reading', completed: false, readingType: 'page', readingAmount: 0, icon: <Book size={16} className="text-blue-500" /> },
    { id: 3, name: 'Dhuha Prayer', completed: false, icon: <Moon size={16} className="text-orange-500" /> },
    { id: 4, name: 'Tahajjud', completed: false, icon: <Moon size={16} className="text-purple-500" /> },
    { id: 5, name: 'Charity', completed: false, amount: 0, currency: 'USD', icon: <Award size={16} className="text-amber-500" /> }
  ]);

  // Get current Ramadan day
  const { ramadanDay, daysUntilRamadan } = calculateRamadanDay();
  
  // Achievement tracking state
  const [achievements, setAchievements] = useState<Achievement[]>([
    // Add daily fast achievement if we're past day 1
    ...(ramadanDay && ramadanDay > 1 ? [{
      id: 0,
      name: `Day ${ramadanDay - 1} Fast Complete`,
      icon: '🌙',
      description: `Completed your ${ramadanDay - 1}${getDayOrdinal(ramadanDay - 1)} day of fasting`,
      progress: 1,
      target: 1,
      unlocked: true
    }] : []),
    {
      id: 1,
      name: "Quran Enthusiast",
      icon: "📖",
      description: "Read Quran daily for 7 consecutive days",
      progress: 0,
      target: 7,
      unlocked: false,
      streak: 0
    },
    {
      id: 2,
      name: "Prayer Warrior",
      icon: "🕌",
      description: "Complete all five daily prayers for 10 consecutive days",
      progress: 0,
      target: 10,
      unlocked: false,
      streak: 0
    },
    {
      id: 3,
      name: "Generous Soul",
      icon: "🤲",
      description: "Give charity for 5 consecutive days",
      progress: 0,
      target: 5,
      unlocked: false,
      streak: 0
    },
    {
      id: 4,
      name: "Dhuha Devotee",
      icon: "🌅",
      description: "Perform Dhuha prayer for 5 consecutive days",
      progress: 0,
      target: 5,
      unlocked: false,
      streak: 0
    },
    {
      id: 5,
      name: "Tahajjud Devotee",
      icon: "✨",
      description: "Perform Tahajjud prayer for 3 consecutive nights",
      progress: 0,
      target: 3,
      unlocked: false,
      streak: 0
    }
  ]);

  // Helper function to get ordinal suffix
  function getDayOrdinal(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  // Last update date for tracking streaks
  const [lastUpdateDate, setLastUpdateDate] = useState<string>(new Date().toISOString().split('T')[0]);

  // Effect to update achievements based on task completion
  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    
    // Only update achievements once per day
    if (today === lastUpdateDate) {
      const newAchievements = [...achievements];

      // Update Quran Enthusiast
      const quranTask = userSunnahTasks.find(t => t.id === 2);
      if (quranTask?.completed) {
        const quranAchievement = newAchievements.find(a => a.id === 1);
        if (quranAchievement) {
          quranAchievement.streak++;
          quranAchievement.progress = quranAchievement.streak;
          if (quranAchievement.progress >= quranAchievement.target) {
            quranAchievement.unlocked = true;
          }
        }
      } else {
        const quranAchievement = newAchievements.find(a => a.id === 1);
        if (quranAchievement) {
          quranAchievement.streak = 0;
          quranAchievement.progress = 0;
        }
      }

      // Update Prayer Warrior
      const allPrayersCompleted = userTasks.every(t => t.completed);
      if (allPrayersCompleted) {
        const prayerAchievement = newAchievements.find(a => a.id === 2);
        if (prayerAchievement) {
          prayerAchievement.streak++;
          prayerAchievement.progress = prayerAchievement.streak;
          if (prayerAchievement.progress >= prayerAchievement.target) {
            prayerAchievement.unlocked = true;
          }
        }
      } else {
        const prayerAchievement = newAchievements.find(a => a.id === 2);
        if (prayerAchievement) {
          prayerAchievement.streak = 0;
          prayerAchievement.progress = 0;
        }
      }

      // Update Generous Soul
      const charityTask = userSunnahTasks.find(t => t.id === 5);
      if (charityTask?.completed) {
        const charityAchievement = newAchievements.find(a => a.id === 3);
        if (charityAchievement) {
          charityAchievement.streak++;
          charityAchievement.progress = charityAchievement.streak;
          if (charityAchievement.progress >= charityAchievement.target) {
            charityAchievement.unlocked = true;
          }
        }
      } else {
        const charityAchievement = newAchievements.find(a => a.id === 3);
        if (charityAchievement) {
          charityAchievement.streak = 0;
          charityAchievement.progress = 0;
        }
      }

      // Update Dhuha Devotee
      const dhuhaTask = userSunnahTasks.find(t => t.id === 3);
      if (dhuhaTask?.completed) {
        const dhuhaAchievement = newAchievements.find(a => a.id === 4);
        if (dhuhaAchievement) {
          dhuhaAchievement.streak++;
          dhuhaAchievement.progress = dhuhaAchievement.streak;
          if (dhuhaAchievement.progress >= dhuhaAchievement.target) {
            dhuhaAchievement.unlocked = true;
          }
        }
      } else {
        const dhuhaAchievement = newAchievements.find(a => a.id === 4);
        if (dhuhaAchievement) {
          dhuhaAchievement.streak = 0;
          dhuhaAchievement.progress = 0;
        }
      }

      // Update Tahajjud Devotee
      const tahajjudTask = userSunnahTasks.find(t => t.id === 4);
      if (tahajjudTask?.completed) {
        const tahajjudAchievement = newAchievements.find(a => a.id === 5);
        if (tahajjudAchievement) {
          tahajjudAchievement.streak++;
          tahajjudAchievement.progress = tahajjudAchievement.streak;
          if (tahajjudAchievement.progress >= tahajjudAchievement.target) {
            tahajjudAchievement.unlocked = true;
          }
        }
      } else {
        const tahajjudAchievement = newAchievements.find(a => a.id === 5);
        if (tahajjudAchievement) {
          tahajjudAchievement.streak = 0;
          tahajjudAchievement.progress = 0;
        }
      }

      setAchievements(newAchievements);
      setLastUpdateDate(today);
    }
  }, [userTasks, userSunnahTasks, lastUpdateDate]);

  // Quran and charity tracking
  const [quranReadingAmount, setQuranReadingAmount] = useState<number>(0);
  const [quranReadingType, setQuranReadingType] = useState<string>('page');
  const [charityAmount, setCharityAmount] = useState<number>(0);
  const [charityCurrency, setCharityCurrency] = useState<string>('USD');

  // Initialize custom goals with pre-filled data
  const [progressHistory, setProgressHistory] = useState<DailyProgress[]>([]);

  // Translation helper
  const t = (key: string, ...args: any[]) => getTranslation(language, key, ...args);

  // Update prayer times when location or data source changes
  useEffect(() => {
    const calculationMethod = getDataSourceForLocation(location, dataSource);
    const times = getPrayerTimes(location, calculationMethod);
    setPrayerTimes(times);
  }, [location, dataSource]);

  // Update profile data when it changes
  useEffect(() => {
    if (profile) {
      setUserName(profile.first_name || '');
      setGender(profile.gender || 'male');
    }
  }, [profile]);

  // Calculate current values
  const currentDate = formatCurrentDate(language);
  const nextPrayer = calculateNextPrayer(prayerTimes);
  const nextMeal = calculateNextMeal(prayerTimes);
  const qiblaDirection = calculateQiblaDirection(location);
  const currentHighlight = dailyHighlights[currentHighlightIndex];
  const completedTasks = userTasks.filter(task => task.completed).length;
  const completedSunnahTasks = userSunnahTasks.filter(task => task.completed).length;
  const completionPercentage = calculateCompletionPercentage(
    completedTasks + completedSunnahTasks,
    userTasks.length + userSunnahTasks.length
  );

  // Get the most recent achievement for display
  const recentAchievement = achievements.find(a => a.unlocked) || achievements[0];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <AuthForm theme={theme} />;
  }

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-gray-900' : 'bg-gray-100'} flex flex-col max-w-md mx-auto p-4`}>
      <Header
        userName={userName}
        theme={theme}
        language={language}
        showProfileMenu={showProfileMenu}
        setShowProfileMenu={setShowProfileMenu}
        setActiveTab={setActiveTab}
        onLogout={signOut}
        onLanguageChange={() => setLanguage(language === 'en' ? 'id' : 'en')}
        t={t}
      />
      
      <main className="flex-1 overflow-y-auto pb-20">
        {activeTab === 'home' && (
          <HomeTab
            theme={theme}
            language={language}
            currentDate={currentDate}
            ramadanDay={ramadanDay}
            daysUntilRamadan={daysUntilRamadan}
            nextPrayer={nextPrayer}
            nextMeal={nextMeal}
            currentHighlight={currentHighlight}
            qiblaDirection={qiblaDirection}
            duasCount={commonDuas.length}
            recentAchievement={recentAchievement}
            completionPercentage={completionPercentage}
            completedTasks={completedTasks}
            totalTasks={userTasks.length}
            completedSunnahTasks={completedSunnahTasks}
            totalSunnahTasks={userSunnahTasks.length}
            unlockedAchievements={achievements.filter(a => a.unlocked).length}
            totalAchievements={achievements.length}
            savedSettings={savedSettings}
            t={t}
            onNextHighlight={() => setCurrentHighlightIndex((currentHighlightIndex + 1) % dailyHighlights.length)}
            onCalculateQibla={() => {}}
            onOpenDuaModal={() => setShowDuaModal(true)}
            onOpenCalendarModal={() => setShowRamadanCalendar(true)}
          />
        )}

        {activeTab === 'track' && (
          <TrackTab
            theme={theme}
            gender={gender}
            periodDay={periodDay}
            setPeriodDay={setPeriodDay}
            userTasks={userTasks}
            userSunnahTasks={userSunnahTasks}
            toggleTask={(id, field) => {
              setUserTasks(userTasks.map(task =>
                task.id === id ? { ...task, [field || 'completed']: !task[field || 'completed'] } : task
              ));
            }}
            toggleSunnahTask={(id) => {
              setUserSunnahTasks(userSunnahTasks.map(task =>
                task.id === id ? { ...task, completed: !task.completed } : task
              ));
            }}
            quranReadingAmount={quranReadingAmount}
            quranReadingType={quranReadingType}
            updateQuranReading={(amount, type) => {
              setQuranReadingAmount(parseInt(amount) || 0);
              setQuranReadingType(type);
            }}
            totalQuranRead={{ verse: 0, page: 0, juz: 0 }}
            charityAmount={charityAmount}
            charityCurrency={charityCurrency}
            updateCharityAmount={(amount) => setCharityAmount(parseFloat(amount) || 0)}
            setCharityCurrency={setCharityCurrency}
            totalCharityGiven={0}
            completionPercentage={completionPercentage}
            t={t}
          />
        )}

        {activeTab === 'info' && (
          <InfoTab
            theme={theme}
            prayerTimes={prayerTimes}
            t={t}
          />
        )}

        {activeTab === 'achievements' && (
          <AchievementsTab
            theme={theme}
            achievements={achievements}
            completionPercentage={completionPercentage}
            t={t}
          />
        )}

        {activeTab === 'personalize' && (
          <PersonalizationTab
            theme={theme}
            t={t}
            progressHistory={progressHistory}
            onViewProgressHistory={() => setShowProgressHistoryModal(true)}
          />
        )}

        {activeTab === 'profile' && (
          <ProfileTab
            theme={theme}
            language={language}
            userName={userName}
            gender={gender}
            setGender={setGender}
            notificationsEnabled={true}
            setNotificationsEnabled={() => {}}
            dataSource={dataSource}
            setDataSource={setDataSource}
            location={location}
            toggleTheme={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
            toggleLanguage={() => setLanguage(language === 'en' ? 'id' : 'en')}
            saveToLocalStorage={() => {
              setSavedSettings(true);
              setTimeout(() => setSavedSettings(false), 3000);
            }}
            savedSettings={savedSettings}
            t={t}
            getDataSourceForLocation={getDataSourceForLocation}
            onLogout={signOut}
          />
        )}
      </main>

      <BottomNavigation
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        theme={theme}
        t={t}
      />

      <DuaModal
        showModal={showDuaModal}
        selectedDua={selectedDua}
        commonDuas={commonDuas}
        theme={theme}
        t={t}
        onClose={() => {
          setShowDuaModal(false);
          setSelectedDua(null);
        }}
        onSelectDua={setSelectedDua}
      />

      <RamadanCalendarModal
        showModal={showRamadanCalendar}
        specialDays={[]}
        ramadanDay={ramadanDay}
        theme={theme}
        t={t}
        onClose={() => setShowRamadanCalendar(false)}
      />

      <CustomGoalModal
        showModal={showCustomGoalModal}
        theme={theme}
        t={t}
        onClose={() => setShowCustomGoalModal(false)}
        onSaveGoal={(goal) => {}}
        existingGoals={[]}
      />

      <ReminderSettingsModal
        showModal={showReminderModal}
        theme={theme}
        t={t}
        onClose={() => setShowReminderModal(false)}
        onSaveReminder={(reminder) => {}}
        existingReminders={[]}
      />

      <ProgressHistoryModal
        showModal={showProgressHistoryModal}
        theme={theme}
        t={t}
        onClose={() => setShowProgressHistoryModal(false)}
        progressHistory={progressHistory}
      />
    </div>
  );
};

export default App;