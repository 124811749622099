import React, { useState, useEffect } from 'react';
import { Clock, Sun, Moon, Sunrise, Sunset } from 'lucide-react';
import { Theme, PrayerTime } from '../types';

interface NextPrayerProps {
  nextPrayer: PrayerTime;
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
}

const NextPrayer: React.FC<NextPrayerProps> = ({
  nextPrayer,
  theme,
  t
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      // Get current time in minutes since midnight
      const now = new Date();
      const currentMinutes = now.getHours() * 60 + now.getMinutes();

      // Convert next prayer time to minutes
      const nextPrayerMinutes = nextPrayer.hours * 60 + nextPrayer.minutes;

      // Get all prayer times for the day
      const prayerTimes = [
        { name: 'Fajr', time: '04:38' },
        { name: 'Dhuhr', time: '12:05' },
        { name: 'Asr', time: '15:20' },
        { name: 'Maghrib', time: '18:15' },
        { name: 'Isha', time: '19:30' }
      ];

      // Find the previous prayer time
      let previousPrayerMinutes = 0;
      for (let i = prayerTimes.length - 1; i >= 0; i--) {
        const [hours, minutes] = prayerTimes[i].time.split(':').map(Number);
        const prayerMinutes = hours * 60 + minutes;
        if (currentMinutes >= prayerMinutes) {
          previousPrayerMinutes = prayerMinutes;
          break;
        }
      }

      // If no previous prayer found today, use Isha from yesterday
      if (previousPrayerMinutes === 0) {
        const [hours, minutes] = prayerTimes[prayerTimes.length - 1].time.split(':').map(Number);
        previousPrayerMinutes = (hours * 60 + minutes) - (24 * 60); // Subtract 24 hours
      }

      // Calculate total interval and elapsed time
      let totalInterval;
      let elapsedTime;

      if (nextPrayerMinutes < previousPrayerMinutes) {
        // Next prayer is tomorrow
        totalInterval = (24 * 60) - previousPrayerMinutes + nextPrayerMinutes;
        elapsedTime = currentMinutes >= previousPrayerMinutes 
          ? currentMinutes - previousPrayerMinutes
          : (24 * 60) - previousPrayerMinutes + currentMinutes;
      } else {
        // Next prayer is today
        totalInterval = nextPrayerMinutes - previousPrayerMinutes;
        elapsedTime = currentMinutes - previousPrayerMinutes;
      }

      // Calculate progress percentage
      const calculatedProgress = (elapsedTime / totalInterval) * 100;
      
      // Ensure progress stays between 0-100
      setProgress(Math.max(0, Math.min(100, calculatedProgress)));
    };

    // Initial update
    updateProgress();

    // Update every minute
    const interval = setInterval(updateProgress, 60000);

    return () => clearInterval(interval);
  }, [nextPrayer]);

  // Get prayer-specific styles and icon
  const getPrayerStyles = (name: string) => {
    switch (name.toLowerCase()) {
      case 'fajr':
        return {
          icon: <Sunrise size={32} className="transition-transform duration-500 group-hover:rotate-12" />,
          gradient: theme === 'dark' 
            ? 'from-indigo-900/50 via-purple-900/30 to-gray-900' 
            : 'from-indigo-100 via-purple-50 to-gray-50',
          iconBg: theme === 'dark' ? 'bg-indigo-900' : 'bg-indigo-100',
          iconColor: theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600',
          timeColor: theme === 'dark' ? 'text-indigo-200' : 'text-indigo-700',
          progressBg: theme === 'dark' ? 'bg-indigo-800/50' : 'bg-indigo-100/50',
          progressFill: theme === 'dark' ? 'bg-indigo-400' : 'bg-indigo-500',
          progressGlow: theme === 'dark' ? 'bg-indigo-400' : 'bg-indigo-400'
        };
      case 'dhuhr':
        return {
          icon: <Sun size={32} className="transition-transform duration-500 group-hover:scale-110" />,
          gradient: theme === 'dark'
            ? 'from-yellow-900/50 via-orange-900/30 to-gray-900'
            : 'from-yellow-100 via-orange-50 to-gray-50',
          iconBg: theme === 'dark' ? 'bg-yellow-900' : 'bg-yellow-100',
          iconColor: theme === 'dark' ? 'text-yellow-300' : 'text-yellow-600',
          timeColor: theme === 'dark' ? 'text-yellow-200' : 'text-yellow-700',
          progressBg: theme === 'dark' ? 'bg-yellow-800/50' : 'bg-yellow-100/50',
          progressFill: theme === 'dark' ? 'bg-yellow-400' : 'bg-yellow-500',
          progressGlow: theme === 'dark' ? 'bg-yellow-400' : 'bg-yellow-400'
        };
      case 'asr':
        return {
          icon: <Sun size={32} className="transition-transform duration-500 group-hover:rotate-45" />,
          gradient: theme === 'dark'
            ? 'from-orange-900/50 via-amber-900/30 to-gray-900'
            : 'from-orange-100 via-amber-50 to-gray-50',
          iconBg: theme === 'dark' ? 'bg-orange-900' : 'bg-orange-100',
          iconColor: theme === 'dark' ? 'text-orange-300' : 'text-orange-600',
          timeColor: theme === 'dark' ? 'text-orange-200' : 'text-orange-700',
          progressBg: theme === 'dark' ? 'bg-orange-800/50' : 'bg-orange-100/50',
          progressFill: theme === 'dark' ? 'bg-orange-400' : 'bg-orange-500',
          progressGlow: theme === 'dark' ? 'bg-orange-400' : 'bg-orange-400'
        };
      case 'maghrib':
        return {
          icon: <Sunset size={32} className="transition-transform duration-500 group-hover:-rotate-12" />,
          gradient: theme === 'dark'
            ? 'from-purple-900/50 via-indigo-900/30 to-gray-900'
            : 'from-purple-100 via-indigo-50 to-gray-50',
          iconBg: theme === 'dark' ? 'bg-purple-900' : 'bg-purple-100',
          iconColor: theme === 'dark' ? 'text-purple-300' : 'text-purple-600',
          timeColor: theme === 'dark' ? 'text-purple-200' : 'text-purple-700',
          progressBg: theme === 'dark' ? 'bg-purple-800/50' : 'bg-purple-100/50',
          progressFill: theme === 'dark' ? 'bg-purple-400' : 'bg-purple-500',
          progressGlow: theme === 'dark' ? 'bg-purple-400' : 'bg-purple-400'
        };
      case 'isha':
        return {
          icon: <Moon size={32} className="transition-transform duration-500 group-hover:scale-110" />,
          gradient: theme === 'dark'
            ? 'from-blue-900/50 via-indigo-900/30 to-gray-900'
            : 'from-blue-100 via-indigo-50 to-gray-50',
          iconBg: theme === 'dark' ? 'bg-blue-900' : 'bg-blue-100',
          iconColor: theme === 'dark' ? 'text-blue-300' : 'text-blue-600',
          timeColor: theme === 'dark' ? 'text-blue-200' : 'text-blue-700',
          progressBg: theme === 'dark' ? 'bg-blue-800/50' : 'bg-blue-100/50',
          progressFill: theme === 'dark' ? 'bg-blue-400' : 'bg-blue-500',
          progressGlow: theme === 'dark' ? 'bg-blue-400' : 'bg-blue-400'
        };
      default:
        return {
          icon: <Clock size={32} />,
          gradient: theme === 'dark'
            ? 'from-gray-800 via-gray-900 to-gray-800'
            : 'from-gray-100 via-gray-50 to-gray-100',
          iconBg: theme === 'dark' ? 'bg-gray-800' : 'bg-gray-100',
          iconColor: theme === 'dark' ? 'text-gray-300' : 'text-gray-600',
          timeColor: theme === 'dark' ? 'text-gray-200' : 'text-gray-700',
          progressBg: theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100/50',
          progressFill: theme === 'dark' ? 'bg-gray-400' : 'bg-gray-500',
          progressGlow: theme === 'dark' ? 'bg-gray-400' : 'bg-gray-400'
        };
    }
  };

  const styles = getPrayerStyles(nextPrayer.name);

  return (
    <div className={`group bg-gradient-to-r ${styles.gradient} rounded-xl p-6 shadow-sm relative overflow-hidden transition-all duration-300 hover:shadow-lg`}>
      {/* Decorative background elements */}
      <div className="absolute inset-0 opacity-10">
        <div className="absolute top-0 right-0 w-40 h-40 bg-current rounded-full filter blur-3xl animate-pulse"></div>
        <div className="absolute -bottom-8 -left-8 w-40 h-40 bg-current rounded-full filter blur-3xl animate-pulse delay-150"></div>
      </div>

      <div className="relative">
        {/* Header */}
        <div className="flex items-center justify-between mb-4">
          <h2 className={`text-lg font-bold flex items-center ${styles.timeColor}`}>
            <Clock size={18} className="mr-2" />
            {t('nextPrayer')}
          </h2>
          <div className={`${styles.iconBg} p-2 rounded-full ${styles.iconColor} transform transition-transform duration-500 group-hover:scale-110`}>
            {styles.icon}
          </div>
        </div>

        {/* Prayer Info */}
        <div className="space-y-3">
          <div>
            <h3 className={`text-2xl font-bold ${styles.timeColor} transition-all duration-300 group-hover:scale-105 origin-left`}>
              {t(nextPrayer.name.toLowerCase())}
            </h3>
            <p className={`flex items-center text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
              <Clock size={14} className="mr-1" />
              {t('inTime')} {nextPrayer.hours}{t('hours')} {nextPrayer.minutes}{t('minutes')}
            </p>
          </div>

          {/* Enhanced Progress bar */}
          <div className="relative">
            {/* Background track */}
            <div className={`h-3 rounded-full ${styles.progressBg} overflow-hidden`}>
              {/* Progress fill */}
              <div 
                className={`h-full rounded-full transition-all duration-1000 relative ${styles.progressFill}`}
                style={{ width: `${progress}%` }}
              >
                {/* Animated gradient overlay */}
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/20 to-transparent animate-[shimmer_2s_infinite]"></div>
                
                {/* Glow effect */}
                <div 
                  className={`absolute inset-0 ${styles.progressGlow} filter blur-sm opacity-50`}
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>

            {/* Progress markers */}
            <div className="flex justify-between mt-1 px-1">
              <span className={`text-xs ${theme === 'dark' ? 'text-gray-500' : 'text-gray-400'}`}>
                {Math.round(progress)}%
              </span>
              <span className={`text-xs ${theme === 'dark' ? 'text-gray-500' : 'text-gray-400'}`}>
                {nextPrayer.hours}h {nextPrayer.minutes}m
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextPrayer;