import { Location, PrayerTime, MealTime } from '../types';

// Calculate Ramadan day and remaining days
export const calculateRamadanDay = () => {
  const ramadanStartDate = new Date('2025-03-01');
  const ramadanEndDate = new Date('2025-03-30 23:59:59');
  const today = new Date();
  
  const daysDifference = Math.floor((today - ramadanStartDate) / (1000 * 60 * 60 * 24));
  const ramadanDay = daysDifference >= 0 && daysDifference < 30 ? daysDifference + 1 : null;
  
  // Calculate remaining days
  const daysUntilRamadan = daysDifference < 0 ? Math.abs(daysDifference) : 0;
  const remainingDays = Math.max(0, Math.ceil((ramadanEndDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)));
  
  return { ramadanDay, daysUntilRamadan, remainingDays };
};

// Calculate prayer times based on location using astronomical calculations
export const getPrayerTimes = (location: Location, calculationMethod: string) => {
  // If no location is provided, return default times
  if (!location.lat || !location.lng) {
    return {
      fajr: '04:38',
      sunrise: '05:52',
      dhuhr: '12:05',
      asr: '15:20',
      maghrib: '18:15',
      isha: '19:30',
      sahoor: '04:20',
      iftar: '18:15'
    };
  }

  // Get the current date
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Calculate Julian date
  const julianDate = calculateJulianDate(year, month, day);

  // Calculate solar position
  const solarPosition = calculateSolarPosition(julianDate, location.lat, location.lng);

  // Get method specific angles
  const methodAngles = getPrayerAngles(calculationMethod);

  // Calculate prayer times based on solar position and method angles
  const times = {
    fajr: calculatePrayerTime(solarPosition, methodAngles.fajr, location, 'fajr'),
    sunrise: calculateSunrise(solarPosition, location),
    dhuhr: calculateDhuhr(solarPosition, location),
    asr: calculateAsr(solarPosition, location, methodAngles.asr),
    maghrib: calculateMaghrib(solarPosition, location),
    isha: calculatePrayerTime(solarPosition, methodAngles.isha, location, 'isha'),
  };

  // Adjust times for timezone
  const adjustedTimes = adjustTimezone(times, location.timezone);

  // Set sahoor time (20 minutes before fajr)
  const sahoorTime = subtractMinutes(adjustedTimes.fajr, 20);

  return {
    ...adjustedTimes,
    sahoor: sahoorTime,
    iftar: adjustedTimes.maghrib
  };
};

// Get previous prayer time
export const getPreviousPrayer = (prayerTimes: Record<string, string>): { name: string; time: string } => {
  const prayers = [
    { name: 'Fajr', time: prayerTimes.fajr },
    { name: 'Dhuhr', time: prayerTimes.dhuhr },
    { name: 'Asr', time: prayerTimes.asr },
    { name: 'Maghrib', time: prayerTimes.maghrib },
    { name: 'Isha', time: prayerTimes.isha }
  ];

  const now = new Date();
  const currentMinutes = now.getHours() * 60 + now.getMinutes();

  // Convert prayer times to minutes since midnight
  const prayerMinutes = prayers.map(prayer => {
    const [hours, minutes] = prayer.time.split(':').map(Number);
    return { ...prayer, minutes: hours * 60 + minutes };
  });

  // Find the previous prayer
  for (let i = prayerMinutes.length - 1; i >= 0; i--) {
    if (currentMinutes >= prayerMinutes[i].minutes) {
      return prayers[i];
    }
  }

  // If no previous prayer found today, return Isha from yesterday
  return prayers[prayers.length - 1];
};

// Calculate progress percentage
export const calculatePrayerProgress = (
  currentPrayerTime: string,
  nextPrayerTime: string
): number => {
  const now = new Date();
  const currentMinutes = now.getHours() * 60 + now.getMinutes();

  const [currentHours, currentMins] = currentPrayerTime.split(':').map(Number);
  const [nextHours, nextMins] = nextPrayerTime.split(':').map(Number);

  const currentPrayerMinutes = currentHours * 60 + currentMins;
  const nextPrayerMinutes = nextHours * 60 + nextMins;

  // Handle case when next prayer is on the next day
  const totalMinutes = nextPrayerMinutes < currentPrayerMinutes 
    ? (24 * 60) - currentPrayerMinutes + nextPrayerMinutes
    : nextPrayerMinutes - currentPrayerMinutes;

  const elapsedMinutes = currentMinutes - currentPrayerMinutes;
  
  // Calculate progress percentage
  const progress = (elapsedMinutes / totalMinutes) * 100;

  // Ensure progress stays between 0-100
  return Math.max(0, Math.min(100, progress));
};

// Helper functions
const calculateJulianDate = (year: number, month: number, day: number): number => {
  if (month <= 2) {
    year -= 1;
    month += 12;
  }
  const a = Math.floor(year / 100);
  const b = 2 - a + Math.floor(a / 4);
  return Math.floor(365.25 * (year + 4716)) + Math.floor(30.6001 * (month + 1)) + day + b - 1524.5;
};

const calculateSolarPosition = (julianDate: number, latitude: number, longitude: number) => {
  const d = julianDate - 2451545.0; // Days since J2000
  
  // Calculate mean solar noon
  const Lsun = 280.460 + 0.9856474 * d;
  const Msun = 357.528 + 0.9856003 * d;
  
  // Calculate ecliptic longitude
  const lambda = Lsun + 1.915 * Math.sin(toRadians(Msun)) + 0.020 * Math.sin(toRadians(2 * Msun));
  
  // Calculate obliquity of ecliptic
  const epsilon = 23.439 - 0.0000004 * d;
  
  // Convert to radians
  const lambdaRad = toRadians(lambda);
  const epsilonRad = toRadians(epsilon);
  
  // Calculate right ascension and declination
  const alpha = toDegrees(Math.atan2(Math.cos(epsilonRad) * Math.sin(lambdaRad), Math.cos(lambdaRad)));
  const delta = toDegrees(Math.asin(Math.sin(epsilonRad) * Math.sin(lambdaRad)));
  
  return { alpha, delta };
};

const getPrayerAngles = (method: string) => {
  switch (method) {
    case 'muslim_world_league':
      return { fajr: 18, isha: 17, asr: 1 };
    case 'kemenag':
      return { fajr: 20, isha: 18, asr: 1 };
    case 'umm_al_qura':
      return { fajr: 18.5, isha: 19, asr: 1 };
    default:
      return { fajr: 18, isha: 17, asr: 1 }; // Default to Muslim World League
  }
};

const calculatePrayerTime = (solarPosition: any, angle: number, location: Location, prayer: 'fajr' | 'isha') => {
  const { delta } = solarPosition;
  const latitude = location.lat!;
  
  // Convert to radians
  const latRad = toRadians(latitude);
  const decRad = toRadians(delta);
  const angleRad = toRadians(angle);
  
  // Calculate hour angle
  const hourAngle = toDegrees(Math.acos(
    (-Math.sin(angleRad) - Math.sin(latRad) * Math.sin(decRad)) /
    (Math.cos(latRad) * Math.cos(decRad))
  ));
  
  // Convert to hours
  const noon = 12;
  const time = prayer === 'fajr' ? noon - hourAngle / 15 : noon + hourAngle / 15;
  
  return formatTime(time);
};

const calculateSunrise = (solarPosition: any, location: Location) => {
  const sunriseAngle = 0.833; // Standard solar depression angle for sunrise
  return calculatePrayerTime(solarPosition, sunriseAngle, location, 'fajr');
};

const calculateDhuhr = (solarPosition: any, location: Location) => {
  const { alpha } = solarPosition;
  const longitude = location.lng!;
  
  // Calculate equation of time
  const eot = alpha - (longitude / 15);
  const dhuhr = 12 + eot / 60;
  
  return formatTime(dhuhr);
};

const calculateAsr = (solarPosition: any, location: Location, asrMethod: number) => {
  const { delta } = solarPosition;
  const latitude = location.lat!;
  
  // Convert to radians
  const latRad = toRadians(latitude);
  const decRad = toRadians(delta);
  
  // Calculate shadow length
  const shadowLength = asrMethod + Math.tan(Math.abs(latRad - decRad));
  
  // Calculate hour angle
  const hourAngle = toDegrees(Math.acos(
    (Math.sin(Math.atan(1/shadowLength)) - Math.sin(latRad) * Math.sin(decRad)) /
    (Math.cos(latRad) * Math.cos(decRad))
  ));
  
  // Convert to hours
  const time = 12 + hourAngle / 15;
  
  return formatTime(time);
};

const calculateMaghrib = (solarPosition: any, location: Location) => {
  const maghribAngle = 0.833; // Standard solar depression angle for maghrib
  return calculatePrayerTime(solarPosition, maghribAngle, location, 'isha');
};

const adjustTimezone = (times: Record<string, string>, timezone: string) => {
  const tzOffset = getTimezoneOffset(timezone);
  const adjustedTimes: Record<string, string> = {};
  
  for (const [prayer, time] of Object.entries(times)) {
    const [hours, minutes] = time.split(':').map(Number);
    const adjustedTime = new Date();
    adjustedTime.setHours(hours);
    adjustedTime.setMinutes(minutes);
    adjustedTime.setMinutes(adjustedTime.getMinutes() + tzOffset);
    
    adjustedTimes[prayer] = formatTime(adjustedTime.getHours() + adjustedTime.getMinutes() / 60);
  }
  
  return adjustedTimes;
};

const getTimezoneOffset = (timezone: string): number => {
  const date = new Date();
  const tzString = date.toLocaleString('en-US', { timeZone: timezone });
  const tzDate = new Date(tzString);
  return Math.round((tzDate.getTime() - date.getTime()) / (60 * 1000));
};

const toRadians = (degrees: number): number => {
  return degrees * Math.PI / 180;
};

const toDegrees = (radians: number): number => {
  return radians * 180 / Math.PI;
};

const formatTime = (hours: number): string => {
  const h = Math.floor(hours);
  const m = Math.round((hours - h) * 60);
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
};

const subtractMinutes = (time: string, minutes: number): string => {
  const [hours, mins] = time.split(':').map(Number);
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(mins - minutes);
  return formatTime(date.getHours() + date.getMinutes() / 60);
};

// Format current date based on language
export const formatCurrentDate = (language: string): string => {
  return new Date().toLocaleDateString(language === 'en' ? 'en-US' : 'id-ID', { 
    weekday: 'long', 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric' 
  });
};

// Calculate completion percentage
export const calculateCompletionPercentage = (completedTasks: number, totalTasks: number): number => {
  return Math.round((completedTasks / totalTasks) * 100);
};

// Calculate next prayer time based on current time and prayer schedule
export const calculateNextPrayer = (prayerTimes: Record<string, string>): PrayerTime => {
  const now = new Date();
  const prayers = [
    { name: 'Fajr', time: parseTimeString(prayerTimes.fajr) },
    { name: 'Dhuhr', time: parseTimeString(prayerTimes.dhuhr) },
    { name: 'Asr', time: parseTimeString(prayerTimes.asr) },
    { name: 'Maghrib', time: parseTimeString(prayerTimes.maghrib) },
    { name: 'Isha', time: parseTimeString(prayerTimes.isha) }
  ];
  
  // Find the next prayer
  let nextPrayer = prayers[0];
  
  for (const prayer of prayers) {
    if (prayer.time > now) {
      nextPrayer = prayer;
      break;
    }
  }
  
  // If all prayers for today have passed, next prayer is Fajr tomorrow
  if (nextPrayer.time < now) {
    nextPrayer = prayers[0];
    nextPrayer.time.setDate(nextPrayer.time.getDate() + 1);
  }
  
  // Calculate time difference
  const diffMs = nextPrayer.time.getTime() - now.getTime();
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  
  return {
    name: nextPrayer.name,
    hours: diffHours,
    minutes: diffMinutes
  };
};

// Parse prayer time string to Date object
export const parseTimeString = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':').map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
};

// Calculate next meal time (Sahoor or Iftar)
export const calculateNextMeal = (prayerTimes: Record<string, string>): MealTime => {
  const now = new Date();
  const sahoorTime = parseTimeString(prayerTimes.sahoor);
  const iftarTime = parseTimeString(prayerTimes.iftar);
  
  // Adjust for next day if needed
  if (now > sahoorTime) {
    sahoorTime.setDate(sahoorTime.getDate() + 1);
  }
  
  if (now > iftarTime) {
    iftarTime.setDate(iftarTime.getDate() + 1);
  }
  
  // Determine which meal is next
  let nextMealTime: Date;
  let mealType: string;
  
  if (iftarTime < sahoorTime) {
    // If iftar is earlier, we're before iftar time
    if (now < iftarTime) {
      nextMealTime = iftarTime;
      mealType = 'Iftar';
    } else {
      nextMealTime = sahoorTime;
      mealType = 'Sahoor';
    }
  } else {
    // If sahoor is earlier, we're after iftar time
    if (now < sahoorTime) {
      nextMealTime = sahoorTime;
      mealType = 'Sahoor';
    } else {
      nextMealTime = iftarTime;
      mealType = 'Iftar';
    }
  }
  
  // Calculate time difference
  const diffMs = nextMealTime.getTime() - now.getTime();
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  
  return {
    type: mealType,
    hours: diffHours,
    minutes: diffMinutes
  };
};

// Helper function to calculate Qibla direction
export const calculateQiblaDirection = (location: Location): number | null => {
  if (!location.lat || !location.lng) {
    return null;
  }
  
  // Coordinates of the Kaaba
  const kaabaLat = 21.4225;
  const kaabaLng = 39.8262;
  
  // Convert to radians
  const lat1 = toRadians(location.lat);
  const lat2 = toRadians(kaabaLat);
  const lngDiff = toRadians(kaabaLng - location.lng);
  
  // Calculate Qibla direction
  const y = Math.sin(lngDiff);
  const x = Math.cos(lat1) * Math.tan(lat2) - Math.sin(lat1) * Math.cos(lngDiff);
  let qibla = toDegrees(Math.atan2(y, x));
  
  // Normalize to 0-360 degrees
  qibla = (qibla + 360) % 360;
  
  return Math.round(qibla);
};

// Helper function to get data source based on location
export const getDataSourceForLocation = (locationData: Location, currentDataSource: string): string => {
  // Safety check - if not using auto, just return the current source
  if (!currentDataSource || currentDataSource !== 'auto') {
    return currentDataSource;
  }
  
  // Safety check for location
  if (!locationData) return 'muslim_world_league';
  
  // If location is Indonesia, use Kemenag data source
  if (locationData.name === "Jakarta" || 
      locationData.timezone === "Asia/Jakarta" || 
      (locationData.timezone && locationData.timezone.includes("Indonesia"))) {
    return "kemenag";
  }
  
  // If location is in Middle East, use Umm Al-Qura
  if (locationData.timezone && 
      (locationData.timezone.includes("Riyadh") || 
       locationData.timezone.includes("Qatar") ||
       locationData.timezone.includes("Kuwait"))) {
    return "umm_al_qura";
  }
  
  // Default to Muslim World League for other locations
  return "muslim_world_league";
};