import React from 'react';
import { Bell, Clock, Calendar, Edit, Trash2, Volume2, Vibrate } from 'lucide-react';
import { ReminderSettings, Theme } from '../types';

interface RemindersListProps {
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
  reminders: ReminderSettings[];
  onAddReminder: () => void;
  onEditReminder: (reminder: ReminderSettings) => void;
  onDeleteReminder: (reminderId: number) => void;
  onToggleReminder: (reminderId: number) => void;
}

const RemindersList: React.FC<RemindersListProps> = ({
  theme,
  t,
  reminders,
  onAddReminder,
  onEditReminder,
  onDeleteReminder,
  onToggleReminder
}) => {
  // Format time to display
  const formatTime = (timeString: string) => {
    try {
      const [hours, minutes] = timeString.split(':');
      const date = new Date();
      date.setHours(parseInt(hours), parseInt(minutes));
      return date.toLocaleTimeString(t('locale'), { hour: '2-digit', minute: '2-digit' });
    } catch (e) {
      return timeString;
    }
  };
  
  // Format days to display
  const formatDays = (days: string[]) => {
    if (days.length === 7) return t('everyday');
    if (days.length === 0) return t('never');
    
    const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri'];
    const weekend = ['sat', 'sun'];
    
    if (weekdays.every(day => days.includes(day)) && weekend.every(day => !days.includes(day))) {
      return t('weekdays');
    }
    
    if (weekend.every(day => days.includes(day)) && weekdays.every(day => !days.includes(day))) {
      return t('weekends');
    }
    
    return days.map(day => t(day).substring(0, 3)).join(', ');
  };
  
  // Group reminders by type and ensure unique keys
  const groupedReminders = reminders.reduce((acc, reminder) => {
    if (!acc[reminder.type]) {
      acc[reminder.type] = [];
    }
    acc[reminder.type].push({
      ...reminder,
      uniqueKey: `${reminder.id}-${reminder.type}-${reminder.name}` // Ensure truly unique keys
    });
    return acc;
  }, {} as Record<string, (ReminderSettings & { uniqueKey: string })[]>);
  
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('reminders')}</h2>
        <button
          onClick={onAddReminder}
          className={`p-2 rounded-full ${theme === 'dark' ? 'bg-indigo-700 text-white' : 'bg-indigo-100 text-indigo-800'}`}
        >
          <Bell size={18} />
        </button>
      </div>
      
      {reminders.length === 0 ? (
        <div 
          className={`p-4 rounded-lg border-2 border-dashed text-center cursor-pointer ${
            theme === 'dark' ? 'border-gray-700 hover:border-gray-600' : 'border-gray-200 hover:border-gray-300'
          }`}
          onClick={onAddReminder}
        >
          <Bell size={24} className={`mx-auto mb-2 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`} />
          <p className={`${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('noReminders')}</p>
          <p className={`text-sm ${theme === 'dark' ? 'text-gray-500' : 'text-gray-400'}`}>{t('clickToAddReminder')}</p>
        </div>
      ) : (
        <div className="space-y-4">
          {Object.entries(groupedReminders).map(([type, typeReminders]) => (
            typeReminders.length > 0 && (
              <div key={`group-${type}`}>
                <h3 className={`text-sm font-medium mb-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t(`${type}Reminders`)}
                </h3>
                <div className="space-y-2">
                  {typeReminders.map(reminder => (
                    <div 
                      key={reminder.uniqueKey}
                      className={`p-3 rounded-lg ${
                        reminder.enabled 
                          ? (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-50') 
                          : (theme === 'dark' ? 'bg-gray-800/50' : 'bg-gray-100/50')
                      }`}
                    >
                      <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center">
                          <Bell size={18} className={`mr-2 ${
                            reminder.enabled 
                              ? (theme === 'dark' 
                                  ? type === 'prayer' ? 'text-indigo-300' 
                                  : type === 'meal' ? 'text-orange-300' 
                                  : type === 'quran' ? 'text-blue-300' 
                                  : 'text-purple-300'
                                : type === 'prayer' ? 'text-indigo-600' 
                                  : type === 'meal' ? 'text-orange-600' 
                                  : type === 'quran' ? 'text-blue-600' 
                                  : 'text-purple-600')
                              : (theme === 'dark' ? 'text-gray-500' : 'text-gray-400')
                          }`} />
                          <h4 className={`font-medium ${
                            reminder.enabled 
                              ? (theme === 'dark' ? 'text-white' : '') 
                              : (theme === 'dark' ? 'text-gray-500' : 'text-gray-400')
                          }`}>
                            {reminder.name}
                          </h4>
                        </div>
                        <div className="flex space-x-1">
                          <button
                            onClick={() => onEditReminder(reminder)}
                            className={`p-1 rounded ${theme === 'dark' ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}`}
                          >
                            <Edit size={14} className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} />
                          </button>
                          <button
                            onClick={() => onDeleteReminder(reminder.id)}
                            className={`p-1 rounded ${theme === 'dark' ? 'hover:bg-gray-600' : 'hover:bg-gray-200'}`}
                          >
                            <Trash2 size={14} className={theme === 'dark' ? 'text-gray-400' : 'text-gray-500'} />
                          </button>
                        </div>
                      </div>
                      
                      <div className={`flex items-center text-xs mb-2 ${
                        reminder.enabled 
                          ? (theme === 'dark' ? 'text-gray-400' : 'text-gray-500')
                          : (theme === 'dark' ? 'text-gray-500' : 'text-gray-400')
                      }`}>
                        <Clock size={12} className="mr-1" />
                        {(reminder.type === 'prayer' || reminder.type === 'meal') 
                          ? `${reminder.minutesBefore} ${t('minutesBefore')}`
                          : formatTime(reminder.time || '')}
                        <span className="mx-1">•</span>
                        <Calendar size={12} className="mr-1" />
                        {formatDays(reminder.days)}
                      </div>
                      
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2 text-xs">
                          {reminder.sound !== 'none' && (
                            <div className="flex items-center">
                              <Volume2 size={12} className={`mr-1 ${
                                reminder.enabled 
                                  ? (theme === 'dark' ? 'text-gray-400' : 'text-gray-500')
                                  : (theme === 'dark' ? 'text-gray-500' : 'text-gray-400')
                              }`} />
                              <span className={
                                reminder.enabled 
                                  ? (theme === 'dark' ? 'text-gray-400' : 'text-gray-500')
                                  : (theme === 'dark' ? 'text-gray-500' : 'text-gray-400')
                              }>
                                {t(reminder.sound + 'Sound')}
                              </span>
                            </div>
                          )}
                          
                          {reminder.vibration && (
                            <div className="flex items-center">
                              <Vibrate size={12} className={`mr-1 ${
                                reminder.enabled 
                                  ? (theme === 'dark' ? 'text-gray-400' : 'text-gray-500')
                                  : (theme === 'dark' ? 'text-gray-500' : 'text-gray-400')
                              }`} />
                              <span className={
                                reminder.enabled 
                                  ? (theme === 'dark' ? 'text-gray-400' : 'text-gray-500')
                                  : (theme === 'dark' ? 'text-gray-500' : 'text-gray-400')
                              }>
                                {t('vibration')}
                              </span>
                            </div>
                          )}
                        </div>
                        
                        <div 
                          className={`w-10 h-5 rounded-full flex items-center p-1 cursor-pointer ${
                            reminder.enabled 
                              ? (theme === 'dark' ? 'bg-indigo-600 justify-end' : 'bg-indigo-500 justify-end')
                              : (theme === 'dark' ? 'bg-gray-600 justify-start' : 'bg-gray-300 justify-start')
                          }`}
                          onClick={() => onToggleReminder(reminder.id)}
                        >
                          <div className="w-3 h-3 bg-white rounded-full"></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          ))}
        </div>
      )}
    </div>
  );
};

export default RemindersList;