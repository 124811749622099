import { Dua } from '../types';

export const commonDuas: Dua[] = [
  {
    id: 1,
    title: "Dua for Breaking Fast",
    arabic: "ذَهَبَ الظَّمَأُ وَابْتَلَّتِ الْعُرُوقُ وَثَبَتَ الْأَجْرُ إِنْ شَاءَ اللَّهُ",
    transliteration: "Dhahaba al-ẓama'u wa abtallatil 'urūqu wa thabata al-ajru in shā' Allāh",
    english: "The thirst has gone, the veins are moistened, and the reward is confirmed, if Allah wills.",
    context: "When breaking the fast at sunset (Iftar)"
  },
  {
    id: 2,
    title: "Dua Before Eating",
    arabic: "بِسْمِ اللَّهِ",
    transliteration: "Bismillāh",
    english: "In the name of Allah",
    context: "Before starting to eat or drink"
  },
  {
    id: 3,
    title: "Dua After Eating",
    arabic: "الْحَمْدُ لِلَّهِ الَّذِي أَطْعَمَنِي هَذَا وَرَزَقَنِيهِ مِنْ غَيْرِ حَوْلٍ مِنِّي وَلَا قُوَّةٍ",
    transliteration: "Alḥamdulillāh alladhī aṭ'amanī hādhā wa razaqanīhi min ghayri ḥawlin minnī wa lā quwwatin",
    english: "Praise be to Allah who has fed me this and provided it for me without any might or power on my part.",
    context: "After finishing a meal"
  },
  {
    id: 4,
    title: "Dua for Laylatul Qadr",
    arabic: "اللَّهُمَّ إِنَّكَ عَفُوٌّ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي",
    transliteration: "Allāhumma innaka 'afuwwun tuḥibbu al-'afwa fa'fu 'annī",
    english: "O Allah, You are forgiving and love forgiveness, so forgive me.",
    context: "During the last ten nights of Ramadan, especially on Laylatul Qadr"
  },
  {
    id: 5,
    title: "Dua for Seeking Forgiveness",
    arabic: "أَسْتَغْفِرُ اللَّهَ وَأَتُوبُ إِلَيْهِ",
    transliteration: "Astaghfirullāha wa atūbu ilayh",
    english: "I seek forgiveness from Allah and I turn to Him in repentance.",
    context: "Any time, especially during Ramadan"
  },
  {
    id: 6,
    title: "Dua for Protection",
    arabic: "بِسْمِ اللَّهِ الَّذِي لَا يَضُرُّ مَعَ اسْمِهِ شَيْءٌ فِي الْأَرْضِ وَلَا فِي السَّمَاءِ وَهُوَ السَّمِيعُ الْعَلِيمُ",
    transliteration: "Bismillāh alladhī lā yaḍurru ma'a ismihi shay'un fil-arḍi wa lā fis-samā'i wa huwas-samī'ul-'alīm",
    english: "In the name of Allah, with whose name nothing can cause harm on earth or in the heavens, and He is the All-Hearing, All-Knowing.",
    context: "Morning and evening for protection"
  },
  {
    id: 7,
    title: "Dua for Entering Mosque",
    arabic: "اللَّهُمَّ افْتَحْ لِي أَبْوَابَ رَحْمَتِكَ",
    transliteration: "Allāhumma iftaḥ lī abwāba raḥmatik",
    english: "O Allah, open for me the gates of Your mercy.",
    context: "When entering a mosque"
  },
  {
    id: 8,
    title: "Dua for Leaving Mosque",
    arabic: "اللَّهُمَّ إِنِّي أَسْأَلُكَ مِنْ فَضْلِكَ",
    transliteration: "Allāhumma innī as'aluka min faḍlik",
    english: "O Allah, I ask You of Your bounty.",
    context: "When leaving a mosque"
  }
];