import React from 'react';
import { CalendarCheck, Sparkles } from 'lucide-react';

interface RamadanCalendarButtonProps {
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
  onOpen: () => void;
}

const RamadanCalendarButton: React.FC<RamadanCalendarButtonProps> = ({
  theme,
  t,
  onOpen
}) => {
  return (
    <div 
      className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-purple-50'} rounded-xl p-4 shadow-sm cursor-pointer relative overflow-hidden`}
      onClick={onOpen}
    >
      <div className="absolute -right-4 -top-4 w-20 h-20 bg-purple-900 rounded-full opacity-10"></div>
      <div className="flex items-center justify-between">
        <div>
          <h3 className={`font-medium ${theme === 'dark' ? 'text-purple-300' : 'text-purple-700'} flex items-center`}>
            <CalendarCheck size={18} className="mr-2" />
            {t('ramadanCalendar')}
          </h3>
          <p className={`text-xs mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
            View special nights and important dates
          </p>
        </div>
        <div className={`w-12 h-12 rounded-full ${theme === 'dark' ? 'bg-purple-900' : 'bg-purple-100'} flex items-center justify-center`}>
          <Sparkles size={20} className={`${theme === 'dark' ? 'text-purple-300' : 'text-purple-600'}`} />
        </div>
      </div>
    </div>
  );
};

export default RamadanCalendarButton;