import React, { createContext, useContext, useEffect, useState } from 'react';
import { User, AuthError } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import toast from 'react-hot-toast';

interface AuthContextType {
  user: User | null;
  loading: boolean;
  profile: { first_name?: string; last_name?: string; phone?: string; gender?: string } | null;
  signUp: (email: string, password: string, userData?: { firstName: string; lastName: string; phone: string }) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  updateProfile: (data: { firstName?: string; lastName?: string; phone?: string; gender?: string }) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<{ first_name?: string; last_name?: string; phone?: string; gender?: string } | null>(null);

  const loadProfile = async (userId: string) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('first_name, last_name, phone, gender')
        .eq('id', userId)
        .maybeSingle();

      if (error && error.code !== 'PGRST116') {
        console.error('Error loading profile:', error);
        return;
      }

      setProfile(data || { first_name: '', last_name: '', phone: '', gender: 'male' });
    } catch (error) {
      console.error('Error loading profile:', error);
      setProfile({ first_name: '', last_name: '', phone: '', gender: 'male' });
    }
  };

  useEffect(() => {
    // Initialize auth state from local storage
    const session = supabase.auth.getSession();
    if (session) {
      setUser(session.data?.session?.user ?? null);
      if (session.data?.session?.user) {
        loadProfile(session.data.session.user.id);
      }
    }
    setLoading(false);

    // Set up auth state change listener
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      setUser(session?.user ?? null);
      if (session?.user) {
        await loadProfile(session.user.id);
      } else {
        setProfile(null);
      }
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signUp = async (email: string, password: string, userData?: { firstName: string; lastName: string; phone: string }) => {
    try {
      const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin,
        }
      });
      
      if (signUpError) throw signUpError;

      if (userData && signUpData.user) {
        const { error: profileError } = await supabase
          .from('profiles')
          .upsert({
            id: signUpData.user.id,
            first_name: userData.firstName,
            last_name: userData.lastName,
            phone: userData.phone,
            gender: 'male', // Default value
            updated_at: new Date().toISOString(),
          });

        if (profileError) {
          toast.error('Failed to save profile information');
          throw profileError;
        }
      }
    } catch (error) {
      if (error instanceof AuthError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
    } catch (error) {
      if (error instanceof AuthError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
      setProfile(null);
    } catch (error) {
      if (error instanceof AuthError) {
        throw new Error(error.message);
      }
      throw error;
    }
  };

  const updateProfile = async (data: { firstName?: string; lastName?: string; phone?: string; gender?: string }) => {
    if (!user) throw new Error('No user logged in');

    try {
      const { error } = await supabase
        .from('profiles')
        .upsert({
          id: user.id,
          first_name: data.firstName,
          last_name: data.lastName,
          phone: data.phone,
          gender: data.gender,
          updated_at: new Date().toISOString(),
        });

      if (error) throw error;

      setProfile(prev => ({
        ...prev,
        first_name: data.firstName || prev?.first_name,
        last_name: data.lastName || prev?.last_name,
        phone: data.phone || prev?.phone,
        gender: data.gender || prev?.gender,
      }));
    } catch (error) {
      console.error('Error updating profile:', error);
      throw new Error('Failed to update profile');
    }
  };

  return (
    <AuthContext.Provider value={{ user, loading, profile, signUp, signIn, signOut, updateProfile }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};