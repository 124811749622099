import React, { useState } from 'react';
import { X, Bell, Clock, Calendar, Check, Volume2, Vibrate } from 'lucide-react';
import { ReminderSettings, Theme } from '../../types';

interface ReminderSettingsModalProps {
  showModal: boolean;
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
  onClose: () => void;
  onSaveReminder: (reminder: Omit<ReminderSettings, 'id'>) => void;
  existingReminders: ReminderSettings[];
}

const ReminderSettingsModal: React.FC<ReminderSettingsModalProps> = ({
  showModal,
  theme,
  t,
  onClose,
  onSaveReminder,
  existingReminders
}) => {
  const [reminderType, setReminderType] = useState<'prayer' | 'meal' | 'quran' | 'custom'>('prayer');
  const [reminderName, setReminderName] = useState('');
  const [reminderTime, setReminderTime] = useState('');
  const [minutesBefore, setMinutesBefore] = useState(15);
  const [selectedDays, setSelectedDays] = useState<string[]>(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);
  const [sound, setSound] = useState('default');
  const [vibration, setVibration] = useState(true);
  
  const daysOfWeek = [
    { code: 'mon', label: t('monday') },
    { code: 'tue', label: t('tuesday') },
    { code: 'wed', label: t('wednesday') },
    { code: 'thu', label: t('thursday') },
    { code: 'fri', label: t('friday') },
    { code: 'sat', label: t('saturday') },
    { code: 'sun', label: t('sunday') }
  ];
  
  const soundOptions = [
    { value: 'default', label: t('defaultSound') },
    { value: 'adhan', label: t('adhanSound') },
    { value: 'bell', label: t('bellSound') },
    { value: 'chime', label: t('chimeSound') },
    { value: 'none', label: t('noSound') }
  ];
  
  const handleDayToggle = (day: string) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter(d => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };
  
  const handleSaveReminder = () => {
    // For prayer and meal types, use minutesBefore
    // For quran and custom types, use specific time
    
    const newReminder: Omit<ReminderSettings, 'id'> = {
      type: reminderType,
      name: reminderName || getDefaultReminderName(),
      enabled: true,
      time: reminderType === 'quran' || reminderType === 'custom' ? reminderTime : undefined,
      minutesBefore: reminderType === 'prayer' || reminderType === 'meal' ? minutesBefore : undefined,
      days: selectedDays,
      sound,
      vibration
    };
    
    onSaveReminder(newReminder);
    resetForm();
    onClose();
  };
  
  const getDefaultReminderName = () => {
    switch (reminderType) {
      case 'prayer':
        return t('prayerReminder');
      case 'meal':
        return t('mealReminder');
      case 'quran':
        return t('quranReminder');
      case 'custom':
        return t('customReminder');
      default:
        return t('reminder');
    }
  };
  
  const resetForm = () => {
    setReminderType('prayer');
    setReminderName('');
    setReminderTime('');
    setMinutesBefore(15);
    setSelectedDays(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);
    setSound('default');
    setVibration(true);
  };
  
  if (!showModal) return null;
  
  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${theme === 'dark' ? 'bg-black/70' : 'bg-gray-500/70'}`}>
      <div className={`w-full max-w-md ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg overflow-hidden`}>
        <div className={`p-4 border-b ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-between items-center">
            <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('createReminder')}</h2>
            <button 
              onClick={onClose}
              className={`p-1 rounded-full ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
            >
              <X size={20} />
            </button>
          </div>
        </div>
        
        <div className="p-4 max-h-[70vh] overflow-y-auto">
          <div className="space-y-4">
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('reminderType')}
              </label>
              <div className="grid grid-cols-2 gap-2">
                <button
                  onClick={() => setReminderType('prayer')}
                  className={`p-2 rounded-lg flex items-center ${
                    reminderType === 'prayer' 
                      ? (theme === 'dark' ? 'bg-indigo-900 text-indigo-200' : 'bg-indigo-100 text-indigo-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <Bell size={16} className="mr-2" />
                  {t('prayerReminder')}
                </button>
                <button
                  onClick={() => setReminderType('meal')}
                  className={`p-2 rounded-lg flex items-center ${
                    reminderType === 'meal' 
                      ? (theme === 'dark' ? 'bg-orange-900 text-orange-200' : 'bg-orange-100 text-orange-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <Bell size={16} className="mr-2" />
                  {t('mealReminder')}
                </button>
                <button
                  onClick={() => setReminderType('quran')}
                  className={`p-2 rounded-lg flex items-center ${
                    reminderType === 'quran' 
                      ? (theme === 'dark' ? 'bg-blue-900 text-blue-200' : 'bg-blue-100 text-blue-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <Bell size={16} className="mr-2" />
                  {t('quranReminder')}
                </button>
                <button
                  onClick={() => setReminderType('custom')}
                  className={`p-2 rounded-lg flex items-center ${
                    reminderType === 'custom' 
                      ? (theme === 'dark' ? 'bg-purple-900 text-purple-200' : 'bg-purple-100 text-purple-800') 
                      : (theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100')
                  }`}
                >
                  <Bell size={16} className="mr-2" />
                  {t('customReminder')}
                </button>
              </div>
            </div>
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('reminderName')}
              </label>
              <input
                type="text"
                value={reminderName}
                onChange={(e) => setReminderName(e.target.value)}
                placeholder={getDefaultReminderName()}
                className={`w-full p-2 rounded border ${
                  theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                }`}
              />
            </div>
            
            {(reminderType === 'prayer' || reminderType === 'meal') && (
              <div>
                <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('minutesBefore')}
                </label>
                <div className="flex items-center">
                  <input
                    type="number"
                    min="1"
                    max="60"
                    value={minutesBefore}
                    onChange={(e) => setMinutesBefore(parseInt(e.target.value) || 15)}
                    className={`w-full p-2 rounded-l border ${
                      theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                    }`}
                  />
                  <div className={`p-2 rounded-r border-t border-r border-b ${
                    theme === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-300' : 'bg-gray-100 border-gray-300 text-gray-700'
                  }`}>
                    {t('minutes')}
                  </div>
                </div>
                <p className={`text-xs mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                  {reminderType === 'prayer' 
                    ? t('minutesBeforePrayerDescription') 
                    : t('minutesBeforeMealDescription')}
                </p>
              </div>
            )}
            
            {(reminderType === 'quran' || reminderType === 'custom') && (
              <div>
                <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                  {t('reminderTime')}
                </label>
                <div className="flex items-center">
                  <div className={`p-2 rounded-l border-t border-l border-b ${
                    theme === 'dark' ? 'bg-gray-700 border-gray-600 text-gray-300' : 'bg-gray-100 border-gray-300 text-gray-700'
                  }`}>
                    <Clock size={16} />
                  </div>
                  <input
                    type="time"
                    value={reminderTime}
                    onChange={(e) => setReminderTime(e.target.value)}
                    className={`w-full p-2 rounded-r border ${
                      theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                    }`}
                    required
                  />
                </div>
              </div>
            )}
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('reminderDays')}
              </label>
              <div className="flex flex-wrap gap-2">
                {daysOfWeek.map(day => (
                  <button
                    key={day.code}
                    onClick={() => handleDayToggle(day.code)}
                    className={`px-2 py-1 rounded-full text-xs ${
                      selectedDays.includes(day.code) 
                        ? (theme === 'dark' ? 'bg-indigo-900 text-indigo-200' : 'bg-indigo-100 text-indigo-800') 
                        : (theme === 'dark' ? 'bg-gray-700 text-gray-400' : 'bg-gray-100 text-gray-500')
                    }`}
                  >
                    {day.label.substring(0, 3)}
                  </button>
                ))}
              </div>
            </div>
            
            <div>
              <label className={`block text-sm font-medium mb-1 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
                {t('reminderSound')}
              </label>
              <select
                value={sound}
                onChange={(e) => setSound(e.target.value)}
                className={`w-full p-2 rounded border ${
                  theme === 'dark' ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'
                }`}
              >
                {soundOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            
            <div className="flex items-center">
              <Vibrate size={16} className={`mr-2 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`} />
              <span className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{t('vibration')}</span>
              <div className="flex-grow"></div>
              <div 
                className={`w-12 h-6 rounded-full flex items-center p-1 cursor-pointer ${
                  vibration ? 'bg-green-600 justify-end' : 'bg-gray-300 justify-start'
                }`}
                onClick={() => setVibration(!vibration)}
              >
                <div className="w-4 h-4 bg-white rounded-full"></div>
              </div>
            </div>
          </div>
        </div>
        
        <div className={`p-4 border-t ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-end space-x-2">
            <button
              onClick={onClose}
              className={`px-4 py-2 rounded ${
                theme === 'dark' ? 'bg-gray-700 text-gray-300' : 'bg-gray-200 text-gray-700'
              }`}
            >
              {t('cancel')}
            </button>
            <button
              onClick={handleSaveReminder}
              disabled={(reminderType === 'quran' || reminderType === 'custom') && !reminderTime}
              className={`px-4 py-2 rounded flex items-center ${
                (reminderType === 'quran' || reminderType === 'custom') && !reminderTime
                  ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                  : (theme === 'dark' ? 'bg-indigo-600 text-white' : 'bg-indigo-600 text-white')
              }`}
            >
              <Check size={16} className="mr-2" />
              {t('saveReminder')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReminderSettingsModal;