import React, { useState } from 'react';
import { X, Calendar, ChevronLeft, ChevronRight, Activity, Book, Heart } from 'lucide-react';
import { DailyProgress, Theme } from '../../types';

interface ProgressHistoryModalProps {
  showModal: boolean;
  theme: Theme;
  t: (key: string, ...args: any[]) => string;
  onClose: () => void;
  progressHistory: DailyProgress[];
}

const ProgressHistoryModal: React.FC<ProgressHistoryModalProps> = ({
  showModal,
  theme,
  t,
  onClose,
  progressHistory
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState<{day: number, date: string, progress?: DailyProgress} | null>(null);
  
  if (!showModal) return null;
  
  // Get days in month
  const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };
  
  // Get first day of month (0 = Sunday, 1 = Monday, etc.)
  const getFirstDayOfMonth = (year: number, month: number) => {
    return new Date(year, month, 1).getDay();
  };
  
  const year = currentMonth.getFullYear();
  const month = currentMonth.getMonth();
  const daysInMonth = getDaysInMonth(year, month);
  const firstDayOfMonth = getFirstDayOfMonth(year, month);
  
  // Adjust first day to start from Monday (0 = Monday, 6 = Sunday)
  const adjustedFirstDay = (firstDayOfMonth === 0) ? 6 : firstDayOfMonth - 1;
  
  // Generate calendar days
  const calendarDays = [];
  
  // Add empty cells for days before the first day of the month
  for (let i = 0; i < adjustedFirstDay; i++) {
    calendarDays.push(null);
  }
  
  // Add days of the month
  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(year, month, day);
    const dateString = date.toISOString().split('T')[0];
    const dayProgress = progressHistory.find(p => p.date === dateString);
    
    calendarDays.push({
      day,
      date: dateString,
      progress: dayProgress
    });
  }
  
  // Navigate to previous month
  const goToPreviousMonth = () => {
    setCurrentMonth(new Date(year, month - 1, 1));
  };
  
  // Navigate to next month
  const goToNextMonth = () => {
    setCurrentMonth(new Date(year, month + 1, 1));
  };
  
  // Format month name
  const formatMonthName = (date: Date) => {
    return date.toLocaleDateString(t('locale'), { month: 'long', year: 'numeric' });
  };
  
  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${theme === 'dark' ? 'bg-black/70' : 'bg-gray-500/70'}`}>
      <div className={`w-full max-w-md ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'} rounded-lg shadow-lg overflow-hidden`}>
        <div className={`p-4 border-b ${theme === 'dark' ? 'border-gray-700' : 'border-gray-200'}`}>
          <div className="flex justify-between items-center">
            <h2 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : ''}`}>{t('progressHistory')}</h2>
            <button 
              onClick={onClose}
              className={`p-1 rounded-full ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
            >
              <X size={20} />
            </button>
          </div>
        </div>
        
        <div className="p-4 max-h-[70vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={goToPreviousMonth}
              className={`p-2 rounded-full ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
            >
              <ChevronLeft size={20} />
            </button>
            <h3 className={`text-lg font-medium ${theme === 'dark' ? 'text-white' : ''}`}>
              {formatMonthName(currentMonth)}
            </h3>
            <button
              onClick={goToNextMonth}
              className={`p-2 rounded-full ${theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
            >
              <ChevronRight size={20} />
            </button>
          </div>
          
          <div className="grid grid-cols-7 gap-1 mb-4">
            {[t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')].map(day => (
              <div key={day} className={`text-center text-xs p-1 font-medium ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {day.substring(0, 1)}
              </div>
            ))}
            
            {calendarDays.map((day, index) => (
              <div key={index} className="p-1">
                {day && (
                  <button
                    onClick={() => setSelectedDay(day)}
                    className={`w-full aspect-square rounded-full flex items-center justify-center text-sm ${
                      selectedDay?.date === day.date
                        ? (theme === 'dark' ? 'bg-indigo-700 text-white' : 'bg-indigo-100 text-indigo-800')
                        : day.progress
                          ? (theme === 'dark' 
                              ? `bg-indigo-900 bg-opacity-${Math.round(day.progress.completionPercentage / 10) * 10} text-white` 
                              : `bg-indigo-100 bg-opacity-${Math.round(day.progress.completionPercentage / 10) * 10} text-indigo-800`)
                          : (theme === 'dark' ? 'hover:bg-gray-700' : 'hover:bg-gray-100')
                    }`}
                  >
                    {day.day}
                  </button>
                )}
              </div>
            ))}
          </div>
          
          {selectedDay && selectedDay.progress && (
            <div className={`p-4 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <h4 className={`font-medium mb-2 ${theme === 'dark' ? 'text-white' : ''}`}>
                {new Date(selectedDay.date).toLocaleDateString(t('locale'), { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
              </h4>
              
              <div className="space-y-3">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Activity size={16} className={`mr-2 ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'}`} />
                    <span className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{t('overallProgress')}</span>
                  </div>
                  <span className={`font-medium ${theme === 'dark' ? 'text-indigo-300' : 'text-indigo-600'}`}>
                    {selectedDay.progress.completionPercentage}%
                  </span>
                </div>
                
                <div className={`w-full ${theme === 'dark' ? 'bg-gray-600' : 'bg-gray-200'} rounded-full h-1.5`}>
                  <div 
                    className="bg-indigo-600 h-1.5 rounded-full" 
                    style={{ width: `${selectedDay.progress.completionPercentage}%` }}
                  ></div>
                </div>
                
                <div className="grid grid-cols-2 gap-2 mt-3">
                  <div className={`p-2 rounded ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex items-center justify-between">
                      <span className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('prayers')}</span>
                      <span className={`text-xs font-medium ${theme === 'dark' ? 'text-white' : ''}`}>
                        {selectedDay.progress.prayersCompleted}/{selectedDay.progress.totalPrayers}
                      </span>
                    </div>
                  </div>
                  
                  <div className={`p-2 rounded ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}>
                    <div className="flex items-center justify-between">
                      <span className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('sunnah')}</span>
                      <span className={`text-xs font-medium ${theme === 'dark' ? 'text-white' : ''}`}>
                        {selectedDay.progress.sunnahCompleted}/{selectedDay.progress.totalSunnah}
                      </span>
                    </div>
                  </div>
                </div>
                
                <div className="flex items-center mt-2">
                  <Book size={16} className={`mr-2 ${theme === 'dark' ? 'text-blue-300' : 'text-blue-600'}`} />
                  <span className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{t('quranReading')}</span>
                  <span className={`ml-auto text-sm font-medium ${theme === 'dark' ? 'text-blue-300' : 'text-blue-600'}`}>
                    {selectedDay.progress.quranAmount} {t(selectedDay.progress.quranUnit)}
                  </span>
                </div>
                
                <div className="flex items-center">
                  <Heart size={16} className={`mr-2 ${theme === 'dark' ? 'text-green-300' : 'text-green-600'}`} />
                  <span className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{t('charity')}</span>
                  <span className={`ml-auto text-sm font-medium ${theme === 'dark' ? 'text-green-300' : 'text-green-600'}`}>
                    {selectedDay.progress.charityAmount > 0 ? `${selectedDay.progress.charityCurrency} ${selectedDay.progress.charityAmount}` : '-'}
                  </span>
                </div>
                
                <div className="flex items-center">
                  <Calendar size={16} className={`mr-2 ${theme === 'dark' ? 'text-purple-300' : 'text-purple-600'}`} />
                  <span className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>{t('customGoals')}</span>
                  <span className={`ml-auto text-sm font-medium ${theme === 'dark' ? 'text-purple-300' : 'text-purple-600'}`}>
                    {selectedDay.progress.customGoalsCompleted}/{selectedDay.progress.totalCustomGoals}
                  </span>
                </div>
              </div>
            </div>
          )}
          
          {selectedDay && !selectedDay.progress && (
            <div className={`p-4 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'} text-center`}>
              <p className={`${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
                {t('noProgressData')}
              </p>
            </div>
          )}
          
          <div className="mt-4">
            <h4 className={`font-medium mb-2 ${theme === 'dark' ? 'text-white' : ''}`}>{t('monthSummary')}</h4>
            
            <div className={`p-3 rounded-lg ${theme === 'dark' ? 'bg-gray-700' : 'bg-gray-100'}`}>
              <div className="grid grid-cols-2 gap-3">
                <div>
                  <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('daysTracked')}</p>
                  <p className={`text-lg font-medium ${theme === 'dark' ? 'text-white' : ''}`}>
                    {progressHistory.filter(p => {
                      const date = new Date(p.date);
                      return date.getMonth() === month && date.getFullYear() === year;
                    }).length}
                  </p>
                </div>
                
                <div>
                  <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('averageCompletion')}</p>
                  <p className={`text-lg font-medium ${theme === 'dark' ? 'text-white' : ''}`}>
                    {Math.round(
                      progressHistory
                        .filter(p => {
                          const date = new Date(p.date);
                          return date.getMonth() === month && date.getFullYear() === year;
                        })
                        .reduce((acc, curr) => acc + curr.completionPercentage, 0 ) / Math.max(1, progressHistory.filter(p => {
                          const date = new Date(p.date);
                          return date.getMonth() === month && date.getFullYear() === year;
                        }).length)
                    )}%
                  </p>
                </div>
                
                <div>
                  <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('totalQuranRead')}</p>
                  <p className={`text-lg font-medium ${theme === 'dark' ? 'text-white' : ''}`}>
                    {progressHistory
                      .filter(p => {
                        const date = new Date(p.date);
                        return date.getMonth() === month && date.getFullYear() === year;
                      })
                      .reduce((acc, curr) => {
                        // Convert all to verses for simplicity
                        let amount = curr.quranAmount;
                        if (curr.quranUnit === 'page') amount *= 15; // Approximate verses per page
                        if (curr.quranUnit === 'juz') amount *= 20 * 15; // Approximate pages per juz * verses per page
                        return acc + amount;
                      }, 0)} {t('verses')}
                  </p>
                </div>
                
                <div>
                  <p className={`text-xs ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>{t('totalCharity')}</p>
                  <p className={`text-lg font-medium ${theme === 'dark' ? 'text-white' : ''}`}>
                    {progressHistory
                      .filter(p => {
                        const date = new Date(p.date);
                        return date.getMonth() === month && date.getFullYear() === year && p.charityCurrency === 'USD'; // Simplify by only showing USD
                      })
                      .reduce((acc, curr) => acc + curr.charityAmount, 0)} USD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressHistoryModal;