import React from 'react';
import { BookOpen } from 'lucide-react';
import { Dua } from '../types';

interface DuaCollectionProps {
  duasCount: number;
  theme: 'light' | 'dark';
  t: (key: string, ...args: any[]) => string;
  onOpen: () => void;
}

const DuaCollection: React.FC<DuaCollectionProps> = ({
  duasCount,
  theme,
  t,
  onOpen
}) => {
  return (
    <div 
      className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-green-50'} rounded-xl p-4 shadow-sm cursor-pointer relative overflow-hidden`}
      onClick={onOpen}
    >
      <div className="absolute -left-4 -bottom-4 w-16 h-16 bg-green-900 rounded-full opacity-10"></div>
      <div className="flex flex-col items-center justify-center h-32">
        <BookOpen size={48} className={`${theme === 'dark' ? 'text-green-300' : 'text-green-600'} mb-2`} />
        <p className={`text-center ${theme === 'dark' ? 'text-green-300' : 'text-green-600'} font-medium`}>
          {t('duaCollection')}
        </p>
        <p className={`text-xs mt-1 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-500'}`}>
          {duasCount} {t('commonDuas')}
        </p>
      </div>
    </div>
  );
};

export default DuaCollection;