import { DailyHighlight } from '../types';

export const dailyHighlights: DailyHighlight[] = [
  {
    type: "quran",
    title: "Quran 2:183-184",
    arabic: "يَا أَيُّهَا الَّذِينَ آمَنُوا كُتِبَ عَلَيْكُمُ الصِّيَامُ كَمَا كُتِبَ عَلَى الَّذِينَ مِن قَبْلِكُمْ لَعَلَّكُمْ تَتَّقُونَ",
    english: "O you who have believed, decreed upon you is fasting as it was decreed upon those before you that you may become righteous.",
    reflection: "Fasting is prescribed to help us develop God-consciousness (taqwa) and self-restraint."
  },
  {
    type: "hadith",
    title: "Virtue of Ramadan",
    source: "Sahih Bukhari",
    arabic: "مَنْ صَامَ رَمَضَانَ إِيمَانًا وَاحْتِسَابًا غُفِرَ لَهُ مَا تَقَدَّمَ مِنْ ذَنْبِهِ",
    english: "Whoever fasts during Ramadan out of sincere faith and hoping to attain Allah's rewards, then all his past sins will be forgiven.",
    reflection: "Ramadan is a time for spiritual renewal and seeking forgiveness."
  },
  {
    type: "quran",
    title: "Quran 2:185",
    arabic: "شَهْرُ رَمَضَانَ الَّذِي أُنزِلَ فِيهِ الْقُرْآنُ هُدًى لِّلنَّاسِ وَبَيِّنَاتٍ مِّنَ الْهُدَىٰ وَالْفُرْقَانِ",
    english: "The month of Ramadan is that in which was revealed the Quran, a guidance for the people and clear proofs of guidance and criterion.",
    reflection: "Ramadan marks the revelation of the Quran, making it a special time to connect with Allah's words."
  },
  {
    type: "hadith",
    title: "Gates of Paradise",
    source: "Sahih Bukhari",
    arabic: "إِذَا جَاءَ رَمَضَانُ فُتِحَتْ أَبْوَابُ الْجَنَّةِ، وَغُلِّقَتْ أَبْوَابُ النَّارِ، وَصُفِّدَتِ الشَّيَاطِينُ",
    english: "When Ramadan begins, the gates of Paradise are opened, the gates of Hell are closed, and the devils are chained.",
    reflection: "Ramadan provides a unique spiritual environment for worship and good deeds."
  },
  {
    type: "quran",
    title: "Quran 97:1-5",
    arabic: "إِنَّا أَنزَلْنَاهُ فِي لَيْلَةِ الْقَدْرِ",
    english: "Indeed, We sent the Quran down during the Night of Decree. And what can make you know what is the Night of Decree? The Night of Decree is better than a thousand months.",
    reflection: "Laylatul Qadr (Night of Decree) is a blessed night in Ramadan worth more than a thousand months of worship."
  },
  {
    type: "hadith",
    title: "Charity in Ramadan",
    source: "Sahih Bukhari",
    arabic: "كَانَ النَّبِيُّ صَلَّى اللَّهُ عَلَيْهِ وَسَلَّمَ أَجْوَدَ النَّاسِ، وَكَانَ أَجْوَدُ مَا يَكُونُ فِي رَمَضَانَ",
    english: "The Prophet (ﷺ) was the most generous of people, and he was most generous during Ramadan.",
    reflection: "Ramadan is a time to increase in generosity and charity, following the Prophet's example."
  },
  {
    type: "quran",
    title: "Quran 2:186",
    arabic: "وَإِذَا سَأَلَكَ عِبَادِي عَنِّي فَإِنِّي قَرِيبٌ ۖ أُجِيبُ دَعْوَةَ الدَّاعِ إِذَا دَعَانِ",
    english: "And when My servants ask you concerning Me - indeed I am near. I respond to the invocation of the supplicant when he calls upon Me.",
    reflection: "Allah is especially close to us during Ramadan, ready to answer our prayers and supplications."
  }
];